export const getAuthorizations = (user) => {
  if (user != null && 'permissions' in user) {
    if (user.permissions.length > 0) {
      const result = user.permissions.map((item) => {
        return { [item['object_type']]: item['action'] === 'view' }
      })

      return Object.assign(...result)
    }

    return {}
  }

  return {}
}

export const hasAuthorization = (user, resource, permission) => {
  if (user != null && user.is_admin) {
    return true
  } else if (user != null && 'permissions' in user) {
    const found = user.permissions.find((p) => p.object_type === resource && p.action === permission)
    return found != undefined
  } else {
    return false
  }
}
