import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect } from 'react';
import { Heading } from 'xen-ui';
import { COMPANY_NAME } from 'xen/constants';
export const PageTitle = ({ title, ...props }) => {
    useEffect(() => {
        document.title = `${title} • ${COMPANY_NAME}`;
    }, []);
    return (_jsx(Heading, { ...props, level: 1, children: title }));
};
