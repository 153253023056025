import { jsx as _jsx } from "react/jsx-runtime";
import { z } from 'zod';
import { Select } from 'xen-ui';
import { useGetDebtorContacts } from 'xen/api/use-debtor/use-get-debtor-contacts';
import { FormSelect } from '../select';
export const FormDebtorContactSelect = ({ allowDeselect = false, label = DEFAULT_LABEL, nothingFoundMessage = DEFAULT_NOTHING_FOUND_MESSAGE, error, required = false, debtorId, ...props }) => {
    const { data: debtorContacts = [], isError, isSuccess, } = useGetDebtorContacts({
        urlParams: { debtorId },
    });
    const dataError = getDataError(isError, isSuccess, debtorContacts.length);
    const selectDebtorContactsList = debtorContacts.map(createSelectData);
    return (_jsx(FormSelect, { allowDeselect: allowDeselect ?? !required, clearable: false, data: selectDebtorContactsList, error: dataError || error, label: label, nothingFoundMessage: nothingFoundMessage, required: required, searchable: true, ...props }));
};
const formDebtorContactSelectSchema = z.string().min(1, { message: 'Debtor contact is required' });
FormDebtorContactSelect.schema = formDebtorContactSelectSchema;
export const DebtorContactSelect = ({ allowDeselect = false, label = DEFAULT_LABEL, nothingFoundMessage = DEFAULT_NOTHING_FOUND_MESSAGE, error, required = false, debtorId, ...props }) => {
    const { data: debtorContacts = [], isError, isSuccess, } = useGetDebtorContacts({
        urlParams: { debtorId },
    });
    const dataError = getDataError(isError, isSuccess, debtorContacts.length);
    const selectDebtorContactsList = debtorContacts.map(createSelectData);
    return (_jsx(Select, { allowDeselect: allowDeselect ?? !required, clearable: false, data: selectDebtorContactsList, error: dataError || error, label: label, nothingFoundMessage: nothingFoundMessage, required: required, searchable: true, ...props }));
};
// Private -------------------------------------------------------------------
const DEFAULT_LABEL = 'Debtor contact';
const DEFAULT_NOTHING_FOUND_MESSAGE = 'No debtor contacts found...';
const DEFAULT_ERROR_MESSAGE = 'Could not fetch debtor contacts';
const DEFAULT_NO_DATA_MESSAGE = 'No debtor contacts exist';
const createSelectData = (contact) => {
    return {
        label: `${contact.firstName} (${contact.email})`,
        value: String(contact.id),
    };
};
const getDataError = (isError, isSuccess, dataLength) => {
    if (isError)
        return DEFAULT_ERROR_MESSAGE;
    if (isSuccess && dataLength === 0)
        return DEFAULT_NO_DATA_MESSAGE;
    return '';
};
