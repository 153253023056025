import { jsx as _jsx } from "react/jsx-runtime";
import { Modal as MantineModal } from '@mantine/core';
const Modal = (props) => {
    return (_jsx(MantineModal, { centered: props.centered ?? true, closeButtonProps: { 'aria-label': 'close', ...props.closeButtonProps }, styles: {
            title: { fontSize: 'var(--mantine-font-size-xl)', fontWeight: 'bold', ...(props.styles?.title ?? {}) },
            ...props.styles,
        }, ...props }));
};
const Body = (props) => {
    return _jsx(MantineModal.Body, { ...props });
};
Modal.Body = Body;
const CloseButton = ({ 'aria-label': ariaLabel = 'close', ...props }) => {
    return _jsx(MantineModal.CloseButton, { ...props, "aria-label": ariaLabel });
};
Modal.CloseButton = CloseButton;
const Content = (props) => {
    return _jsx(MantineModal.Content, { ...props });
};
Modal.Content = Content;
const Header = (props) => {
    return _jsx(MantineModal.Header, { ...props });
};
Modal.Header = Header;
const Overlay = (props) => {
    return _jsx(MantineModal.Overlay, { ...props });
};
Modal.Overlay = Overlay;
const Root = (props) => {
    return _jsx(MantineModal.Root, { ...props });
};
Modal.Root = Root;
const Title = (props) => {
    return _jsx(MantineModal.Title, { ...props });
};
Modal.Title = Title;
export { Modal };
