import { jsx as _jsx } from "react/jsx-runtime";
import { createContext, useContext } from 'react';
const defaultTenant = {
    addressCity: '',
    addressCountry: '',
    addressState: null,
    addressStreet: null,
    addressUnit: null,
    addressZipCode: null,
    agingType: '',
    aliasOfId: null,
    aliasTenants: [],
    appEndpoint: '',
    attachmentsEmailAddress: '',
    bccUserEmail: false,
    contactEmailAddress: '',
    contactFirstName: '',
    contactLastName: '',
    contactPhone: '',
    contractLengthMonths: null,
    contractRenewDate: null,
    contractSignDate: null,
    createdAt: new Date(),
    disabled: false,
    fromEmailAddress: '',
    fundingRequestNotificationEmail: '',
    hellosignEnabled: true,
    hideEmailLogo: false,
    id: 0,
    newLeadsEmailAddress: '',
    notificationsEmailAddress: '',
    numLicenses: null,
    operationsAttachmentsEmailAddress: '',
    operationsNotificationsEmailAddress: '',
    organizationName: '',
    organizationType: '',
    organizationWebsite: '',
    originationType: '',
    preheaderEmailColor: '',
    qbOauthVersion: 0,
    referenceNum: 0,
    returnUserAttachmentsEmailAddress: '',
    returnUserNotificationsEmailAddress: '',
    scheduleBatchStart: 1,
    showCopyright: false,
    showTrackingData: false,
    tenantTimezone: '',
    tenantType: 'tenant',
    token: '',
    updatedAt: new Date(),
};
const TenantContext = createContext(defaultTenant);
export const TenantProvider = ({ children, tenant }) => {
    return _jsx(TenantContext.Provider, { value: tenant, children: children });
};
export const useTenantContext = () => {
    const context = useContext(TenantContext);
    return context;
};
