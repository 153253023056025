import { jsx as _jsx } from "react/jsx-runtime";
import { createContext, useContext } from 'react';
import { useSearchParamState } from 'xen/hooks';
const Context = createContext({
    setSortAsc: () => { },
    setSortBy: () => { },
    sortAsc: undefined,
    sortBy: undefined,
});
/**
 * Share state setters/getters for syncing standard sorting state (i.e.
 * params generally compatible with our server's Pagy setup) to URL.
 */
export const SortingSearchParamsProvider = ({ children, defaultSortBy, }) => {
    const [sortBy, setSortBy] = useSearchParamState('sortBy', defaultSortBy);
    const [sortAsc, setSortAsc] = useSearchParamState('sortAsc');
    return (_jsx(Context.Provider, { value: {
            setSortAsc,
            setSortBy,
            sortAsc,
            sortBy,
        }, children: children }));
};
export const useSortingSearchParamsContext = () => {
    const context = useContext(Context);
    return context;
};
