/* eslint-disable react/jsx-key */
import React, { useMemo, useEffect, useState } from 'react'
import styled from 'styled-components'
import {
  useTable,
  useSortBy,
  useRowState,
  useBlockLayout,
  useFlexLayout,
  useResizeColumns,
  useExpanded,
} from 'react-table'
import { v4 as uuidv4 } from 'uuid'
import { S_Table, S_TR } from './Table.styles'
import isEqual from 'lodash/isEqual'

// Table Constants
const TABLE_CONSTS = {
  defaultPageSize: 10,
}

// Redux Hooks
import { useDrag } from '../../_reduxHooks/drag.hook'
import { tableWidthsHooks } from '../../_actionHooks/tableWidths.actionHooks'

const S_UploadingRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-style: dotted;
  border-color: #4caf50;
  border-width: 2px;
  width: 100%;
  height: 50px;
  padding: 8px;
`

//Use of getTrProps is deprecated in react-table v7, now we are using our own implementation

const Table = ({
  data = [],
  columns = [],
  pageSize = TABLE_CONSTS.defaultPageSize,
  onSortedChange,
  onFilesAccepted = async () => {},
  getTrProps = () => {},
  handleRowClick = null,
  updateResults = () => {},
  defaultSort = null, // should have props id and desc
  keyProp = 'id',
  resizable,
  externalSort = null,
  mobileScroll = false,
  expandInitials = {},
  totals = null,
  borderLess = false,
  className = '',
  getSubRows = (row) => row.subRows || [],
  layout = null,
  ...rest
}) => {
  const uniqueTableId = uuidv4()
  const [previousData, setPreviousData] = useState(data)
  const areEqual = isEqual(data, previousData)
  let memoData = useMemo(() => {
    return totals ? [...data, totals] : data
  }, [areEqual])

  if (!areEqual) {
    setPreviousData(data)
  }
  const useSetColumnsWidths = tableWidthsHooks.useSetColumnsWidths()
  const useGetColumnsWidths = tableWidthsHooks.useGetColumnsWidths()

  const column = {
    Header: (props) => {
      const { headingText = '', textAlignment = 'left', canSort } = props.column
      return (
        <div
          className={`rt-custom-header align-${textAlignment}`}
          onClick={(e) => {
            if (externalSort) {
              if (typeof onSortedChange === 'function') {
                if (!externalSort[0] || externalSort[0].id != props.column.id) {
                  onSortedChange([{ id: props.column.id, desc: false }])
                } else if (externalSort[0] && externalSort[0].id == props.column.id && !externalSort[0].desc) {
                  onSortedChange([{ id: props.column.id, desc: true }])
                } else {
                  onSortedChange([])
                }
              }
            }
          }}
        >
          <span>{headingText}</span>

          {canSort && (
            <div
              className={`arrow-container ${
                props.column.isSorted && !externalSort ? (props.column.isSortedDesc ? '-sort-desc' : '-sort-asc') : ''
              }
                            ${
                              externalSort && externalSort[0] && externalSort[0].id == props.column.id
                                ? externalSort[0].desc
                                  ? '-sort-desc'
                                  : '-sort-asc'
                                : ''
                            }
                            `}
            ></div>
          )}
        </div>
      )
    },
    Cell: (props) => {
      const { textAlignment = 'left', overflow = false } = props.column
      return <div className={`rt-custom-cell align-${textAlignment} ${overflow ? '_overflow' : ''}`}>{props.value}</div>
    },
    minWidth: 50,
    width: 125,
    maxWidth: 400,
  }

  let memoColumns = useMemo(() => columns, [columns])
  const [fileHoverIndex, setFileHoverIndex] = useState(null)
  const [fileUploadingIndex, setFileUploadingIndex] = useState(null)
  const { drag = {} } = useDrag()
  const dragOverGlobalTarget = drag.dragOverTarget || ''
  const hasExpandColumns = columns.some((column) => column.id === 'expand' && column.className === 'expand-cell')
  useEffect(() => {
    if (dragOverGlobalTarget.constructor === Object && !Object.keys(dragOverGlobalTarget).length) return

    if (
      !!dragOverGlobalTarget &&
      !dragOverGlobalTarget.includes(S_UploadingRow.styledComponentId) &&
      !dragOverGlobalTarget.includes(S_Table.styledComponentId)
    ) {
      setFileHoverIndex(null)
    }
  }, [dragOverGlobalTarget])

  const tableOptions = [
    {
      columns: memoColumns,
      data: memoData,
      defaultColumn: column,
      disableMultiSort: true,
      manualSortBy: true,
      getSubRows: getSubRows,
      stateReducer: (newState, action, prevState) => {
        if (
          action.type === 'toggleSortBy' &&
          defaultSort &&
          defaultSort[0]?.disableNoSort &&
          defaultSort[0]?.id === action.columnId
        ) {
          let sortedColumn = newState.sortBy.find((column) => column.id === defaultSort[0].id) || {}
          if (typeof sortedColumn.desc !== 'boolean') {
            sortedColumn = { ...defaultSort[0] }
            newState.sortBy = [sortedColumn]
          }
        }

        return newState
      },
      initialState: {
        sortBy: defaultSort ? defaultSort : [],
        expanded: expandInitials,
      },
    },
    useRowState,
    useSortBy,
    useExpanded,
  ]

  if (layout && !resizable) {
    switch (layout) {
      case 'block':
        tableOptions.push(useBlockLayout)
        break
      case 'flex':
        tableOptions.push(useFlexLayout)
        break
      default:
        tableOptions.push(useBlockLayout)
    }
  }

  if (resizable) {
    tableOptions.push(useBlockLayout, useResizeColumns)
    memoColumns = useGetColumnsWidths(memoColumns, uniqueTableId)
  }

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow, state, ...restTab } = useTable(
    ...tableOptions
  )

  useEffect(() => {
    if (typeof onSortedChange === 'function' && !externalSort) {
      onSortedChange(state.sortBy)
    }
  }, [state.sortBy])

  if (resizable) {
    useSetColumnsWidths(state.columnResizing, uniqueTableId)
  }

  const clickListener = (original, rowIndex) => {
    if (handleRowClick && rowIndex < data.length) {
      handleRowClick(original)
    }
  }

  return (
    //define min rows to keep the min number of rows from being the same as the page size
    <S_Table
      borderLess={borderLess}
      className={`${className} ${hasExpandColumns ? 'has-expand-columns' : ''}`}
      hasTotals={!!totals}
      isTableLayout={!resizable || !layout}
      mobileScroll={mobileScroll}
    >
      <div className="table" {...getTableProps()}>
        <div className="headergroup">
          {headerGroups.map((headerGroup) => (
            <div className="tr" {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers
                .filter((column) => column?.hide !== true)
                .map((column) => {
                  return (
                    <div {...column.getHeaderProps()} className={`th ${column.className}`}>
                      <div {...column.getSortByToggleProps()}>{column.render('Header')}</div>

                      {resizable && (
                        <div
                          {...column.getResizerProps()}
                          className={`resizer ${column.isResizing ? '_active' : ''}`}
                        />
                      )}
                    </div>
                  )
                })}
            </div>
          ))}
        </div>
        <div
          className="rowgroup"
          onDragLeave={(event) => {
            event.preventDefault()
            // Clearing because onDragEnter, onDragEnter can be skiped if mouse passes too fast
            if (event.target.className.includes('S_UploadingRow')) {
              setFileHoverIndex(null)
            }
          }}
          {...getTableBodyProps()}
        >
          {rows.map((row, rowIndex) => {
            prepareRow(row)
            return (
              <S_TR
                aria-label="row"
                className={`tr ${row.depth ? 'child-row' : ''} ${
                  row.canExpand && row.isExpanded ? 'parent-row-expanded' : ''
                }`}
                {...row.getRowProps()}
                {...getTrProps(null, row)}
                clickable={!!handleRowClick && (totals ? rowIndex < data.length : true)}
                key={
                  typeof keyProp === 'function'
                    ? keyProp(data[row.index])
                    : data[row.index]
                      ? `${data[row.index][keyProp]}${rowIndex}${row.depth}`
                      : `${rowIndex}${row.depth}`
                }
                onClick={() => clickListener(row.original, rowIndex)}
                onDragOver={(event) => {
                  if (event.dataTransfer.types.includes('imagename') && event.dataTransfer.types.includes('base64')) {
                    event.preventDefault()
                    const { target } = event
                    if (target.tagName === 'TD' && fileHoverIndex !== rowIndex) {
                      // Clearing because onDragEnter, onDragEnter can be skiped if mouse passes too fast
                      setFileHoverIndex(rowIndex)
                    }
                  }
                }}
                onDropCapture={(e) => {
                  setFileHoverIndex(null)
                  setFileUploadingIndex(rowIndex)

                  //when an already uploaded image is being dragged over we'll handle it here instead of the onDrop handler
                  let base64Image = e.dataTransfer.getData('base64')
                  let imageName = e.dataTransfer.getData('imageName')
                  if (base64Image) {
                    let imageData = base64Image.split(',')
                    let mimeType = imageData[0].replace('data:', '').replace(';base64', '')
                    let image = imageData[1]

                    //base64 to proper File type, goes byteString -> byteArray (ints) -> byteArray -> blob
                    const byteCharacters = atob(image)
                    const byteNumbers = new Array(byteCharacters.length)
                    for (let i = 0; i < byteCharacters.length; i++) {
                      byteNumbers[i] = byteCharacters.charCodeAt(i)
                    }
                    const byteArray = new Uint8Array(byteNumbers)
                    const blob = new Blob([byteArray], { type: mimeType })

                    onFilesAccepted(
                      [
                        new File([blob], imageName, {
                          type: mimeType,
                        }),
                      ],
                      row
                    ).then(() => setFileUploadingIndex(null))

                    e.stopPropagation()
                  } else {
                    setFileUploadingIndex(null)
                  }
                }}
                row={row}
                rowIndex={rowIndex}
              >
                {fileHoverIndex === rowIndex ? (
                  <div className="td" colSpan={memoColumns.length} style={{ padding: '2px' }}>
                    <S_UploadingRow>Upload Files</S_UploadingRow>
                  </div>
                ) : fileUploadingIndex === rowIndex ? (
                  <div className="td" colSpan={memoColumns.length} style={{ padding: '2px' }}>
                    <S_UploadingRow>Uploading Files ...</S_UploadingRow>
                  </div>
                ) : (
                  row.cells
                    .filter((cell) => cell?.column?.hide !== true)
                    .map((cell) => {
                      return (
                        <div className={`td ${cell.column.className}`} {...cell.getCellProps()}>
                          {/* Pass these additional params to
                                        enable triggering table content updates from
                                        an editable cell */}
                          {cell.render('Cell', { updateResults, data })}
                        </div>
                      )
                    })
                )}
              </S_TR>
            )
          })}
        </div>
      </div>
    </S_Table>
  )
}

export default Table
export { Table }
