import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import { Group, Heading, Loader, Pagination, Stack, BasicTable as Table, Text } from 'xen-ui';
import { useGetTransactions } from 'xen/api/use-integration-plaid/use-get-transactions';
import { Error } from 'xen/components';
import { formatCurrency, formatDate } from 'xen/helpers/format';
const TRANSACTIONS_PER_PAGE = 10;
export const PlaidTransactions = ({ accountId }) => {
    const [activePage, setPage] = useState(1);
    const params = {
        queryParams: {
            page: activePage,
            perPage: TRANSACTIONS_PER_PAGE,
        },
        urlParams: {
            accountId: accountId,
        },
    };
    const { data: response, isError } = useGetTransactions(params);
    if (isError) {
        return (_jsxs(Stack, { children: [_jsx(Heading, { level: 2, styleVariant: 5, children: "Transactions" }), _jsx(Error, { message: "Could not get transactions" })] }));
    }
    if (!response) {
        return (_jsxs(Stack, { children: [_jsx(Heading, { level: 2, styleVariant: 5, children: "Transactions" }), _jsx(Loader, {})] }));
    }
    const { pagination: { pages: totalPages = 0 }, transactions = [], } = response;
    if (transactions.length === 0) {
        return (_jsxs(Stack, { children: [_jsx(Heading, { level: 2, styleVariant: 5, children: "Transactions" }), _jsx(Text, { children: "No transactions found" })] }));
    }
    return (_jsxs(Stack, { children: [_jsx(Heading, { level: 2, styleVariant: 5, children: "Transactions" }), _jsx(Table.ScrollArea, { "aria-label": "Transactions", children: _jsxs(Table, { striped: true, withColumnBorders: true, withTableBorder: true, children: [_jsx(Table.Thead, { children: _jsxs(Table.Tr, { children: [_jsx(Table.Th, { children: "Date" }), _jsx(Table.Th, { children: "Type" }), _jsx(Table.Th, { children: "Category" }), _jsx(Table.Th, { children: "Description" }), _jsx(Table.Th, { children: "Amount" })] }) }), _jsx(Table.Tbody, { children: transactions.map((transaction) => (_jsxs(Table.Tr, { children: [_jsx(Table.Td, { children: formatDate.localeDateString(transaction.date) }), _jsx(Table.Td, { children: parseFloat(transaction.amount) < 0 ? 'Credit' : 'Debit' }), _jsx(Table.Td, { children: transaction.category }), _jsxs(Table.Td, { children: [transaction.merchantName, " | ", transaction.name] }), _jsx(Table.Td, { children: _jsxs(Text, { children: [parseFloat(transaction.amount) < 0 ? '-' : '+', formatCurrency(transaction.amount)] }) })] }, transaction.id))) })] }) }), totalPages > 1 && (_jsx(Group, { align: "center", justify: "center", children: _jsx(Pagination, { "data-testid": "plaid-transactions-pagination", onChange: setPage, size: "xs", total: totalPages, value: activePage }) }))] }));
};
