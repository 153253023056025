import React from 'react'
import { connect } from 'react-redux'
import { FaPhone } from 'react-icons/fa'

// Actions
import { authenticationActions, menuOpenActions, modalActions } from '../../_actions'

// Components
import { SocialIcons } from '../SocialIcons'
import { MenuHelpLinks } from './MenuHelpLinks'

import { MenuPanel, Links } from './Menu.styles'

// CONSTANTS
import { tenantColors } from '../../_constants/colors'
import { menuConfig, headerConfig } from '../../_configs'

const Menu = ({ tenant, menuOpen }) => {
  const customColors = tenantColors[tenant]
  const config = menuConfig[tenant]
  const headerTenantConfig = headerConfig[tenant]

  return (
    <MenuPanel customColors={customColors} style={{ transform: menuOpen ? 'translateY(0)' : 'translateY(-200%)' }}>
      {!!config?.length && (
        <Links>
          {config.map((item, i) => (
            <a className={`menu-link`} href={item.link} key={i}>
              {item.type === `phone` && <FaPhone className={`icon`} />}
              {item.label}
            </a>
          ))}
        </Links>
      )}

      {headerTenantConfig.length > 0 && <MenuHelpLinks links={headerTenantConfig} />}

      <SocialIcons />
    </MenuPanel>
  )
}

const mapStateToProps = (state) => {
  const { authentication, menuOpen, env } = state
  return {
    loggedIn: authentication.loggedIn,
    sessionToken: authentication.sessionToken,
    loading: authentication.loading,
    menuOpen: menuOpen,
    tenant: env.tenant,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    signOut: (sessionToken) => {
      dispatch(authenticationActions.signOut(sessionToken))
    },
    toggleMenu: () => {
      dispatch(menuOpenActions.toggleMenu())
    },
    openModal: (content, props) => {
      dispatch(modalActions.openModal(content, props))
    },
  }
}

const connectedMenu = connect(mapStateToProps, mapDispatchToProps)(Menu)
export { connectedMenu as Menu }
