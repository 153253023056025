import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { BasicTable, Button, Group, Heading, Modal, Stack, Text, useDisclosure, XenIcon } from 'xen-ui';
import { formatDate } from 'xen/helpers';
import { InvoiceVerificationForm } from './invoice-verification-form';
const SentimentIcon = ({ sentiment }) => {
    switch (sentiment) {
        case 'positive':
            return _jsx(XenIcon.CheckMark, { color: "green", size: "sm", style: { flexShrink: 0 } });
        case 'negative':
            return _jsx(XenIcon.ExclamationMark, { color: "red", size: "sm", style: { flexShrink: 0 } });
        case 'neutral':
            return _jsx(XenIcon.MinusCircle, { color: "gray", size: "sm", style: { flexShrink: 0 } });
        default:
            return _jsx(_Fragment, {});
    }
};
export const InvoiceVerificationSection = ({ serializedInvoice }) => {
    const { debtorId, debtorName, verifications = [], errors = {} } = serializedInvoice;
    const [verificationFormOpened, { open: verificationFormOpen, close: verificationFormClose }] = useDisclosure(false);
    const onFormSubmit = () => {
        verificationFormClose();
    };
    const rows = verifications.map((verification) => (_jsxs(BasicTable.Tr, { children: [_jsx(BasicTable.Td, { children: _jsxs(Group, { gap: "xs", wrap: "nowrap", children: [_jsx(SentimentIcon, { sentiment: verification.sentiment }), " ", verification.verificationTypeName] }) }), _jsx(BasicTable.Td, { children: verification.note.owner?.name || '' }), _jsxs(BasicTable.Td, { children: [verification.contact?.firstName || '', " ", verification.contact?.lastName || ''] }), _jsx(BasicTable.Td, { children: formatDate.localeDateString(verification.note.updatedAt) }), _jsx(BasicTable.Td, { style: { whiteSpace: 'normal' }, children: verification.note.body })] }, verification.id)));
    return (_jsxs(_Fragment, { children: [debtorId && debtorName && (_jsx(Modal, { onClose: verificationFormClose, opened: verificationFormOpened, title: "New Invoice Verification", children: _jsx(InvoiceVerificationForm, { invoice: serializedInvoice, onFormSubmit: onFormSubmit }) })), _jsxs(Stack, { children: [_jsxs(Group, { justify: "space-between", children: [_jsxs(Stack, { gap: 0, children: [_jsx(Heading, { level: 3, size: "h5", children: "Verifications" }), _jsx(Text, { size: "xs", children: rows.length > 0 ? 'View all verifications for this invoice' : 'Invoice has no verification notes' })] }), _jsx(Button, { disabled: Object.keys(errors).length > 0, onClick: verificationFormOpen, size: "xs", w: "fit-content", children: "Add Verification" })] }), rows.length > 0 && (_jsxs(BasicTable, { striped: true, styles: { td: { whiteSpace: 'nowrap' } }, withColumnBorders: true, withTableBorder: true, children: [_jsx(BasicTable.Thead, { children: _jsxs(BasicTable.Tr, { children: [_jsx(BasicTable.Th, { children: "Type" }), _jsx(BasicTable.Th, { children: "User" }), _jsx(BasicTable.Th, { children: "Contact" }), _jsx(BasicTable.Th, { children: "Date" }), _jsx(BasicTable.Th, { children: "Note" })] }) }), _jsx(BasicTable.Tbody, { children: rows })] }))] })] }));
};
