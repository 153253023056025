import { parseDate } from 'xen/helpers/parse';
import { formatCurrency } from './currency';
import { formatDate } from './dates';
import { formatDecimal } from './decimal';
import { formatPercent } from './percent';
const isNumberOrNumberString = (value) => {
    return ['number', 'string'].includes(typeof value) && !isNaN(Number(value));
};
const isDateString = (value) => {
    return typeof value === 'string' && !isNaN(Date.parse(value)) && !value.includes('T');
};
const isDateTimeString = (value) => {
    return typeof value === 'string' && !isNaN(Date.parse(value)) && value.includes('T');
};
const isNLengthString = (value, n) => {
    return typeof value === 'string' && value.length === n;
};
const formatFieldValue = ({ type, value }) => {
    if (value === null)
        return '';
    switch (type) {
        case 'currency':
            return isNumberOrNumberString(value) ? formatCurrency(value) : value;
        case 'date':
            if (isDateTimeString(value))
                return formatDate.localeDateString(new Date(value));
            if (isDateString(value))
                return formatDate.localeDateString(parseDate.fromIsoDateString(value));
            return value;
        case 'dropdown':
            return value;
        case 'ein':
            return isNLengthString(value, 9) ? value.replace(/([\d\\*]{2})([\d\\*]{7})/, '$1-$2') : value;
        case 'email':
            return value;
        case 'number':
            return isNumberOrNumberString(value) ? formatDecimal(Number(value)) : value;
        case 'percentage':
            return isNumberOrNumberString(value) ? formatPercent(Number(value) / 100) : value;
        case 'radio':
            return value;
        case 'radio_upload':
            return value;
        case 'ssn':
            return isNLengthString(value, 9) ? value.replace(/([\d\\*]{3})([\d\\*]{2})([\d\\*]{4})/, '$1-$2-$3') : value;
        case 'tel':
            return isNLengthString(value, 10) ? value.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3') : value;
        case 'text':
            return value;
        case 'textarea':
            return value;
        default:
            return value;
    }
};
export const formatField = {
    value: formatFieldValue,
};
