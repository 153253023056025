import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { forwardRef, useEffect, useState } from 'react';
import { createLabelText, selectScrollAreaProps } from '../_internal';
import { CheckIcon, Combobox, isComboboxDataGroups, isComboboxDataItems, useCombobox } from '../combobox';
import { Group } from '../group';
import { useIsFirstRender } from '../hooks';
import { Pill } from '../pill';
import { PillsInput } from '../pills-input';
import { ScrollArea } from '../scroll-area';
/**
 * Custom implementation of a `<MultiSelect />` component that allows for
 * custom-rendered values, i.e. the values shown in the input pills.
 * Based on https://github.com/mantinedev/mantine/blob/master/packages/%40mantine/core/src/components/MultiSelect/MultiSelect.tsx
 * and this example https://mantine.dev/combobox/?e=MultiSelectValueRenderer.
 * It doesn't have all the same functionality as `<MultiSelect />`, so if you don't need
 * custom rendering use that component instead.
 */
export const MultiSelectValueRenderer = forwardRef(({ data, disabled, error, hideLabel = false, inputWrapperOrder, label, onBlur, onChange, onKeyDown, onRemove, placeholder, size, styles, type, value: initialValue, valueSorter, w, }, ref) => {
    const combobox = useCombobox({
        onDropdownClose: () => combobox.resetSelectedOption(),
        onDropdownOpen: () => combobox.updateSelectedOptionIndex('active'),
    });
    const [value, setValue] = useState(initialValue ?? []);
    // Run onChange after the first render (required for react-hook-form)
    const firstRender = useIsFirstRender();
    useEffect(() => {
        if (firstRender)
            return;
        onChange?.(value);
    }, [value]);
    return (_jsxs(Combobox, { onOptionSubmit: (val) => setValue((current) => {
            const newValue = current.includes(val) ? current.filter((v) => v !== val) : [...current, val];
            return newValue.sort(valueSorter);
        }), size: size, store: combobox, children: [_jsx(Combobox.DropdownTarget, { children: _jsx(PillsInput, { ...createLabelText(label, hideLabel, type), "data-expanded": combobox.dropdownOpened || undefined, error: error, inputWrapperOrder: inputWrapperOrder, onClick: () => combobox.toggleDropdown(), pointer: true, rightSection: _jsx(Combobox.Chevron, { size: size }), 
                    // Allow the chevron to be toggle the dropdown
                    rightSectionPointerEvents: "none", size: size, styles: {
                        ...styles,
                        input: {
                            // Don't include padding for chevron
                            paddingInlineEnd: 'var(--input-padding)',
                            ...(typeof styles === 'object' && styles?.input ? styles.input : {}),
                        },
                    }, w: w, children: _jsxs(Pill.Group, { children: [_jsx(SelectedValues, { data: data, onRemove: (removeVal) => {
                                    const newVal = value.filter((v) => v !== removeVal);
                                    onRemove?.(removeVal);
                                    setValue(newVal);
                                }, value: value }), _jsx(Combobox.EventsTarget, { children: _jsx(PillsInput.Field, { disabled: disabled, onBlur: (event) => {
                                        onBlur?.(event);
                                        combobox.closeDropdown();
                                    }, onKeyDown: (event) => {
                                        onKeyDown?.(event);
                                        if (event.key === ' ') {
                                            event.preventDefault();
                                            combobox.toggleDropdown();
                                        }
                                    }, placeholder: placeholder, pointer: true, readOnly: true, ref: ref, 
                                    // Hide placeholder when there are selected values
                                    type: value.length > 0 ? 'hidden' : 'visible' }) })] }) }) }), _jsx(Combobox.Dropdown, { children: _jsx(Combobox.Options, { children: _jsx(ScrollArea.Autosize, { ...selectScrollAreaProps, children: _jsx(Options, { data: data, size: size, value: value }) }) }) })] }));
});
MultiSelectValueRenderer.displayName = 'MultiSelectValueRenderer';
const ItemOption = ({ item, size, value }) => {
    return (_jsx(Combobox.Option, { value: item.value, children: _jsxs(Group, { gap: `calc(0.5 * var(--mantine-spacing-${size}))`, children: [value.includes(item.value) && _jsx(CheckIcon, { opacity: "0.4", size: "0.8em" }), _jsx("span", { children: item.label })] }) }, item.value));
};
const Options = ({ data, ...props }) => {
    if (isComboboxDataGroups(data)) {
        return data.map((option) => {
            return (_jsx(Combobox.Group, { label: option.group, children: option.items.map((item) => {
                    return _jsx(ItemOption, { item: item, ...props }, item.value);
                }) }, option.group));
        });
    }
    if (isComboboxDataItems(data)) {
        return data.map((option) => {
            return _jsx(ItemOption, { item: option, ...props }, option.value);
        });
    }
};
const SelectedValues = ({ data, onRemove, value: selectedValues }) => {
    return selectedValues.map((value) => {
        let selectedItem = null;
        if (isComboboxDataGroups(data)) {
            const foundGroup = data.find((group) => group.items.some((item) => item.value === value));
            selectedItem = foundGroup?.items.find((item) => item.value === value);
        }
        else if (isComboboxDataItems(data)) {
            selectedItem = data.find((item) => item.value === value);
        }
        if (!selectedItem)
            return null;
        return (_jsx(Pill, { onRemove: () => onRemove(selectedItem.value), removeButtonProps: { 'aria-hidden': undefined, 'aria-label': `Clear ${selectedItem.valueLabel}` }, withRemoveButton: true, children: selectedItem.valueLabel }, value));
    });
};
