import { jsPDF } from 'jspdf'
import autoTable from 'jspdf-autotable'

import { formatCurrency, formatIntPercentage, formatPercentage, formatValueByType } from '../formatting'
import { VERIFICATION_LABELS } from '../../_views/common/lender/InvoicesOverview/constants'
import { getApprovalStatus } from '../approval'

const getValueByType = (value, header) => {
  const { type } = header

  switch (type) {
    case 'date':
      return formatValueByType(value, 'truncatedDate')
    case 'currency':
      return formatCurrency(value || 0)
    case 'percentage':
    case 'percent':
      return formatIntPercentage(value)
    case 'precise-percentage':
      return formatPercentage(value, header?.precision || 2)
    case 'boolean':
    case 'boolean-indicator': {
      const isReverse = header?.mode === 'reverse'
      const val = isReverse ? !value : value
      return val ? '+' : '-'
    }
    case 'sentiment': {
      const label = VERIFICATION_LABELS?.[value] || ''
      return value !== 'non_verified' ? label : ''
    }
    case 'approval':
      if (typeof value === 'object') {
        return getApprovalStatus(value)
      } else {
        return getApprovalStatus({ status: value })
      }
    default:
      return value === null ? '' : String(value)
  }
}

export const tableToPdf = (data, headers, filename = 'records', format = 'a4', orientation = 'l') => {
  const doc = new jsPDF({
    orientation,
    format,
    unit: 'pt',
  })

  const headersFormatted = headers.reduce((accumulator, header) => {
    const { id, accessor, headingText, type, hideFromExportDoc } = header
    const isCustomAccessor = typeof accessor === 'function'

    if (headingText && !hideFromExportDoc) {
      accumulator.push({
        isCustomAccessor,
        key: !isCustomAccessor && accessor ? accessor : id,
        header: headingText,
        type: type,
        ...header,
      })
    }

    return accumulator
  }, [])

  let formattedData = data.map((d) => {
    return headersFormatted.reduce((result, header) => {
      const accessor = header.accessor
      const isCustomAccessor = header.isCustomAccessor
      const key = header.key
      let value
      if (key.split('.').length > 1) {
        key.split('.').reduce((data, key) => {
          if (data && key) {
            value = data[key]
          }
          return d[key]
        }, d)
      } else {
        value = isCustomAccessor ? accessor(d) : d[key]
      }

      if (key === 'invoice_number' && Array.isArray(value)) {
        value = value.map((snapshot) => snapshot?.ref_number).join(', ')
      }

      result[key] = value !== undefined ? getValueByType(value, header) : ''

      return result
    }, {})
  })

  autoTable(doc, {
    margin: { formattedData: 10 },
    body: formattedData,
    columns: headersFormatted,
  })

  doc.save(filename)
}
