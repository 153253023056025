import React from 'react'
import { FVAdminStandardPage } from './layout/FVAdminStandardPage'
import Tabs from '../_components/Tabs'
import styled from 'styled-components'
import config from 'config'
// Services

// Components
import { S_ContentColumn } from '../_components/layout/ContentColumn'

// Actions

// Helpers

const S_Build = styled.div`
  ${S_ContentColumn} {
    max-width: 1500px;
  }
  table {
    width: 100%;
  }
`

export const Build = () => {
  let flagCopy = [...config.flagsInUse]
  flagCopy.sort(function (a, b) {
    var nameA = a.flag.toUpperCase() // ignore upper and lowercase
    var nameB = b.flag.toUpperCase() // ignore upper and lowercase
    if (nameA < nameB) {
      return -1
    }
    if (nameA > nameB) {
      return 1
    }

    // names must be equal
    return 0
  })
  return (
    <S_Build>
      <FVAdminStandardPage setTenantSetter={(setTenants) => {}}>
        <Tabs>
          <div label={'Flags Used'}>
            <table>
              <thead>
                <tr>
                  <th>Flag</th>
                  <th>File</th>
                  <th>Line No.</th>
                </tr>
              </thead>
              <tbody>
                {flagCopy.map((flag) => {
                  return (
                    <tr key={flag.flag}>
                      <td>{flag.flag}</td>
                      <td>{flag.file}</td>
                      <td>{flag.lineNumber}</td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
        </Tabs>
      </FVAdminStandardPage>
    </S_Build>
  )
}
