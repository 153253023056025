import { useQuery } from '@tanstack/react-query';
import { request } from 'xen/api';
import { getTenantFromUrl } from 'xen/helpers';
import { transformFlipperFeatures } from './transform';
async function getFlipperFeatures(tenantToken) {
    try {
        const response = await request({
            method: 'GET',
            url: `/flipper/api/actors/Tenant;${tenantToken}`,
        });
        const data = transformFlipperFeatures(response);
        return data;
    }
    catch (error) {
        throw new Error('Could not fetch features');
    }
}
export const useGetFlipperFeatures = () => {
    const tenantToken = getTenantFromUrl();
    return useQuery({
        queryKey: ['flipper-features'],
        queryFn: () => getFlipperFeatures(tenantToken),
        enabled: tenantToken !== undefined,
    });
};
