import React from 'react'
import { Link } from 'react-router-dom'
import { S_LenderOverviewTile } from './LenderOverviewTile.style'

// Components
import ToolTip from './Tooltip/Tooltip'

const getTileValue = (type, value) => {
  switch (type) {
    case 'currency':
      return !value || value == 0 || value == '$0.00' ? '$0.00' : value
    case 'number':
      return value || 0
    default:
      return '-'
  }
}

const ContentItem = ({ value, label, tooltip, type }) => {
  return (
    <div className="tile-value-wrapper">
      {/* Value */}
      <div className="tile-value">{getTileValue(type, value)}</div>
      {/* Label */}
      <ToolTip content={tooltip}>{label !== null && <div className="tile-label">{label}</div>}</ToolTip>
    </div>
  )
}

export const LenderOverviewTile = (props) => {
  const {
    label = null,
    value = '',
    content = [],
    tooltip,
    type = 'single',
    href = '#',
    linkText = null,
    pending = false,
    layout = '',
    component = () => {},
    noClick,
    disableHover,
    tileClass = '',
    boxClass = '',
  } = props
  let Component = component

  return (
    <S_LenderOverviewTile className={tileClass}>
      {noClick || disableHover ? (
        <div className={`no-click ${layout} ${boxClass} ${disableHover ? '_no-hover' : ''}`}>
          {type !== 'custom' ? (
            type === 'multiple' ? (
              <>
                {content.map((contentItem, i) => {
                  if (contentItem.type === 'custom') {
                    const ContentComponent = contentItem.contentComponent
                    return <ContentComponent key={contentItem.label} />
                  } else {
                    return (
                      <ContentItem
                        key={contentItem.label}
                        label={contentItem.label}
                        tooltip={contentItem.tooltip}
                        type={contentItem.type}
                        value={contentItem.value}
                      />
                    )
                  }
                })}
              </>
            ) : (
              <ContentItem label={label} tooltip={tooltip} type={type} value={value} />
            )
          ) : (
            <Component />
          )}

          {/* Link Text */}
          {linkText !== null && <div className="tile-link">Manage Clients</div>}
        </div>
      ) : (
        <Link className={`${layout}`} to={href}>
          {type !== 'custom' ? (
            type === 'multiple' ? (
              <>
                {content.map((contentItem, i) => (
                  <ContentItem
                    key={i}
                    label={contentItem.label}
                    tooltip={contentItem.tooltip}
                    type={contentItem.type}
                    value={contentItem.value}
                  />
                ))}
              </>
            ) : (
              <ContentItem label={label} tooltip={tooltip} type={type} value={value} />
            )
          ) : (
            <Component />
          )}

          {/* Link Text */}
          {linkText !== null && <div className="tile-link">Manage Clients</div>}
        </Link>
      )}
      {pending && (
        <div className="sub-line">
          <hr></hr>
        </div>
      )}
    </S_LenderOverviewTile>
  )
}
