import { useEffect, useState } from 'react';
import { searchParam } from 'xen/helpers';
/** Wrapper around `useState` that syncs local state in URL search params. */
export const useSearchParamState = (
/** Search param key (add new ones to `searchParamConfig`) */
key, 
/** Default state value */
defaultValue, options = {}) => {
    const [value, setValue] = useState(() => searchParam.get(key, defaultValue));
    useEffect(() => {
        if (!value || (value === defaultValue && options.hideDefaultParam)) {
            searchParam.remove(key);
            return;
        }
        searchParam.set(key, value);
    }, [value, key]);
    return [value, setValue];
};
