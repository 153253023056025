import request from './axios_service'
import snakeCase from 'lodash/snakeCase'

export const emailService = {
  deliverAdminEmail,
  sendLoginLink,
  forgotPassword,
  sendProspectLink,
}

function deliverAdminEmail(emailType, content, userId) {
  return request({
    url: `/emails/${snakeCase(emailType)}`,
    method: 'POST',
    data: {
      user_id: userId,
      ...content,
    },
  })
}

function sendLoginLink(email) {
  return request({
    url: `emails/send_login_link`,
    method: 'POST',
    data: {
      email_address: email,
    },
  })
}

function sendProspectLink(email) {
  return request({
    url: `emails/send_prospect_link`,
    method: 'POST',
    data: {
      email_address: email,
    },
  })
}

function forgotPassword(email, underwriteDealId = null) {
  return request({
    url: '/emails/forgot_password',
    method: 'POST',
    data: {
      email_address: email,
      underwrite_deal_id: underwriteDealId,
    },
  })
}
