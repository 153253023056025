// Certification Images
import digicertLogo from '../assets/img/certifications/digicert.png'
import bbbLogo from '../assets/img/certifications/bbb.png'
import mcafeeLogo from '../assets/img/certifications/mcafee.png'
import ifaLogo from '../assets/img/certifications/ifa_logo.jpg'
import cfaLogo from '../assets/img/certifications/cfa_logo.jpg'
import React from 'react'
import ROUTES from '../_constants/routes'

import { PropertyProxy, ConfigProxy } from '../_helpers/util'

const DEFAULT_SIGN_IN = {
  label_title: 'Welcome Back',
  fields: [
    {
      name: 'email',
      type: 'email',
      label: 'E-mail',
      required: true,
    },
    {
      name: 'password',
      type: 'password',
      label: 'Password',
      required: true,
    },
  ],
  label_cta: 'Sign In',
}

const DEFAULT_SIGN_UP = {
  label_title: 'Sign Up',
  fields: [
    {
      name: 'email',
      type: 'email',
      label: 'E-mail',
      required: true,
    },
    {
      name: 'business_phone_number',
      type: 'tel',
      label: 'Business Phone Number',
      required: true,
    },
    {
      name: 'password',
      type: 'password',
      label: 'Password',
      required: true,
    },
  ],
  label_cta: 'Sign Up',
}

const DEFAULT_FORGOT_PASSWORD = {
  label_title: 'Forgot Password',
  fields: [
    {
      name: 'email',
      type: 'email',
      label: 'E-mail',
      required: true,
    },
  ],
  label_cta: 'Send Reset Link',
}

const DEFAULT_RESET_PASSWORD = {
  label_title: 'Reset Password',
  fields: [
    {
      name: 'email',
      type: 'email',
      label: 'Email',
      prospect_link_applicable: false,
      required: true,
    },
    {
      name: 'temp_password',
      type: 'password',
      label: 'Temporary Password',
      prospect_link_applicable: false,
      required: true,
    },
    {
      name: 'password',
      type: 'password',
      label: 'New Passsword',
      prospect_link_applicable: true,
      required: true,
    },
    {
      name: 'confirm_password',
      type: 'password',
      label: 'Confirm New Password',
      prospect_link_applicable: true,
      required: true,
    },
  ],
  label_cta: 'Reset Password',
}

const DEFAULT_COMMON = {
  show_auth_pg_logo: true,
  show_auth_bg: true,
  label_invoices_to_cash: 'Turn invoices into cash',
  upper_list: ['Upload Invoice', 'Get Paid'],
  contact_info: null,
}
const DEFAULT_AUTH_CONFIG = {
  sign_in: DEFAULT_SIGN_IN,
  sign_up: DEFAULT_SIGN_UP,
  forgot_password: DEFAULT_FORGOT_PASSWORD,
  reset_password: DEFAULT_RESET_PASSWORD,
  common: DEFAULT_COMMON,
}

export const AuthConfig = ConfigProxy(
  {
    capitalnow: PropertyProxy(
      {
        common: {
          ...DEFAULT_COMMON,
          upper_list: ['Complete Application', 'Upload Invoices', 'Get Funded'],
        },
      },
      DEFAULT_AUTH_CONFIG
    ),
    canntella: PropertyProxy(
      {
        common: {
          ...DEFAULT_COMMON,
          upper_list: ['Create an Account', 'Submit Your Info', 'Get Paid'],
        },
      },
      DEFAULT_AUTH_CONFIG
    ),
    sevenoaks: PropertyProxy(
      {
        sign_up: {
          ...DEFAULT_SIGN_UP,
          label_title: 'Apply Now',
          label_cta: 'Apply Now',
        },
        common: {
          show_auth_pg_logo: true,
          label_invoices_to_cash: 'Submit your application.',
        },
      },
      DEFAULT_AUTH_CONFIG
    ),
    boldbusinesscapital: PropertyProxy(
      {
        sign_up: {
          ...DEFAULT_SIGN_UP,
          redirect: ROUTES.confirm_form,
        },
        common: {
          ...DEFAULT_COMMON,
          upper_list: ['Complete form', 'Upload AR', 'Get quote'],
        },
      },
      DEFAULT_AUTH_CONFIG
    ),
    portercapital: PropertyProxy(
      {
        sign_up: {
          ...DEFAULT_SIGN_UP,
          redirect: ROUTES.confirm_form,
          label_cta: 'Apply Online',
        },
        common: {
          ...DEFAULT_COMMON,
          upper_list: null,
          label_invoices_to_cash: 'Providing Working Capital Overnight Since 1991',
          label_invoices_to_cash_style: {
            marginTop: '30px',
            fontSize: '20px',
            fontWeight: 'normal',
            color: '#999999',
          },
        },
      },
      DEFAULT_AUTH_CONFIG
    ),
    twelvefive: PropertyProxy(
      {
        sign_up: {
          ...DEFAULT_SIGN_UP,
          fields: [
            {
              name: 'full_name',
              type: 'text',
              label: 'First & Last Name',
              required: true,
            },
            {
              name: 'email',
              type: 'email',
              label: 'E-mail',
              required: true,
            },
            {
              name: 'business_phone_number',
              type: 'tel',
              label: 'Business Phone Number',
              required: true,
            },
            {
              name: 'password',
              type: 'password',
              label: 'New Password',
              required: true,
            },
          ],
        },
        common: {
          show_auth_pg_logo: true,
          label_invoices_to_cash: 'Cultivating Your Passion\nThrough Finance.',
          upper_list: [],
        },
      },
      DEFAULT_AUTH_CONFIG
    ),
    sallyportcf: PropertyProxy(
      {
        sign_up: {
          ...DEFAULT_SIGN_UP,
          label_title: 'Apply Now',
          label_cta: 'Apply Now',
        },
        common: {
          ...DEFAULT_COMMON,
          upper_list: ['Complete Form', 'Upload AR', 'Get Quote'],
        },
      },
      DEFAULT_AUTH_CONFIG
    ),
    newcenturyfinancial: PropertyProxy(
      {
        sign_up: {
          label_title: 'Get Started',
          redirect: ROUTES.confirm_form,
          fields: [
            {
              name: 'first_name',
              type: 'text',
              label: 'First Name',
              required: true,
            },
            {
              name: 'last_name',
              type: 'text',
              label: 'Last Name',
              required: true,
            },
            {
              name: 'email',
              type: 'email',
              label: 'E-mail',
              required: true,
            },
            {
              name: 'business_phone_number',
              type: 'tel',
              label: 'Business Phone Number',
              required: true,
            },
            {
              name: 'password',
              type: 'password',
              label: 'Password',
              required: true,
            },
            {
              name: 'tell_us_more',
              type: 'textarea',
              label: 'Tell Us More',
            },
          ],
          label_cta: 'Get Started',
        },
        common: {
          ...DEFAULT_COMMON,
          lower_list: [
            'No term contract',
            'No monthly minimums',
            'Credit lines up to $5,000,000',
            'Proposal within 24 hours',
          ],
          link_pdf_application: 'https://www.newcenturyfinancial.com/pdf/REVISED-NCF-Application.pdf',
          certifications: [
            {
              img: digicertLogo,
              alt: 'Digicert Trusted Certificate',
            },
            {
              img: bbbLogo,
              alt: 'BBB Accredited Business',
              href: 'https://www.bbb.org/houston/business-reviews/factoring-service/new-century-financial-in-the-woodlands-tx-90002376/#bbbonlineclick',
            },
            {
              img: mcafeeLogo,
              alt: 'McAfee Secure',
              href: 'https://www.mcafeesecure.com/verify?host=newcenturyfinancial.com',
            },
          ],
        },
        modal_body: (
          <div>
            Thank you for your inquiry. To apply for a proposal within 24 hours please click the &#39;Apply Now&#39;
            button below or download the{' '}
            <a href="https://www.newcenturyfinancial.com/pdf/REVISED-NCF-Application.pdf" style={{ color: '#900' }}>
              PDF Application
            </a>
            .
          </div>
        ),
        modal_title: <h3>Your inquiry was sent successfully</h3>,
      },
      DEFAULT_AUTH_CONFIG
    ),
    scfactoringsolution: PropertyProxy(
      {
        sign_up: {
          label_title: 'Apply Now',
          redirect: ROUTES.confirm_form,
          fields: [
            {
              name: 'business_name',
              type: 'text',
              label: 'Business Name',
              required: true,
            },
            {
              name: 'first_name',
              type: 'text',
              label: 'First Name',
              required: true,
            },
            {
              name: 'last_name',
              type: 'text',
              label: 'Last Name',
              required: true,
            },
            {
              name: 'email',
              type: 'email',
              label: 'E-mail',
              required: true,
            },
            {
              name: 'business_phone_number',
              type: 'tel',
              label: 'Business Phone Number',
              required: true,
            },
            {
              name: 'business_website',
              type: 'text',
              label: 'Business Website',
              required: true,
            },
            {
              name: 'password',
              type: 'password',
              label: 'Password',
              required: true,
            },
          ],
          label_cta: 'Apply Now',
        },
        common: {
          ...DEFAULT_COMMON,
          upper_list: [
            'Upload Invoice(s)',
            'After the invoices are verified, approved by insurance company and loaded into the system, we are ready to fund immediately',
            'We continue funding (revolving) up to the limit of your approved facility, granting you fresh cash flow for your working capital',
          ],
        },
      },
      DEFAULT_AUTH_CONFIG
    ),
    acsfactors: PropertyProxy(
      {
        sign_up: {
          label_title: 'Sign Up',
          redirect: ROUTES.confirm_form,
          fields: [
            {
              name: 'email',
              type: 'email',
              label: 'E-mail',
              required: true,
            },
            {
              name: 'business_phone_number',
              type: 'tel',
              label: 'Business Phone Number',
              required: true,
            },
            {
              name: 'password',
              type: 'password',
              label: 'Create a Password',
              required: true,
            },
            {
              name: 'is_carrier',
              type: 'radio',
              label: 'Are you a carrier/trucking company?',
              required: true,
              options: [
                {
                  label: 'Yes',
                  value: true,
                },
                {
                  label: 'No',
                  value: false,
                },
              ],
              following_question: {
                name: 'is_new_carrier',
                type: 'radio',
                label: 'Are you a new carrier?',
                condition: true,
                options: [
                  {
                    label: 'Yes',
                    value: true,
                  },
                  {
                    label: 'No',
                    value: false,
                  },
                ],
              },
            },
          ],
          label_cta: 'Sign Up',
        },
        sign_in: {
          ...DEFAULT_SIGN_IN,
          redirect: ROUTES.confirm_form,
        },
        common: {
          ...DEFAULT_COMMON,
          show_auth_bg: false,
          label_invoices_to_cash: 'Factoring Invoices >> Providing Solutions',
          upper_list: ['Apply', 'Submit Invoices', `Check your Bank Account - You've got Funds`],
          link_pdf_application: 'https://acsfactors.com/apps/Application_Form.pdf',
        },
      },
      DEFAULT_AUTH_CONFIG
    ),
    ccbg: PropertyProxy(
      {
        common: {
          ...DEFAULT_COMMON,
          upper_list: [],
        },
      },
      DEFAULT_AUTH_CONFIG
    ),
    marcocapital: PropertyProxy(
      {
        common: {
          ...DEFAULT_COMMON,
          label_invoices_to_cash: 'Fuel exports with working capital\n(that actually works)\nsay hola to marco.',
          label_invoices_to_cash_style: {
            fontSize: '18px',
            fontWeight: 'normal',
          },
        },
      },

      DEFAULT_AUTH_CONFIG
    ),
    marcofi: PropertyProxy(
      {
        common: {
          ...DEFAULT_COMMON,
          label_invoices_to_cash: 'Fuel exports with working capital\n(that actually works)\nsay hola to marco.',
          label_invoices_to_cash_style: {
            fontSize: '18px',
            fontWeight: 'normal',
          },
          upper_list: [
            'Fill out a brief web application.',
            'Get a funding decision in 5-10 business days.',
            'Tech-enabled workflow built to support growth.',
            'Visibility from invoice to payments',
          ],
        },
      },

      DEFAULT_AUTH_CONFIG
    ),
    chartercapitalusa: PropertyProxy(
      {
        sign_up: {
          label_title: 'Sign Up',
          redirect: ROUTES.confirm_form,
          fields: [
            {
              name: 'business_name',
              type: 'text',
              label: 'Business Name',
              required: true,
            },
            {
              name: 'email',
              type: 'email',
              label: 'E-mail',
              required: true,
            },
            {
              name: 'business_phone_number',
              type: 'tel',
              label: 'Business Phone Number',
              required: true,
            },
            {
              name: 'password',
              type: 'password',
              label: 'New Password',
              required: true,
            },
          ],
          label_cta: 'Sign Up',
        },
        common: {
          ...DEFAULT_COMMON,
          upper_list: [],
          lower_list: [
            'Same-Day Funding',
            'No Term Contracts',
            'Low Rates',
            'No Hidden Fees',
            'Dedicated Account Manager',
          ],
          certifications: [
            {
              img: ifaLogo,
              alt: 'International Factoring Association Certificate',
            },
            {
              img: cfaLogo,
              alt: 'Commercial Finance Association Certificate',
            },
          ],
        },
      },
      DEFAULT_AUTH_CONFIG
    ),
    hedayacapital: PropertyProxy(
      {
        sign_up: {
          ...DEFAULT_SIGN_UP,
          label_title: 'Apply Now',
          label_cta: 'Apply Now',
        },
        common: {
          ...DEFAULT_COMMON,
          upper_list: ['Fill out an application', 'Upload Information', 'Get Funded'],
        },
      },
      DEFAULT_AUTH_CONFIG
    ),
    velocityfi: PropertyProxy(
      {
        sign_up: {
          ...DEFAULT_SIGN_UP,
        },
        common: {
          show_auth_pg_logo: true,
          label_invoices_to_cash: 'Accelerate the velocity \nof your cash flow today!',
          upper_list: [],
        },
      },
      DEFAULT_AUTH_CONFIG
    ),
    republicbc: PropertyProxy(
      {
        sign_up: {
          label_title: 'Apply Now',
          label_cta: 'Apply Now',
          redirect: ROUTES.confirm_form,
          fields: [
            {
              name: 'business_name',
              type: 'text',
              label: 'Business Name',
              required: true,
            },
            {
              name: 'full_name',
              type: 'text',
              label: 'First & Last Name',
              required: true,
            },
            {
              name: 'email',
              type: 'email',
              label: 'E-mail',
              required: true,
            },
            {
              name: 'business_phone_number',
              type: 'tel',
              label: 'Business Phone Number',
              required: true,
            },
            {
              name: 'password',
              type: 'password',
              label: 'New Password',
              required: true,
            },
          ],
        },
        common: {
          show_auth_pg_logo: true,
          label_invoices_to_cash: 'Working capital for your\nbusiness.',
          upper_list: [],
        },
      },
      DEFAULT_AUTH_CONFIG
    ),
    newbridgeglobal: PropertyProxy(
      {
        fields: [
          {
            name: 'email',
            type: 'email',
            label: 'E-mail',
            required: true,
          },
          {
            name: 'business_phone_number',
            type: 'tel',
            label: 'Business Phone Number',
            required: true,
          },
          {
            name: 'password',
            type: 'password',
            label: 'New Password',
            required: true,
          },
        ],
        common: {
          show_auth_pg_logo: true,
          label_invoices_to_cash: 'We offer worldwide financing\nsolutions to our clients.',
          lower_list: [
            'Our solutions give our customers better repayment terms on goods purchased or financing on accounts receivables.',
          ],
        },
        label_cta: 'Apply Now',
      },
      DEFAULT_AUTH_CONFIG
    ),
    axosbank: PropertyProxy(
      {
        common: {
          ...DEFAULT_COMMON,
          upper_list: ['Complete Form', 'Get Approved', 'Get Paid'],
          label_invoices_to_cash: 'Transform Your Invoices into\nCash!',
        },
      },
      DEFAULT_AUTH_CONFIG
    ),
    ctrlpay: PropertyProxy(
      {
        common: {
          ...DEFAULT_COMMON,
          upper_list: [
            'Quick ~ in as little as 24 hours',
            'Cheap ~ starting at 3.5%',
            'Easy ~ upload invoices, wait for approval, get paid',
          ],
          label_invoices_to_cash: 'Your cashflow solution',
          bg_size: 'cover',
        },
      },
      DEFAULT_AUTH_CONFIG
    ),
    fastarfunding: PropertyProxy(
      {
        common: {
          ...DEFAULT_COMMON,
          upper_list: null,
          label_invoices_to_cash: '',
          bg_size: 'cover',
          show_auth_bg: false,
          sign_in_logo_style: {
            maxWidth: '300px',
          },
        },
      },
      DEFAULT_AUTH_CONFIG
    ),
    capitalplus: PropertyProxy(
      {
        common: {
          ...DEFAULT_COMMON,
          show_auth_bg: false,
          label_invoices_to_cash: (
            <span>
              <span style={{ fontFamily: 'Avenir Black', color: '#55ae55' }}>We Keep</span>
              <span style={{ fontFamily: 'Avenir Black', color: '#1c3978' }}> You Building</span>
            </span>
          ),
          upper_list: null,
        },
      },
      {
        sign_in: DEFAULT_SIGN_IN,
        sign_up: {
          label_title: 'Get started',
          fields: [
            {
              name: 'first_name',
              type: 'text',
              label: 'Name',
              required: true,
            },
            {
              name: 'business_name',
              type: 'text',
              label: 'Company',
              required: true,
            },
            {
              name: 'email',
              type: 'email',
              label: 'E-mail',
              required: true,
            },
            {
              name: 'business_phone_number',
              type: 'tel',
              label: 'Business Phone Number',
              required: true,
            },
            {
              name: 'password',
              type: 'password',
              label: 'Password',
              required: true,
            },
            {
              name: 'how_hear_about_us',
              type: 'select',
              options: [
                { value: 'linkedin-facebook', label: 'Linkedin or Facebook' },
                { value: 'colleague-friend', label: 'Colleague or Friend' },
                { value: 'article-press', label: 'Article/Press Release' },
                { value: 'advertisement', label: 'Advertisement' },
                { value: 'google-search', label: 'Google/Search Engine' },
                { value: 'email', label: 'Email' },
                { value: 'conference-tradeshow', label: 'Conference or Tradeshow' },
                { value: 'other', label: 'Other' },
              ],
              label: 'How Did You Hear About Us?',
              required: false,
            },
            {
              name: 'tell_us_more',
              type: 'textarea',
              label: 'Your Message',
              required: false,
            },
          ],
          label_cta: 'Get started',
        },
        forgot_password: DEFAULT_FORGOT_PASSWORD,
        reset_password: DEFAULT_RESET_PASSWORD,
        common: DEFAULT_COMMON,
      }
    ),
    capitalplusfinancial: PropertyProxy(
      {
        common: {
          ...DEFAULT_COMMON,
          show_auth_bg: false,
          label_invoices_to_cash: (
            <span>
              <span style={{ fontFamily: 'Avenir Black', color: '#55ae55' }}>We Keep</span>
              <span style={{ fontFamily: 'Avenir Black', color: '#1c3978' }}> You Building</span>
            </span>
          ),
          upper_list: null,
        },
      },
      {
        sign_in: DEFAULT_SIGN_IN,
        sign_up: {
          label_title: 'Get started',
          fields: [
            {
              name: 'first_name',
              type: 'text',
              label: 'Name',
              required: true,
            },
            {
              name: 'business_name',
              type: 'text',
              label: 'Company',
              required: true,
            },
            {
              name: 'email',
              type: 'email',
              label: 'E-mail',
              required: true,
            },
            {
              name: 'business_phone_number',
              type: 'tel',
              label: 'Business Phone Number',
              required: true,
            },
            {
              name: 'password',
              type: 'password',
              label: 'Password',
              required: true,
            },
            {
              name: 'how_hear_about_us',
              type: 'select',
              options: [
                { value: 'linkedin-facebook', label: 'Linkedin or Facebook' },
                { value: 'colleague-friend', label: 'Colleague or Friend' },
                { value: 'article-press', label: 'Article/Press Release' },
                { value: 'advertisement', label: 'Advertisement' },
                { value: 'google-search', label: 'Google/Search Engine' },
                { value: 'email', label: 'Email' },
                { value: 'conference-tradeshow', label: 'Conference or Tradeshow' },
                { value: 'other', label: 'Other' },
              ],
              label: 'How Did You Hear About Us?',
              required: false,
            },
            {
              name: 'tell_us_more',
              type: 'textarea',
              label: 'Your Message',
              required: false,
            },
          ],
          label_cta: 'Get started',
        },
        forgot_password: DEFAULT_FORGOT_PASSWORD,
        reset_password: DEFAULT_RESET_PASSWORD,
        common: DEFAULT_COMMON,
      }
    ),
    changecapital: PropertyProxy(
      {
        common: {
          ...DEFAULT_COMMON,
          show_auth_bg: false,
        },
      },
      {
        sign_in: DEFAULT_SIGN_IN,
        sign_up: DEFAULT_SIGN_UP,
        forgot_password: DEFAULT_FORGOT_PASSWORD,
        reset_password: DEFAULT_RESET_PASSWORD,
        common: DEFAULT_COMMON,
      }
    ),
    fundient: PropertyProxy(
      {
        common: {
          ...DEFAULT_COMMON,
          show_auth_bg: false,
        },
      },
      DEFAULT_AUTH_CONFIG
    ),
    interportcapital: PropertyProxy(
      {
        common: {
          ...DEFAULT_COMMON,
          show_auth_bg: false,
          label_invoices_to_cash: null,
        },
      },
      DEFAULT_AUTH_CONFIG
    ),
  },
  DEFAULT_AUTH_CONFIG
)
