import { jsx as _jsx } from "react/jsx-runtime";
import { createContext, useContext } from 'react';
import { useSearchParamState } from 'xen/hooks';
const Context = createContext({
    page: undefined,
    perPage: undefined,
    setPage: () => { },
    setPerPage: () => { },
});
/**
 * Share state setters/getters for syncing standard pagination state (i.e.
 * params generally compatible with our server's Pagy setup) to URL.
 */
export const PaginationSearchParamsProvider = ({ children }) => {
    // Current page number
    const [page, setPage] = useSearchParamState('page', '1');
    // Current page size
    const [perPage, setPerPage] = useSearchParamState('perPage', '10');
    return (_jsx(Context.Provider, { value: {
            page,
            perPage,
            setPage,
            setPerPage,
        }, children: children }));
};
export const usePaginationSearchParamsContext = () => {
    const context = useContext(Context);
    return context;
};
