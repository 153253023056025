import { esigningConstants } from '../_constants'
import { alertActions, modalActions, notificationsActions } from './'
import { queryClient } from 'xen/providers/query-provider'

// Components
import { EsigningDocumentsList } from '../_components/modals/esigning/EsigningDocumentsList'

//Helpers
import HelloSign from 'hellosign-embedded'
import { getSignatureRequestUrl, getESigningRequests } from '../_services/v2/documents.service'

export const esigningActions = {
  getEsigningRequests,
  openEsigningDocument,
  signEsigningDocument,
}

function getEsigningRequests(userId, showAlert = false) {
  return (dispatch) => {
    dispatch(request(userId))
    return getESigningRequests()
      .then((resp) => {
        dispatch(success(resp.count, resp.signature_requests))

        if (showAlert && resp.count > 0) {
          dispatch(
            alertActions.showAlert({
              title: `You have ${resp.count} document(s) to sign`,
              type: `info`,
              confirmButtonText: `Sign Documents Now`,
              cancelButtonText: `Later`,
              onConfirm: () => {
                dispatch(modalActions.openModal(EsigningDocumentsList))
              },
            })
          )
        }
      })
      .catch((error) => dispatch(failure(error)))
  }

  function request(userId) {
    return { type: esigningConstants.GET_ESIGNING_REQUESTS_REQUEST, userId }
  }
  function success(requestsCount, requests) {
    return { type: esigningConstants.GET_ESIGNING_REQUESTS_SUCCESS, requestsCount, requests }
  }
  function failure(error) {
    return { type: esigningConstants.GET_ESIGNING_REQUESTS_FAILURE, error }
  }
}

function openEsigningDocument(doc, user, env, lastDoc) {
  return (dispatch) => {
    dispatch(request(doc.signature_request_id, user.id))

    try {
      const signatureRequest = {
        document_title: doc.template.label,
        document_created_at: doc.request_date,
        client_id: doc.clientId,
      }
      dispatch(success(signatureRequest))

      const docSigner = doc.signers.find((signer) => signer.email === user.email_address)

      getSignatureRequestUrl(docSigner.s_gid).then((response) => {
        const { sign_url, client_id } = response

        const client = new HelloSign({
          clientId: client_id,
        })

        client.open(sign_url, {
          allowCancel: false,
          container: document.getElementById(`esigning-container`),
        })

        client.on('sign', () => {
          dispatch(signEsigningDocument(doc, user.id, lastDoc))
        })
      })
    } catch (error) {
      dispatch(failure(error.message))
      dispatch(
        notificationsActions.showNotification({
          type: `error`,
          message: error.message,
        })
      )
    }
  }

  function request(requestId, userId) {
    return { type: esigningConstants.OPEN_ESIGNING_DOCUMENT_REQUEST, requestId, userId }
  }
  function success(signatureRequest) {
    return { type: esigningConstants.OPEN_ESIGNING_DOCUMENT_SUCCESS, signatureRequest }
  }
  function failure(error) {
    return { type: esigningConstants.OPEN_ESIGNING_DOCUMENT_FAILURE, error }
  }
}

function signEsigningDocument(doc, userId, lastDoc) {
  return (dispatch) => {
    dispatch(success())

    dispatch(
      notificationsActions.showNotification({
        type: `success`,
        message: `Document successfully signed!`,
      })
    )

    if (lastDoc) {
      dispatch(
        notificationsActions.showNotification({
          type: `info`,
          message: `All documents have been successfully signed!`,
        })
      )
    }

    setTimeout(async () => {
      await Promise.all([
        dispatch(getEsigningRequests(userId)),
        queryClient.invalidateQueries({
          queryKey: ['deals', doc.external_entity_id, 'contracts'],
        }),
      ])

      if (lastDoc) {
        dispatch(modalActions.closeModal())
      } else {
        dispatch(modalActions.switchModal(EsigningDocumentsList))
      }
    }, 3000)
  }

  function success() {
    return { type: esigningConstants.SIGN_ESIGNING_DOCUMENT }
  }
}
