import { accountingConstants } from '../_constants'

const intialState = {
  activeCustomers: [],
}

export function accounting(state = intialState, action) {
  switch (action.type) {
    case accountingConstants.SET_ACCOUNTING:
      return {
        ...state,
        connected: action.connected,
        platform: action.platform,
      }
    case accountingConstants.SET_ACTIVE_CUSTOMERS:
      return {
        ...state,
        activeCustomers: action.customers,
      }
    case accountingConstants.ADD_ACTIVE_CUSTOMERS: {
      let activeCustomersCopy1 = [...state.activeCustomers]
      activeCustomersCopy1.push(action.customer)
      return {
        ...state,
        activeCustomers: activeCustomersCopy1,
      }
    }
    case accountingConstants.UPDATE_ACTIVE_CUSTOMER: {
      let activeCustomersCopy2 = [...state.activeCustomers]
      activeCustomersCopy2[action.index] = action.customer
      return {
        ...state,
        activeCustomers: activeCustomersCopy2,
      }
    }
    default:
      return state
  }
}
