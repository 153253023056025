import ROUTES from '../../../_constants/routes'
import { BUSINESS_ENTITY_OPTIONS } from '../../form.constants'

export const fundient_config = {
  crumbs: [
    {
      label: 'Your Invoices',
      link: ROUTES.upload_invoices,
    },
    {
      label: 'Business Details',
      link: ROUTES.confirm_form,
    },
    {
      label: 'Submitted',
      link: ROUTES.submitted,
    },
  ],
  sections: [
    {
      title: 'Contact Details',
      type: 'contact_details',
      newProspectTitle: 'Required Prospect Details',
      newProspectSubtitle: 'Please enter the following information to create a new prospect.',
      tooltip: 'Please tell us about your business.',
      fields: [
        {
          type: 'text',
          name: 'business_name',
          table: 'business',
          placeholder: 'Business Name',
          label: 'Business Name',
          required: true,
        },
        {
          type: 'select',
          name: 'business_entity',
          table: 'business',
          placeholder: 'Business Entity',
          label: 'Business Entity',
          options: BUSINESS_ENTITY_OPTIONS,
          required: true,
        },
        [
          {
            type: 'text',
            name: 'address_street',
            table: 'business',
            placeholder: 'Street',
            label: 'Business Address',
            required: true,
          },
          {
            type: 'text',
            name: 'address_unit',
            table: 'business',
            placeholder: 'Unit',
            required: false,
          },
          {
            type: 'text',
            name: 'address_city',
            table: 'business',
            placeholder: 'City',
            label: 'City',
            required: true,
          },
          {
            type: 'text',
            name: 'address_state',
            table: 'business',
            placeholder: 'State',
            label: 'State',
            required: true,
          },
          {
            type: 'text',
            name: 'address_zip_code',
            table: 'business',
            placeholder: 'Zip Code',
            label: 'Zip Code',
            required: true,
          },
        ],
        {
          type: 'tel',
          name: 'business_phone_number',
          table: 'business',
          placeholder: 'Phone Number',
          label: 'Phone Number',
          lenderRequired: true,
          required: true,
        },
        {
          type: 'text',
          name: 'business_website',
          table: 'business',
          placeholder: 'Website',
          label: 'Website',
          required: false,
        },
      ],
    },
    {
      title: 'Business Officer(s)',
      type: 'business_officers',
      label: 'Business Officer',
      fields: [
        {
          type: 'text',
          name: 'first_name',
          table: 'business_officers',
          label: 'First Name',
          placeholder: 'First Name',
          required: true,
        },
        {
          type: 'text',
          name: 'last_name',
          table: 'business_officers',
          label: 'Last Name',
          placeholder: 'Last Name',
          required: true,
        },
        {
          type: 'text',
          name: 'title',
          table: 'business_officers',
          label: 'Title',
          placeholder: 'Title',
          required: true,
        },
        {
          type: 'percentage',
          name: 'pct_ownership',
          table: 'business_officers',
          label: 'Percent Ownership',
          placeholder: 'Percent Ownership',
          required: true,
        },
        {
          type: 'tel',
          name: 'phone_number',
          table: 'business_officers',
          label: 'Phone',
          placeholder: 'Phone',
          required: true,
        },
        {
          type: 'ssn',
          name: 'ssn_last4',
          table: 'business_officers',
          label: 'SSN',
          placeholder: 'SSN',
          required: true,
        },
        {
          type: 'email',
          name: 'email_address',
          table: 'business_officers',
          label: 'Email Address',
          placeholder: 'Email Address',
          required: true,
        },
      ],
    },
    {
      title: 'Financial Details',
      tooltip: 'Please give us a sense of how much funding you need and your current state of finances.',
      newProspectSubtitle: `Please give us a sense of how much funding the prospect needs and their current state of finances.`,
      fields: [
        {
          type: 'radio',
          name: 'currently_being_financed',
          table: 'business',
          label: 'Does the business currently have financing?',
          options: [
            {
              label: 'Yes',
              value: true,
            },
            {
              label: 'No',
              value: false,
            },
          ],
          tooltip: `'Yes' if you have one of the following: Credit Card, SBA Loan, Merchant Cash Advance, any other type of loan.`,
        },
      ],
    },
    {
      type: 'upload_documents',
      title: 'Document Details',
      tooltip:
        'Upload the following documents to expedite your application. Document uploads with "I will do it later" are optional.',
      fields: [
        [
          {
            type: 'upload_document',
            name: 'aged_receivables',
            table: 'deal',
            label: 'Aged Receivables',
            options: [
              {
                label: 'Upload file',
                value: 'upload',
              },
              {
                label: 'I will do it later',
                value: 'later',
              },
            ],
          },
          {
            type: 'upload_document',
            name: 'aged_payables',
            table: 'deal',
            label: 'Aged Payables',
            options: [
              {
                label: 'Upload file',
                value: 'upload',
              },
              {
                label: 'I will do it later',
                value: 'later',
              },
            ],
          },
          {
            type: 'upload_document',
            name: 'profit_and_loss',
            table: 'deal',
            label: 'Profit and Loss',
            options: [
              {
                label: 'Upload file',
                value: 'upload',
              },
              {
                label: 'I will do it later',
                value: 'later',
              },
            ],
          },
          {
            type: 'upload_document',
            name: 'balance_sheet',
            table: 'deal',
            label: 'Balance Sheet',
            options: [
              {
                label: 'Upload file',
                value: 'upload',
              },
              {
                label: 'I will do it later',
                value: 'later',
              },
            ],
          },
          {
            type: 'upload_document',
            name: 'cash_flow',
            table: 'deal',
            label: 'Cash Flow',
            options: [
              {
                label: 'Upload file',
                value: 'upload',
              },
              {
                label: 'I will do it later',
                value: 'later',
              },
            ],
          },
          {
            type: 'upload_document',
            name: 'sales_summary',
            table: 'deal',
            label: 'Sales Summary',
            tooltip: 'Please provide for each of your customers (for multiple files, upload one Zip file)',
            options: [
              {
                label: 'Upload file',
                value: 'upload',
              },
              {
                label: 'I will do it later',
                value: 'later',
              },
            ],
          },
          {
            type: 'upload_document',
            name: 'bank_statement',
            table: 'deal',
            label: 'Bank Statement from last month',
            options: [
              {
                label: 'Upload file',
                value: 'upload',
              },
              {
                label: 'I will do it later',
                value: 'later',
              },
            ],
          },
          {
            type: 'upload_document',
            name: 'bank_statement_2',
            table: 'deal',
            label: 'Bank Statement from 2 months ago',
            options: [
              {
                label: 'Upload file',
                value: 'upload',
              },
              {
                label: 'I will do it later',
                value: 'later',
              },
            ],
          },
          {
            type: 'upload_document',
            name: 'bank_statement_3',
            table: 'deal',
            label: 'Bank Statement from 3 months ago',
            options: [
              {
                label: 'Upload file',
                value: 'upload',
              },
              {
                label: 'I will do it later',
                value: 'later',
              },
            ],
          },
        ],
      ],
    },
  ],
}
