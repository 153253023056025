import { jsx as _jsx } from "react/jsx-runtime";
import { ApplicationFormattedStageStatus } from './components/application-formatted-stage-status';
/**
 * By default our object keys are automatically split on numbers when converted
 * from camel to snake case, but in some cases we don't want them to be. Instead
 * of (or in addition to) changing the default behavior of our `formatToSnakeCase`
 * function, we can manually specify which keys should not be split on numbers,
 * or provide any custom key transform on a per-key basis.
 *
 * Keys including isolated letters also need to be specified here because they will be joined
 * when converted to camel case.
 * E.g.:
 *  convicted_of_a_felony is camelCased to convictedOfAFelony
 *  but convictedOfAFelony is snake_cased to convicted_of_afelony, not convicted_of_a_felony
 *
 * Preserve "snake" case of the following keys when sent to server:
 */
export const DEAL_KEY_MAP = {
    line1: 'line1',
    line2: 'line2',
    w9: 'w9',
    convictedOfAFelony: 'convicted_of_a_felony',
};
export const DEAL_STAGE_STATUSES = [
    'application_ready_to_start',
    'application_in_progress',
    'application_complete',
    'qualification_ready_to_start',
    'qualification_in_progress',
    'qualification_complete',
    'underwriting_ready_to_start',
    'underwriting_in_progress',
    'underwriting_complete',
    'contracting_ready_to_start',
    'contracting_in_progress',
    'contracting_complete',
];
export const DEAL_STAGE_STATUS_COLORS = {
    READY_TO_START: '#A4CBFA',
    IN_PROGRESS: '#3271DE',
    COMPLETE: '#163262',
};
// Render each stage status in a dropdown group
export const GROUPED_DEAL_STAGE_STATUS_SELECT_DATA = [
    {
        group: 'Application',
        items: [
            { label: 'Application: Ready to start', value: 'application_ready_to_start' },
            { label: 'Application: In progress', value: 'application_in_progress' },
            { label: 'Application: Complete', value: 'application_complete' },
        ],
    },
    {
        group: 'Qualification',
        items: [
            { label: 'Qualification: Ready to start', value: 'qualification_ready_to_start' },
            { label: 'Qualification: In progress', value: 'qualification_in_progress' },
            { label: 'Qualification: Complete', value: 'qualification_complete' },
        ],
    },
    {
        group: 'Underwriting',
        items: [
            { label: 'Underwriting: Ready to start', value: 'underwriting_ready_to_start' },
            { label: 'Underwriting: In progress', value: 'underwriting_in_progress' },
            { label: 'Underwriting: Complete', value: 'underwriting_complete' },
        ],
    },
    {
        group: 'Contracting',
        items: [
            { label: 'Contracting: Ready to start', value: 'contracting_ready_to_start' },
            { label: 'Contracting: In progress', value: 'contracting_in_progress' },
            { label: 'Contracting: Complete', value: 'contracting_complete' },
        ],
    },
];
export const GROUPED_DEAL_STAGE_STATUS_SELECT_DATA_WITH_CUSTOM_VALUE_LABELS = [
    {
        group: 'Application',
        items: [
            {
                label: 'Ready to start',
                value: 'application_ready_to_start',
                valueLabel: _jsx(ApplicationFormattedStageStatus, { stageStatus: "application_ready_to_start" }),
            },
            {
                label: 'In progress',
                value: 'application_in_progress',
                valueLabel: _jsx(ApplicationFormattedStageStatus, { stageStatus: "application_in_progress" }),
            },
            {
                label: 'Complete',
                value: 'application_complete',
                valueLabel: _jsx(ApplicationFormattedStageStatus, { stageStatus: "application_complete" }),
            },
        ],
    },
    {
        group: 'Qualification',
        items: [
            {
                label: 'Ready to start',
                value: 'qualification_ready_to_start',
                valueLabel: _jsx(ApplicationFormattedStageStatus, { stageStatus: "qualification_ready_to_start" }),
            },
            {
                label: 'In progress',
                value: 'qualification_in_progress',
                valueLabel: _jsx(ApplicationFormattedStageStatus, { stageStatus: "qualification_in_progress" }),
            },
            {
                label: 'Complete',
                value: 'qualification_complete',
                valueLabel: _jsx(ApplicationFormattedStageStatus, { stageStatus: "qualification_complete" }),
            },
        ],
    },
    {
        group: 'Underwriting',
        items: [
            {
                label: 'Ready to start',
                value: 'underwriting_ready_to_start',
                valueLabel: _jsx(ApplicationFormattedStageStatus, { stageStatus: "underwriting_ready_to_start" }),
            },
            {
                label: 'In progress',
                value: 'underwriting_in_progress',
                valueLabel: _jsx(ApplicationFormattedStageStatus, { stageStatus: "underwriting_in_progress" }),
            },
            {
                label: 'Complete',
                value: 'underwriting_complete',
                valueLabel: _jsx(ApplicationFormattedStageStatus, { stageStatus: "underwriting_complete" }),
            },
        ],
    },
    {
        group: 'Contracting',
        items: [
            {
                label: 'Ready to start',
                value: 'contracting_ready_to_start',
                valueLabel: _jsx(ApplicationFormattedStageStatus, { stageStatus: "contracting_ready_to_start" }),
            },
            {
                label: 'In progress',
                value: 'contracting_in_progress',
                valueLabel: _jsx(ApplicationFormattedStageStatus, { stageStatus: "contracting_in_progress" }),
            },
            {
                label: 'Complete',
                value: 'contracting_complete',
                valueLabel: _jsx(ApplicationFormattedStageStatus, { stageStatus: "contracting_complete" }),
            },
        ],
    },
];
