import React, { useEffect } from 'react'
import { ClipLoader } from 'react-spinners'

// CONSTANTS
import { tenantColors } from '../../../_constants/colors'

// Components
import { EsigningDocumentsList } from './EsigningDocumentsList'
import { useModal } from '../../../_reduxHooks/modal.hook'
import { useSigning } from '../../../_reduxHooks/esigning.hook'
import { useTenant } from '../../../_reduxHooks/tenant.hook'
import { H2 } from '../../Typography/Components/Headings/H2'
import { Text } from '../../Typography/Components/Text/Text'
import { Box } from '../../../components/Box/Box'
import { S_EsigningDocument } from './EsigningDocument.styles'

export const EsigningDocument = () => {
  const { setPrevModal } = useModal()
  const { signature_request, loading } = useSigning()
  const { tenant } = useTenant()

  useEffect(() => {
    setPrevModal(EsigningDocumentsList)
  }, [])

  let createdAtTime = signature_request ? Date.parse(signature_request.document_created_at) : new Date().getTime()
  let createdAtDate = new Date()
  createdAtDate.setTime(createdAtTime)

  return (
    <S_EsigningDocument>
      <ClipLoader
        color={tenantColors[tenant].secondary}
        cssOverride={{
          display: `block`,
          margin: `0 auto`,
          animationDuration: `1.5s !important`,
        }}
        loading={loading}
        size={100}
      />
      {signature_request && (
        <Box mb={'15px'} pt={'5px'}>
          <H2 className={'e-sign-title'} mb={'5px'}>
            {signature_request?.document_title || 'Document to sign'}
          </H2>
          <Text className={'e-sign-date'}>{createdAtDate.toLocaleDateString()}</Text>
        </Box>
      )}
      <div id={`esigning-container`}></div>
    </S_EsigningDocument>
  )
}
