import config from 'config'
import { LOCAL_STORAGE_PREFIX } from 'xen/constants'
import { store } from '../_helpers/store'
import { PRODUCT_FLAGS_STR } from './featureFlags/flags.constants'
// eslint-disable-next-line no-unused-vars
import { flipperFeaturesMock } from 'xen/api/use-flipper/mocks'

const ENABLE_FEATURE_FLAG_OVERRIDES = config.enableFeatureFlagOverrides ?? false

/**
 *
 * @param {keyof typeof PRODUCT_FLAGS_STR} flagKey
 * @param {Record<string, boolean>} productFlags
 * @returns
 */
export const isProductEnabled = (flagKey, productFlags = {}) => {
  let flags = productFlags
  if (!flagKey) {
    return false
  }

  const product = PRODUCT_FLAGS_STR[flagKey]

  if (!flags || Object.keys(flags).length === 0) {
    flags = store?.getState()?.productFlags || {}
  }

  flags = overrideFlagsWithLocalStorage(flags)

  const { [product]: returnValue } = flags
  return returnValue || false
}

/**
 *
 * @param {keyof typeof flipperFeaturesMock} flagKey
 * @param {Record<string, boolean>} featureFlags
 * @returns
 */
export const isFeatureEnabled = (flagKey, featureFlags = {}) => {
  let flags = featureFlags

  if (!flagKey || !flags) {
    return false
  }

  if (Object.keys(flags).length === 0) {
    flags = store?.getState()?.featureFlags || {}
  }

  flags = overrideFlagsWithLocalStorage(flags)

  const { [flagKey]: returnValue } = flags
  return returnValue || false
}

function overrideFlagsWithLocalStorage(flags) {
  // If feature flag overrides are disabled, return the flags as-is
  if (!ENABLE_FEATURE_FLAG_OVERRIDES) return flags

  const flagsWithOverrides = { ...flags }

  Object.keys(flagsWithOverrides).forEach((flag) => {
    // If the flag exists in local storage...
    const flagValue = window.localStorage.getItem(`${LOCAL_STORAGE_PREFIX}${flag}`)
    if (flagValue !== null) {
      // ...override the API value with the local storage value
      flagsWithOverrides[flag] = flagValue === 'true'
    }
  })

  return flagsWithOverrides
}
