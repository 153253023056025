import { formHeader } from '../_helpers'
import request from './axios_service'

export const documentsService = {
  uploadInvoiceDocument,
  updateDocument,
  updateInvoice,
  uploadInvoice,
  getDealDocumentsZip,
  getDealSummary,
  uploadBusinessDocument,
  getBusinessDocumentsZip,
}

export const downloadFile = (url, fileName) => {
  // Create an invisible A element
  const a = document.createElement('a')
  a.style.display = 'none'
  document.body.appendChild(a)

  a.href = url

  // Set download Name
  a.setAttribute('download', fileName)

  // Trigger click
  a.click()

  // Cleanup
  document.body.removeChild(a)
}

// post 'documents/upload_operate_invoice_document/:business_id' => 'documents#upload_invoice_document_for_email'
function uploadInvoiceDocument(file, businessId) {
  const data = new FormData()
  data.append(`filename`, file.name)
  data.append(`filedata`, file)

  return request({
    url: `/documents/upload_operate_invoice_document/${businessId}`,
    method: 'POST',
    headers: [formHeader()],
    data,
  })
}

function uploadBusinessDocument(file, businessId) {
  const data = new FormData()
  data.append(`filename`, file.name)
  data.append(`filedata`, file)
  data.append(`document_type`, 'business_documents')
  data.append(`business_id`, businessId)

  return request({
    url: `/documents/upload_business_document/${businessId}`,
    method: 'POST',
    headers: [formHeader()],
    data,
  })
}

function updateDocument(documentId, attr, val) {
  let dataObj = {}
  dataObj.document = {}
  dataObj.document[attr] = val

  return request({
    url: `/documents/${documentId}`,
    method: `PUT`,
    data: dataObj,
  })
}

function updateInvoice(invoiceId, attr, val) {
  let dataObj = {}
  dataObj.invoice = {}
  dataObj.invoice[attr] = val

  return request({
    url: `/invoices/${invoiceId}`,
    method: `PUT`,
    data: dataObj,
  })
}

function uploadInvoice(file, userId, sendEmail, dealId, documentId) {
  const dataObj = new FormData()
  dataObj.append(`filename`, file.name)
  dataObj.append(`filedata`, file)
  dataObj.append(`document_type`, `invoice`)
  dataObj.append(`user_id`, userId)
  dataObj.append(`sendEmail`, sendEmail)
  dataObj.append(`deal_id`, dealId)

  return request({
    url: `/documents/upload/${documentId}`,
    method: `POST`,
    headers: [formHeader()],
    data: dataObj,
  })
}

function getBase64Prefix(atobString) {
  let toReturn = 'data:'
  if (atobString.substring(0, 4) == '%PDF') {
    toReturn += 'application/pdf;base64,'
  } else if (atobString.substring(1, 4) == 'PNG') {
    toReturn += 'image/png;base64,'
  }
  //jpegs are weird, they have a more proper filetype after the first 4 bytes, but it varies wildly, things like JFIF or EXIFII are still jpegs
  else if (atobString.substring(0, 4) == 'ÿØÿá' || atobString.substring(0, 4) == 'ÿØÿà') {
    toReturn += 'image/jpg;base64,'
  } else {
    throw 'Unsupported File Type'
  }
  return toReturn
}

function getDealDocumentsZip(dealId) {
  return request({
    url: `documents/download_all_deal_docs/${dealId}`,
    method: 'GET',
  })
}

function getBusinessDocumentsZip(businessId) {
  return request({
    url: `/documents/download_business_documents?business_id=${businessId}`,
    method: `GET`,
  })
}

function getDealSummary(dealId) {
  return request({
    url: `deals/get_deal_summary/${dealId}`,
    method: 'GET',
  })
}

export const getDocumentLink = (documentId) => {
  return request({
    url: `/documents/download/${documentId}`,
    method: `GET`,
  })
}

export const getBase64Document = (documentId) => {
  let returnedPromise = new Promise((resolve, reject) => {
    ;(async () => {
      let doc = await request({
        url: `/documents/download_doc/${documentId}`,
        method: `GET`,
      })
      try {
        let prefix = getBase64Prefix(atob(doc.base64_file_string))
        resolve(prefix + doc.base64_file_string)
      } catch (e) {
        //need to catch unsupported file types here
        reject(e)
      }
    })()
  })
  return returnedPromise
}

export const downloadDocument = async (documentId, documentName) => {
  return request({
    url: `/documents/download/${documentId}`,
    method: `GET`,
  }).then(
    (resp) => {
      downloadFile(resp.url, documentName)
      return resp.url
    }
    //window.open(resp.url, '_newtab')
    // return request({
    //     url: resp.url,
    //     method: `GET`,
    // })
  )
}

export const getDocument = (documentId) => {
  return request({
    url: `/documents/${documentId}`,
    method: `GET`,
  })
}

export const getDocumentsByCategory = (documentCategory, businessId, searchParams = '') => {
  return request({
    url: `/documents/category/${documentCategory}/?${searchParams}&business_id=${businessId}`,
    method: `GET`,
  })
}

export const getDealDocuments = (businessId) => {
  return request({
    url: `/deal_documents/business/${businessId}`,
    method: `GET`,
  })
}

export const getBusinessDocuments = (businessId) => {
  return request({
    url: `/business_documents/?business_id=${businessId}`,
    method: `GET`,
  })
}

export const getBusinessDocument = (documentId) => {
  return request({
    url: `/business_documents/${documentId}`,
    method: 'GET',
  })
}

export const deleteDocument = (documentId) => {
  return request({
    url: `/documents/${documentId}`,
    method: `DELETE`,
  })
}
