import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import { Group, Heading, Stack } from 'xen-ui';
import { Card, Page, PageTitle } from 'xen/components';
import { PlaidAccounts, PlaidLink } from '../components';
const LinkedExternalAccounts = () => {
    const [selectedAccount, setSelectedAccount] = useState(null);
    return (_jsxs(Page, { "data-testid": "linked-external-accounts", children: [_jsx(PageTitle, { title: "Accounts" }), _jsxs(Card, { children: [_jsxs(Group, { justify: "space-between", px: "lg", py: "md", children: [_jsx(Heading, { icon: "BuildingBank", level: 2, children: "Linked bank accounts" }), _jsx(PlaidLink, {})] }), _jsx(Stack, { p: "lg", children: _jsx(PlaidAccounts, { entityType: "tenant", onAccountSelected: setSelectedAccount, selectedAccount: selectedAccount }) })] })] }));
};
export default LinkedExternalAccounts;
