import { jsx as _jsx } from "react/jsx-runtime";
import { ErrorBoundary as ReactErrorBoundary } from 'react-error-boundary';
import { XenThemeProvider } from 'xen-ui';
import { searchParam } from 'xen/helpers';
import { AppLoader, Error } from '../../components/fallbacks';
export const ErrorBoundary = ({ children, fallbackRender = DefaultFallback }) => {
    return (_jsx(ReactErrorBoundary, { fallbackRender: fallbackRender, onError: handleError, children: children }));
};
// Private --------------------------------------------------------------------
// Handle error scenario where new version of app is deployed in middle of
// existing user session. Lazy-loaded bundles may 404 when new bundle is
// deployed with new file hashes (i.e. new version number). This raises a
// `ChunkLoadError` error, so we reload the app *one* time to get the latest app
// code and bundles. If for some reason the error persists, we hit the boundary
// again and show generic fallback error UI to the user and restart the process.
const isChunkError = (error) => {
    return error.name === 'ChunkLoadError';
};
const handleError = (error) => {
    // If we encounter a chunk error
    if (isChunkError(error)) {
        // If we haven't already tried to reload the app
        if (!searchParam.get('reload')) {
            // Reload the app and set a flag to prevent infinite reload loop
            searchParam.set('reload', '1');
            window.location.reload();
        }
        else {
            // If we've already tried to reload the app, set a flag to show generic
            // error UI to user and reset the error boundary
            window.xenChunkError = true;
        }
    }
};
const DefaultFallback = ({ error, resetErrorBoundary }) => {
    // If we encounter a chunk error and haven't already tried to reload the app
    if (isChunkError(error) && !searchParam.get('reload') && !window.xenChunkError) {
        // Show the app loader while we reload the app
        return (_jsx(XenThemeProvider, { children: _jsx(AppLoader, {}) }));
    }
    if (window.xenChunkError) {
        delete window.xenChunkError;
        resetErrorBoundary();
    }
    return (_jsx(XenThemeProvider, { children: _jsx(Error, { message: error.message, title: "Unexpected application error" }) }));
};
