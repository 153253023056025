import { jsx as _jsx } from "react/jsx-runtime";
import { Badge } from 'xen-ui';
import { useGetConfiguration } from 'xen/api/use-configuration';
export const TenantBadge = ({ tenant, ...props }) => {
    const { data: config } = useGetConfiguration(tenant.token);
    if (!config)
        return null;
    // Color the badge based on its tenant's primary color
    const { primary } = config.tenant.branding.colors;
    return (_jsx(Badge, { autoContrast: true, color: primary, "data-testid": "tenant-badge", ...props, children: tenant.organizationName }));
};
