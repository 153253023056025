import React, { useState, useReducer, useEffect, forwardRef, useRef, useImperativeHandle } from 'react'
import { connect } from 'react-redux'
import { IconContext } from 'react-icons'
import { FaExclamationTriangle } from 'react-icons/fa'
import throttle from 'lodash/throttle'
import { Chip } from './Chip'
import { BeatLoader } from 'react-spinners'

import { S_MultipleAutoCompleteInput } from './MultipleAutoCompleteInput.styles'

// Actions
import { modalActions } from '../../_actions'

const useKeyPress = function (targetKey) {
  const [keyPressed, setKeyPressed] = useState(false)

  function downHandler({ key }) {
    if (key === targetKey) {
      event.preventDefault()
      setKeyPressed(true)
    }
  }

  const upHandler = ({ key }) => {
    if (key === targetKey) {
      setKeyPressed(false)
    }
  }

  React.useEffect(() => {
    window.addEventListener('keydown', downHandler)
    window.addEventListener('keyup', upHandler)
    return () => {
      window.removeEventListener('keydown', downHandler)
      window.removeEventListener('keyup', upHandler)
    }
  })

  return keyPressed
}

const throttleCallback = throttle((val, callback) => {
  callback(val)
}, 500)
/**
 * Accepts fetchSuggestions (async function), valueIdAttr (attr of selected option to send to onChange handler), valueLabelAttr (pretty value to display when option is selected)
 */
const MultipleAutoCompleteInput = (props, ref) => {
  const [suggestions, setSuggestions] = useState([])
  const [selectedText, setSelectedText] = useState('')
  const [selectedIdVal, setSelectedIdVal] = useState('')

  const selectedReducer = (state, action) => {
    switch (action.type) {
      case 'delete': {
        let updatedDeleteState = { items: state.items.filter((item) => item.id !== action.item.id) }
        props.onBlur({
          target: {
            value: updatedDeleteState,
          },
        })
        return updatedDeleteState
      }
      case 'add': {
        let added = state.items.some((item) => item.id === action.item.id)

        if (added) {
          return state
        }

        let updatedState = { ...state, items: [...state.items, action.item] }

        props.onBlur({
          target: {
            value: updatedState,
          },
        })

        return updatedState
      }
    }
  }

  const [selectedItems, setSelectedItems] = useReducer(selectedReducer, {
    items: props.value ? [...props.value] : [],
  })

  const { showWarning = true } = props
  const [warning] = useState('')
  const [openSuggestionBox, setOpenSuggestionBox] = useState(false)
  const inputRef = useRef(null)

  const downPress = useKeyPress('ArrowDown')
  const upPress = useKeyPress('ArrowUp')
  const enterPress = useKeyPress('Enter')
  const [cursor, setCursor] = useState(0)
  const [hovered, setHovered] = useState(undefined)
  const [focused, setFocused] = useState(undefined)
  const [componentLoading, setIsLoading] = useState(false)
  const isLoading = props.isLoading !== undefined ? props.isLoading : componentLoading

  const getSuggestions = (e) => {
    let search = `${e.target.value}`
    setSelectedText(e.target.value)
    setOpenSuggestionBox(true)

    throttleCallback(search, async (search) => {
      setIsLoading(true)
      let suggestions = await props.fetchSuggestions(search)
      setIsLoading(false)
      setSuggestions(suggestions)
    })
  }

  const setSelected = (selectedId, selectedText) => {
    setSelectedItems({ type: 'add', item: { id: selectedId, text: selectedText } })
    setSelectedText('')
    setSelectedIdVal('')
    setSuggestions([])
  }

  useEffect(() => {
    if (suggestions.length && downPress) {
      setCursor((prevState) => (prevState < suggestions.length - 1 ? prevState + 1 : prevState))
    }
  }, [downPress])

  useEffect(() => {
    if (suggestions.length && upPress) {
      setCursor((prevState) => (prevState > 0 ? prevState - 1 : prevState))
    }
  }, [upPress])

  useEffect(() => {
    if (suggestions.length && enterPress) {
      setSelected(suggestions[cursor][props.valueIdAttr], suggestions[cursor][props.valueLabelAttr])
      setOpenSuggestionBox(false)
    }
  }, [cursor, enterPress])

  useEffect(() => {
    if (suggestions.length && hovered) {
      setCursor(suggestions.indexOf(hovered))
    }
  }, [hovered])

  useImperativeHandle(ref, () => ({
    focus: () => {
      inputRef.current.focus()
    },
    focusout: () => {
      inputRef.current.focusout()
    },
    //making this act more like a proper html input element
    tagName: 'INPUT',
    value: selectedIdVal,
  }))

  const renderInputComponent = () => {
    if (props.inputComponent) {
      // Note that instead of ref we use inputRef here as it is assumed
      // that the custom inputComponent will be a function component
      return (
        <props.inputComponent
          autoComplete="off"
          className={props.className}
          disabled={props.disabled}
          inputRef={inputRef}
          onChange={getSuggestions}
          onFocus={(e) => {
            setFocused(true)
            setSelectedIdVal(null)
            setSelectedText('')
            setOpenSuggestionBox(true)
          }}
          placeholder={props.placeholder || ''}
          type={'text'}
          value={!!selectedIdVal && !!selectedText ? '' : selectedText}
        ></props.inputComponent>
      )
    } else {
      return (
        <input
          autoComplete="off"
          className={props.className}
          disabled={props.disabled}
          onChange={getSuggestions}
          onFocus={(e) => {
            setFocused(true)
            setSelectedIdVal(null)
            setSelectedText('')
            setOpenSuggestionBox(true)
          }}
          placeholder={props.placeholder || ''}
          ref={inputRef}
          type={'text'}
          value={selectedText}
        />
      )
    }
  }

  return (
    <S_MultipleAutoCompleteInput
      onBlur={(e) => {
        if (!e.currentTarget.contains(e.relatedTarget)) {
          setSuggestions([])
          setOpenSuggestionBox(false)
          setFocused(false)
          if (suggestions.length !== 0) {
            setSelected(suggestions[0][props.valueIdAttr], suggestions[0][props.valueLabelAttr])
          }
        }
      }}
      onClickCapture={(e) => {
        if (props.disabled) return
        setOpenSuggestionBox(true)
        inputRef.current.focus()
      }}
    >
      <div className={`multiple-auto-complete ${focused ? 'focused' : ''}`}>
        {selectedItems.items &&
          selectedItems.items.map((item) => (
            <Chip
              className="chip-position"
              key={item.id}
              onDelete={() => {
                setSelectedIdVal(null)
                setSelectedText('')
                setSelectedItems({ type: 'delete', item: { id: item.id } })
                if (inputRef.current) {
                  inputRef.current.focus()
                }
              }}
              text={item.text || item.name}
            />
          ))}

        {renderInputComponent()}
      </div>

      <div className={`suggestion-box-wrapper`}>
        <div
          className={openSuggestionBox && suggestions.length === 0 && selectedText === '' ? 'suggestion-box' : 'close'}
        >
          <div className={'default-suggestion'} tabIndex={-1}>
            {props.defaultSuggestion}
          </div>
        </div>
        {isLoading && selectedText !== '' ? (
          <div className={`suggestion-box loading open`}>
            <BeatLoader size={10} />
          </div>
        ) : (
          <>
            <div
              className={
                openSuggestionBox && (suggestions.length !== 0 || props.addButton) && selectedText !== ''
                  ? `suggestion-box open`
                  : `suggestion-box close`
              }
            >
              {suggestions.map((item, i) => {
                const added = selectedItems.items.some((selectedItem) => selectedItem.id === item.id)
                return (
                  <div
                    className={`suggestion item ${i === cursor ? 'active' : ''} ${added ? 'added' : ''}`}
                    key={item.id}
                    onClick={() => {
                      if (added) {
                        return
                      }
                      setSelected(item[props.valueIdAttr], item[props.valueLabelAttr])
                      setOpenSuggestionBox(false)
                    }}
                    onMouseEnter={() => {
                      setHovered(item)
                    }}
                    onMouseLeave={() => setHovered(undefined)}
                    tabIndex={-1}
                  >
                    {item[props.valueLabelAttr]}
                  </div>
                )
              })}
              {props.addButton && selectedText !== '' ? (
                <div className={openSuggestionBox ? 'add-button' : 'close'}>
                  <div
                    className={`create`}
                    onClick={(e) => {
                      //Preventing default so state can update before selecting a value
                      e.preventDefault()
                      setSuggestions([])
                      setSelectedIdVal(null)
                      setSelectedText('')
                      setOpenSuggestionBox(false)
                      props.openModal(props.buttonModal, { setSelected })
                    }}
                    tabIndex={-1}
                  >
                    {props.modalText}
                  </div>
                </div>
              ) : null}
            </div>
          </>
        )}
      </div>
      {warning && showWarning && (
        <IconContext.Provider value={{ color: '#ffae42' }}>
          <FaExclamationTriangle className={`warning-icon`} title={warning} />
        </IconContext.Provider>
      )}
    </S_MultipleAutoCompleteInput>
  )
}

const mapDispatchToProps = (dispatch) => {
  return {
    openModal: (content, props) => {
      dispatch(modalActions.openModal(content, props))
    },
  }
}

const ACI = forwardRef(MultipleAutoCompleteInput)

const connectedAutoCompleteInput = connect(null, mapDispatchToProps, null, { forwardRef: true })(ACI)

export { connectedAutoCompleteInput as MultipleAutoCompleteInput }
