import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { forwardRef, useState } from 'react';
import { createLabelText, selectScrollAreaProps } from '../_internal';
import { CheckIcon, Combobox, isComboboxDataGroups, isComboboxDataItems, useCombobox } from '../combobox';
import { Group } from '../group';
import { Input } from '../input';
import { InputBase } from '../input-base';
import { ScrollArea } from '../scroll-area';
/**
 * Custom implementation of a `<Select />` component that allows for
 * custom-rendered values, i.e. the values shown in the input pills.
 * Based on https://github.com/mantinedev/mantine/blob/master/packages/%40mantine/core/src/components/Select/Select.tsx
 * and this example https://mantine.dev/combobox/?e=SelectGroups.
 * It doesn't have all the same functionality as `<Select />`, so if you don't need
 * custom rendering use that component instead.
 */
export const SelectValueRenderer = forwardRef(({ data, disabled, error, hideLabel = false, inputWrapperOrder, label, onChange, onOptionSubmit, placeholder, size, styles, type, value: initialValue, w, }, ref) => {
    const combobox = useCombobox({
        onDropdownClose: () => combobox.resetSelectedOption(),
    });
    const [value, setValue] = useState(initialValue || null);
    return (_jsxs(Combobox, { onOptionSubmit: (val) => {
            // onChange required for react-hook-form
            const option = getSelectedItem({ data, value: val });
            option && onChange?.(val, option);
            onOptionSubmit?.(val);
            setValue(val);
            combobox.closeDropdown();
        }, size: size, store: combobox, children: [_jsx(Combobox.Target, { children: _jsx(InputBase, { ...createLabelText(label, hideLabel, type), component: "button", disabled: disabled, error: error, inputWrapperOrder: inputWrapperOrder, onClick: () => combobox.toggleDropdown(), pointer: true, ref: ref, rightSection: _jsx(Combobox.Chevron, {}), 
                    // Allow the chevron to be toggle the dropdown
                    rightSectionPointerEvents: "none", size: size, styles: {
                        ...styles,
                        input: {
                            // Don't include padding for chevron
                            paddingInlineEnd: 'var(--input-padding)',
                            ...(typeof styles === 'object' && styles?.input ? styles.input : {}),
                        },
                    }, type: "button", w: w, children: value ? (getSelectedItem({ data, value })?.valueLabel) : (_jsx(Input.Placeholder, { children: placeholder })) }) }), _jsx(Combobox.Dropdown, { children: _jsx(Combobox.Options, { children: _jsx(ScrollArea.Autosize, { ...selectScrollAreaProps, children: _jsx(Options, { data: data, size: size, value: value }) }) }) })] }));
});
SelectValueRenderer.displayName = 'SelectValueRenderer';
const ItemOption = ({ item, size, value }) => {
    return (_jsx(Combobox.Option, { value: item.value, children: _jsxs(Group, { gap: `calc(0.5 * var(--mantine-spacing-${size}))`, children: [value === item.value && _jsx(CheckIcon, { opacity: "0.4", size: "0.8em" }), _jsx("span", { children: item.label })] }) }, item.value));
};
const Options = ({ data, ...props }) => {
    if (isComboboxDataGroups(data)) {
        return data.map((option) => {
            return (_jsx(Combobox.Group, { label: option.group, children: option.items.map((item) => {
                    return _jsx(ItemOption, { item: item, ...props }, item.value);
                }) }, option.group));
        });
    }
    if (isComboboxDataItems(data)) {
        return data.map((option) => {
            return _jsx(ItemOption, { item: option, ...props }, option.value);
        });
    }
};
const getSelectedItem = ({ data, value }) => {
    let selectedItem = null;
    if (isComboboxDataGroups(data)) {
        const foundGroup = data.find((group) => group.items.some((item) => item.value === value));
        selectedItem = foundGroup?.items.find((item) => item.value === value);
    }
    else if (isComboboxDataItems(data)) {
        selectedItem = data.find((item) => item.value === value);
    }
    if (!selectedItem)
        return null;
    return selectedItem;
};
