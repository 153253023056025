import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { createContext, useContext } from 'react';
import { ActionIcon, Collapse, Group, Heading, Stack, Tooltip, useDisclosure, Card as XenCard, XenIcon } from 'xen-ui';
// Context for Card state
const CardContext = createContext(null);
// Custom hook to use the Card context
const useCardContext = () => {
    const context = useContext(CardContext);
    if (!context) {
        throw new Error('useCardContext must be used within a CardProvider');
    }
    return context;
};
export const Card = ({ collapseId, collapseLabel, ...props }) => {
    // Store collapsed state in localStorage using unique key
    const STORAGE_KEY = `xen-collapse:${collapseId}`;
    const isCollapsed = localStorage.getItem(STORAGE_KEY) === 'true';
    // If a collapseId is provided, default to open when stored state is not collapsed
    let defaultOpenState = true;
    if (collapseId) {
        defaultOpenState = !isCollapsed;
    }
    const [isOpen, { toggle: collapse }] = useDisclosure(defaultOpenState, {
        onOpen: () => localStorage.removeItem(STORAGE_KEY), // NOT collapsed
        onClose: () => localStorage.setItem(STORAGE_KEY, 'true'), // collapsed
    });
    return (_jsx(CardContext.Provider, { value: { isOpen, collapse, collapseId, collapseLabel }, children: _jsx(XenCard, { p: "0", radius: "md", shadow: "sm", w: "100%", withBorder: true, ...props }) }));
};
const CardHeader = (props) => {
    const { isOpen, collapse, collapseId, collapseLabel } = useCardContext();
    if (!collapseId) {
        return _jsx(Group, { justify: "space-between", px: "lg", py: "md", ...props });
    }
    const label = isOpen ? `Hide ${collapseLabel}` : `Show ${collapseLabel}`;
    return (_jsxs(Group, { px: "lg", py: "md", children: [_jsx(Group, { justify: "space-between", ...props, flex: 1 }), _jsx(Tooltip, { label: label, children: _jsx(ActionIcon, { "aria-label": label, onClick: collapse, variant: "transparent", children: isOpen ? _jsx(XenIcon.ChevronDown, {}) : _jsx(XenIcon.ChevronRight, {}) }) })] }));
};
const CardHeading = (props) => {
    return _jsx(Heading, { level: 2, styleVariant: 3, ...props });
};
const CardBody = (props) => {
    const { isOpen } = useCardContext();
    return (_jsx(Collapse, { in: isOpen, children: _jsx(Stack, { gap: "xl", p: "lg", ...props }) }));
};
const CardGroup = (props) => {
    return _jsx(Group, { ...props });
};
Card.Header = CardHeader;
Card.Heading = CardHeading;
Card.Group = CardGroup;
Card.Body = CardBody;
