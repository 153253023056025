import { businessOfficersConstants } from '../_constants'

export function businessOfficers(state = {}, action) {
  switch (action.type) {
    case businessOfficersConstants.GET_BUSINESS_OFFICERS_REQUEST:
      return {
        ...state,
        action: {
          loading: true,
          business_id: action.business_id,
        },
      }
    case businessOfficersConstants.GET_BUSINESS_OFFICERS_SUCCESS:
      return {
        data: action.businessOfficers,
      }
    case businessOfficersConstants.GET_BUSINESS_OFFICERS_FAILURE:
      return {
        ...state,
        action: {
          error: action.error,
        },
      }
    case businessOfficersConstants.UPDATE_BUSINESS_OFFICER_REQUEST:
      return {
        ...state,
        action: {
          loading: true,
          attr: action.attr,
          val: action.val,
          business_id: action.businessId,
          ordinal: action.businessOfficerOrdinal,
        },
      }
    case businessOfficersConstants.UPDATE_BUSINESS_OFFICER_SUCCESS:
      return {
        data: state.data.map(
          (businessOfficer) =>
            [action.businessOfficer].find(({ ordinal }) => ordinal === businessOfficer.ordinal) || businessOfficer
        ),
      }
    case businessOfficersConstants.UPDATE_BUSINESS_OFFICER_FAILURE:
      return {
        ...state,
        action: {
          error: action.error,
        },
      }

    case businessOfficersConstants.CREATE_BUSINESS_OFFICER_REQUEST:
      return {
        ...state,
        action: {
          loading: true,
          business_id: action.businessId,
        },
      }
    case businessOfficersConstants.CREATE_BUSINESS_OFFICER_SUCCESS: {
      const newData = state.data
      newData.push(action.businessOfficer)
      return {
        data: newData,
      }
    }
    case businessOfficersConstants.CREATE_BUSINESS_OFFICER_FAILURE:
      return {
        ...state,
        action: {
          error: action.error,
        },
      }
    case businessOfficersConstants.DELETE_BUSINESS_OFFICER_REQUEST:
      return {
        ...state,
        action: {
          loading: true,
          businessOfficerId: action.businessOfficerId,
        },
      }
    case businessOfficersConstants.DELETE_BUSINESS_OFFICER_SUCCESS: {
      const oldData = state.data
      return {
        data: oldData.filter((bo) => {
          return bo.id != action.businessOfficerId
        }),
      }
    }
    case businessOfficersConstants.DELETE_BUSINESS_OFFICER_FAILURE:
      return {
        ...state,
        action: {
          error: action.error,
        },
      }
    default:
      return state
  }
}
