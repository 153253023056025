import { jsx as _jsx } from "react/jsx-runtime";
import { createContext, useContext } from 'react';
import { MantineProvider } from '@mantine/core';
import { useGetFlipperFeatures } from 'xen/api/use-flipper';
import { AppLoader, Error } from 'xen/components';
const defaultFeatures = {
    accounting_integration: false,
    auto_change_prospect_to_client: false,
    auto_funding_request: false,
    backdating_enabled: false,
    banking_integration: false,
    'broker-commission': false,
    business_officer_unique_email: false,
    demoware: false,
    change_email_receivers: false,
    chargeback_backdate: false,
    client_activity_page: false,
    client_concentration: false,
    client_debtors_module: false,
    client_reserves_page: false,
    client_summary_report: false,
    client_vendors_module: false,
    documents_page: false,
    esigning_pill: false,
    fr_posting_date: false,
    fund_checkbox: false,
    funding_for_borrower: false,
    funding_request_fee_from_cash: false,
    funding_requests: false,
    funnel_management_page: false,
    generated_reports: false,
    hide_due_diligence: false,
    invoice_settlement_expected_total: false,
    intercom: false,
    'monthly-funding-amount-requested': false,
    new_client_button_on_client_page: false,
    new_configurations_and_settings_ui: false,
    new_invoice_redesign: false,
    new_underwriting_sign_up_flow: false,
    new_underwriting: false,
    ocrolus: false,
    print_application_disclaimer: false,
    print_underwrite_checklist: false,
    print_underwriting_checklist: false,
    product_abl: false,
    product_esigning: false,
    product_factoring: false,
    product_operate: false,
    product_risk_and_fraud: false,
    product_underwrite: false,
    prospect_button: false,
    'prospect-quotes': false,
    require_underwriting_approval: false,
    require_underwriting_checklist: false,
    return_checks: false,
    risk_and_fraud: false,
    risk_and_fraud_demo_data: false,
    risk_and_fraud_info_rerun: false,
    sales_email_bcc_new_leads: false,
    salesforce_integration: false,
    schedule_page_new: false,
    send_emails: false,
    show_all_funds_employed_reports: false,
    staged_application: false,
    stop_invoice_fees: false,
    tenant_enrollment: false,
    transactions_v2: false,
    underwrite_activities: false,
    underwrite_audit: false,
    unprocess_payment_batch: false,
    unprocess_schedule: false,
    'unprocess-fr': false,
    upload_invoices_enabled: false,
    use_non_standard_nfe: false,
    'user-notification-settings': false,
    'user-notifications': false,
    xen_auth_pages: false,
    xen_invoice_schedule: false,
    xen_premium: false,
};
export const FeaturesContext = createContext(defaultFeatures);
export const FeaturesProvider = ({ children }) => {
    const { data: features = defaultFeatures, isLoading, isError } = useGetFlipperFeatures();
    if (isLoading)
        return (_jsx(MantineProvider, { children: _jsx(AppLoader, {}) }));
    if (isError)
        return (_jsx(MantineProvider, { children: _jsx(Error, { message: "Could not fetch features" }) }));
    return _jsx(FeaturesContext.Provider, { value: features, children: children });
};
export const useFeaturesContext = () => {
    const context = useContext(FeaturesContext);
    return context;
};
