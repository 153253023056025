import React from 'react'
import {
  MdAdd,
  MdCheck,
  MdExpandMore,
  MdExpandLess,
  MdChevronLeft,
  MdChevronRight,
  MdFileDownload,
  MdZoomIn,
  MdZoomOut,
} from 'react-icons/md'
import { FaFileExcel, FaRegSave } from 'react-icons/fa'
import { ClipLoader } from 'react-spinners'
import { Link } from 'react-router-dom'

import colors from '../../_constants/colors'

// Components
import { S_ControlsButton } from './ControlsButton.styles'
import MdClose from '../../assets/svg/Exit_X.svg'
import MdDelete from '../../assets/svg/Trash.svg'
import MdModeEdit from '../../assets/svg/Pencil.svg'
import Pin from '../../assets/svg/Grey_Pin.svg'

const iconMapping = {
  'save-alt': { normal: <FaRegSave /> },
  add: { normal: <MdAdd /> },
  close: { normal: <MdClose className="svg" /> },
  default: { normal: <MdModeEdit className="svg" /> },
  delete: { normal: <MdDelete className="svg" /> },
  download: { normal: <MdFileDownload /> },
  edit: { normal: <MdModeEdit className="svg" /> },
  left: { normal: <MdChevronLeft /> },
  less: { normal: <MdExpandLess /> },
  more: { normal: <MdExpandMore /> },
  pin: {
    active: <Pin className="svg" fill={colors.ICON_BLUE} />,
    normal: <Pin className="svg" fill={colors.ICON_GREY} />,
  },
  right: { normal: <MdChevronRight /> },
  save: { normal: <MdCheck /> },
  spreadsheet: { normal: <FaFileExcel /> },
  zoom_in: { normal: <MdZoomIn /> },
  zoom_out: { normal: <MdZoomOut /> },
}

const getIcon = (icon = 'default', variant = 'normal') => {
  let iconObject = iconMapping[icon] ?? iconMapping['default']
  return iconObject[variant]
}

export const ControlsButton = ({
  icon = 'edit',
  variant = 'normal',
  handleClick = () => {},
  isLoading = false,
  className = '',
  isDisabled = false,
  href = null,
  ...rest
}) => {
  // Decide internal / external link props
  const hasLink = href !== null
  let linkProps = {}
  if (hasLink) {
    const isExternal = /^https?:\/\//.test(href)
    if (isExternal) {
      linkProps = { href: href, as: 'a' }
    } else {
      linkProps = { to: href, as: Link }
    }
  }
  return (
    <S_ControlsButton
      className={className}
      disabled={isLoading || isDisabled}
      onClick={handleClick}
      type="button"
      {...rest}
      {...linkProps}
    >
      {!isLoading ? (
        getIcon(icon, variant)
      ) : (
        <ClipLoader
          color={'#000'}
          cssOverride={{
            display: `block`,
            margin: `0 auto`,
            animationDuration: `1.5s !important`,
          }}
          size={15}
        />
      )}
    </S_ControlsButton>
  )
}
