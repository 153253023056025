import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useController } from 'react-hook-form';
import { Text, Checkbox as XenCheckbox } from 'xen-ui';
import { ErrorText } from '../../error-text';
export function FormCheckbox({ name, control, defaultValue, error, hideLabel, label, required, rules, shouldUnregister, onChange, ...props }) {
    const { field: { value, onChange: fieldOnChange, ...field }, fieldState, } = useController({
        name,
        control,
        defaultValue,
        rules,
        shouldUnregister,
    });
    const fieldError = error ?? fieldState.error?.message;
    const labelWithAsterisk = (_jsxs(_Fragment, { children: [label, ' ', _jsx(Text, { c: "red", span: true, children: "*" })] }));
    return (_jsx(XenCheckbox, { checked: value, error: fieldError ? _jsx(ErrorText, { component: "span", message: fieldError }) : undefined, label: required && !hideLabel ? labelWithAsterisk : label, onChange: (e) => {
            fieldOnChange(e);
            onChange?.(e);
        }, styles: { body: { alignItems: 'center' }, label: { fontWeight: 'bold' }, ...props.styles }, value: value, ...field, ...props }));
}
