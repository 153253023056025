import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Heading, Stack, BasicTable as Table, Text } from 'xen-ui';
/**
 * Horizontal table with data labels in the table header
 */
const DataTable = ({ ...props }) => {
    return _jsx(Table, { verticalSpacing: "6px", withColumnBorders: true, withTableBorder: true, ...props });
};
/**
 * Vertical table with data labels in the first column of each row.
 */
export const VerticalDataTable = ({ data, ...props }) => {
    const { body, columns } = data;
    return (_jsx(DataTable, { variant: "vertical", ...props, children: _jsx(Table.Tbody, { children: body.map((cells, index) => {
                return (_jsxs(Table.Tr, { children: [_jsx(Table.Th, { style: { minWidth: 'fit-content', width: 0, whiteSpace: 'nowrap' }, children: columns[index] }), cells.map((cell, cellIndex) => {
                            return _jsx(Table.Td, { children: cell }, cellIndex);
                        })] }, index));
            }) }) }));
};
/**
 * Section wrapper for DataTable with heading and empty state
 */
const DataTableSection = ({ children, data, name }) => {
    return (_jsxs(Stack, { gap: "sm", children: [_jsx(Heading, { level: 4, children: name }), data?.length === 0 ? _jsx(Text, { children: "No data found" }) : children] }));
};
DataTable.Vertical = VerticalDataTable;
DataTable.Section = DataTableSection;
export { DataTable };
