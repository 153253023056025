import React, { useState } from 'react'
import { createSearchParams } from '../../../_helpers/search'
import { S_FilterInput } from './FilterField.styles'
import { AutoCompleteInput } from '../../AutoCompleteInput'
import { FormInput, SelectInput } from '../../Forms/FormInput'
import { UTCDateToSystemString } from '../../../_helpers'
import { S_FilterField, S_FilterFieldHead, S_FilterFieldBody } from './FilterField.styles'
import { Text } from '../../Typography/Components/Text/Text'
import { FormField } from '../../Forms/FormField'

export const FilterField = ({ field, show, pendingFilters, setPendingFilters, onLabelClick }) => {
  const [isLoading, setIsLoading] = useState(false)

  const handleFilterValueChange = (e, column) => {
    let indexToModify = pendingFilters.findIndex((val) => val.column.fieldName === column.fieldName)
    let filterCopy = [...pendingFilters]
    let newVal = e?.target?.value
    let deleteFilter = indexToModify > -1 && !(newVal || typeof newVal === 'number')
    if (column.type === 'boolean' || column.type === 'customTruthy') {
      newVal = e.target.checked ? 1 : 0
      deleteFilter = !newVal
    }
    if (!deleteFilter && indexToModify > -1 && (newVal || typeof newVal === 'number')) {
      filterCopy[indexToModify].value = newVal
      if (filterCopy[indexToModify].formattedValue) {
        delete filterCopy[indexToModify].formattedValue
      }
    } else if (deleteFilter) {
      filterCopy.splice(indexToModify, 1)
    } else if (newVal || typeof newVal === 'number') {
      filterCopy.push({
        column,
        value: newVal,
        formattedValue: column.type === 'autoComplete' ? e.target.text : false,
      })
    }
    setPendingFilters(filterCopy)
  }

  const renderFilterInput = (currentColumn, withDefaultVal) => {
    let fetchSuggestions, results
    const autoCompleteSettings = currentColumn.autoComplete
    let defaultVal = withDefaultVal ? withDefaultVal.value : null

    // All of these values should be defined in the columns in order
    // to keep this component flexible
    if (autoCompleteSettings) {
      fetchSuggestions = async (searchText) => {
        let searchParams = createSearchParams({
          requestMethod: autoCompleteSettings.requestMethod,
          searchTerm: searchText,
          searchParam: autoCompleteSettings.searchParam ?? currentColumn.fieldName,
        })

        setIsLoading(true)

        if (autoCompleteSettings.requestMethod === 'GET') {
          searchParams.set('type', 'tenant')
          results = await autoCompleteSettings.service(searchParams.toString())
        } else {
          let extra = autoCompleteSettings.extra
            ? autoCompleteSettings.extra
            : { constraints: { is_client: true, risk_status: 'active' } }

          searchParams = {
            ...searchParams,
            ...extra,
          }
          results = await autoCompleteSettings.service(searchParams)
        }

        setIsLoading(false)

        if (results.length !== undefined) {
          return results
        } else {
          return results.results
        }
      }
    }

    let addlProps = autoCompleteSettings ? autoCompleteSettings : {}

    switch (currentColumn.type) {
      case 'autoComplete':
        return (
          <S_FilterInput data-testid="filter-field">
            <AutoCompleteInput
              defaultSuggestion={currentColumn.autoComplete.suggestion}
              fetchSuggestions={fetchSuggestions}
              inputComponent={FormInput}
              isLoading={isLoading}
              onChange={(e) => handleFilterValueChange(e, currentColumn)}
              onInputValueChange={() => {}}
              value={defaultVal}
              valueIdAttr={currentColumn.autoComplete.responseAttr}
              valueLabelAttr={currentColumn.autoComplete.searchParam}
              {...addlProps}
            />
          </S_FilterInput>
        )
      case 'boolean':
        return (
          <S_FilterInput data-testid="filter-field">
            <>
              <>
                <FormField
                  checkboxLabel={currentColumn.displayName}
                  checked={defaultVal}
                  onChange={(value, id, e) => handleFilterValueChange(e, currentColumn)}
                  type={'checkbox'}
                />
              </>
            </>
          </S_FilterInput>
        )
      case 'customTruthy':
        return (
          <S_FilterInput data-testid="filter-field">
            <>
              <FormField
                checkboxLabel={currentColumn.displayName}
                checked={defaultVal}
                onChange={(value, id, e) => handleFilterValueChange(e, currentColumn)}
                type={'checkbox'}
              />
            </>
          </S_FilterInput>
        )
      case 'date':
        return (
          <S_FilterInput data-testid="filter-field">
            <>
              <FormInput
                defaultValue={defaultVal ? UTCDateToSystemString(defaultVal).split('T')[0] : defaultVal}
                max={currentColumn.max}
                onChange={(e) => handleFilterValueChange(e, currentColumn)}
                type={'date'}
              />
            </>
          </S_FilterInput>
        )
      case 'select':
        return (
          <S_FilterInput data-testid="filter-field">
            <FormField
              className={'selected-accent'}
              defaultValue={defaultVal || ''}
              id={currentColumn.fieldName}
              name={currentColumn.fieldName}
              onChange={(value, id, e) => handleFilterValueChange(e, currentColumn)}
              options={currentColumn.options.map((option) => {
                return {
                  label: option.display,
                  value: option.value,
                  name: currentColumn.fieldName,
                }
              })}
              type={'radio'}
            />
          </S_FilterInput>
        )
      case 'select-dropdown': {
        let opts = currentColumn.options
        let placeholder = currentColumn?.placeholder || 'Select'
        if (opts?.length > 0 && opts[0].value) {
          opts = [...currentColumn.options]
        }
        return (
          <S_FilterInput data-testid="filter-field">
            <SelectInput
              defaultValue={defaultVal || ''}
              onChange={(e) => handleFilterValueChange(e, currentColumn)}
              options={opts}
              placeholder={placeholder}
              showDefault={false}
              type={'select'}
            />
          </S_FilterInput>
        )
      }
      default:
        return (
          <S_FilterInput data-testid="filter-field">
            <>
              <FormInput defaultValue={defaultVal} onChange={(e) => handleFilterValueChange(e, currentColumn)} />
            </>
          </S_FilterInput>
        )
    }
  }

  return (
    <S_FilterField data-testid="filter-wrapper">
      <S_FilterFieldHead className={`${show ? 'opened' : ''}`} data-testid="filter-head" onClick={onLabelClick}>
        <Text data-testid="label-text" fs={'16px'}>
          {field.displayName}
        </Text>
      </S_FilterFieldHead>
      <S_FilterFieldBody className={`${show ? 'opened' : ''}`} data-testid="filter-body">
        {renderFilterInput(
          field,
          pendingFilters.find((v) => v.column.fieldName === field.fieldName)
        )}
      </S_FilterFieldBody>
    </S_FilterField>
  )
}
