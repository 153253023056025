/** Returns date string formatted like '1/31/24' */
const formatDateAsLocaleDateString = (date) => {
    return new Date(date).toLocaleString('en-US', { dateStyle: 'short' });
};
/** Returns date string formatted like '1/31/24, 5:19 PM' */
const formatDateAsLocaleDateTimeString = (date) => {
    return new Date(date).toLocaleString('en-US', { dateStyle: 'short', timeStyle: 'short' });
};
/** Returns date string formatted like '5:19 PM' */
const formatDateAsLocaleTimeString = (date) => {
    return new Date(date).toLocaleString('en-US', { timeStyle: 'short' });
};
/** Returns date string formatted like '2024-01-31' */
const formatDateAsISODateString = (date) => {
    return new Date(date).toISOString().split('T')[0] ?? '';
};
export const formatDate = {
    localeDateString: formatDateAsLocaleDateString,
    localeDateTimeString: formatDateAsLocaleDateTimeString,
    localeTimeString: formatDateAsLocaleTimeString,
    isoDateString: formatDateAsISODateString,
};
