import { jsx as _jsx } from "react/jsx-runtime";
import { forwardRef } from 'react';
import { Link } from 'react-router-dom';
import { Anchor } from 'xen-ui';
import { createInternalLinkUrl } from 'xen/helpers';
import { isEmailLink, isExternalLink } from 'xen/types';
/**
 * A wrapper around the `Anchor` component that handles internal and external
 * text links. If the `to` prop is an external link (https://...), the component
 * will render an anchor tag that looks like a text link and  opens in a new
 * window. If the `to` prop is an internal link, the component will render a
 * `Link` component from `react-router-dom` and only allow valid internal link
 * paths based on the `XenPath` type and require the correct number of dynamic
 * URL params (0-3).
 *
 * NOTE: `createInternalLinkUrl` only supports a maximum of 3 dynamic URL
 * params. If you need more, update the `LinkProps` type in `xen/types/link.ts`
 * or manually construct your own link component and linkUrl.
 */
export const TextLink = forwardRef(({ children, fz = 'inherit', keepSearchParams, searchParams = {}, to, underline = 'always', urlParams, ...props }, ref) => {
    const sharedProps = { fz, ref, underline };
    if (isExternalLink(to) || isEmailLink(to)) {
        return (_jsx(Anchor, { ...props, ...sharedProps, component: "a", href: to, rel: "noopener noreferrer", target: "_blank", children: children }));
    }
    // @ts-expect-error TODO: fix type errors
    const linkUrl = createInternalLinkUrl({ keepSearchParams, to, searchParams, urlParams });
    return (_jsx(Anchor, { ...props, ...sharedProps, component: Link, to: linkUrl, children: children }));
});
TextLink.displayName = 'TextLink';
