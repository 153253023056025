import React, { Fragment, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Helmet } from 'react-helmet'
import startCase from 'lodash/startCase'
import { FaSpinner } from 'react-icons/fa'
import { Formik, Form } from 'formik'
import { merge } from 'lodash'
import { plural } from 'pluralize'

import Field from '../_components/FieldNew'
import { Button } from '../_components/Button'

// Constants
import urls from '../_constants/urls'
import { tenantColors } from '../_constants/colors'
import { AuthConfig } from '../_configs'
import ROUTES from '../_constants/routes'

import { authenticationActions } from '../_actions'

// Components
import { AlertMessage } from '../_components/AlertMessage'
import { Auth, AlertWrapper, AlertInnerWrapper } from './styles'
import { MarketingColumn } from './MarketingColumn'

// Helpers
import { redirectByAuthenticationState } from '../_helpers/auth'
import { isProductEnabled } from '../_helpers/flags'
import { FINANCING_TYPE_ABL, FINANCING_TYPE_FACTORING } from '../_constants/financingTypes'
import { cognitoPasswordValid } from '../_helpers/security/passwordValidation'
import { InvalidPasswordCognito } from '../_components/FormValidation/InvalidPasswordCognito'
import { COMPANY_NAME } from 'xen/constants'

const SIGN_UP = 'sign_up'

// Values either have no model or are a child of deal otherwise they are an array nested in deal containing one object
export const mapValuesToDeal = (values, fields) =>
  fields.reduce((acc, field) => {
    if (values?.[field.name] == null) {
      return acc
    }
    const value = values[field.name]

    if (field.model == null || field.model === '') {
      return merge(acc, { [field.name]: value })
    }
    if (field.model === 'deal') {
      return merge(acc, { deal: { [field.name]: value } })
    }
    return merge(acc, { deal: { [plural(field.model)]: [{ [field.name]: value }] } })
  }, {})

export const SignUp = () => {
  const [salesPerson, setSalesPerson] = useState(null)
  const [customError, setCustomError] = useState('')
  const [passwordInvalid, setPasswordInvalid] = useState(false)
  const [initialValues, setInitialValues] = useState({})
  const dispatch = useDispatch()

  const { authentication, env, idleTimeoutMessage, tenant, featureFlags, user } = useSelector((state) => ({
    authentication: state.authentication,
    env: state.env.env,
    idleTimeoutMessage: state.authentication.idleTimeoutMessage,
    tenant: state.env.tenant,
    featureFlags: state.featureFlags,
    user: state.user,
  }))

  const clearError = () => {
    dispatch(authenticationActions.clearError())
  }
  const stopLoading = () => {
    dispatch(authenticationActions.stopLoading())
  }

  useEffect(() => {
    dispatch(authenticationActions.getSignUpFormAndMarketing(tenant))
    const queryParams = new URLSearchParams(window.location.search)
    if (queryParams.has('sp')) {
      setSalesPerson(queryParams.get('sp'))
    }
    clearError()
    stopLoading()
  }, [])

  useEffect(() => {
    if (authentication?.signUpForm != null) {
      setInitialValues(
        authentication.signUpForm.fields.reduce((acc, field) => {
          return merge(acc, { [field.name]: field.default_value || '' })
        }, {})
      )
    }
  }, [authentication?.signUpForm])

  redirectByAuthenticationState(SIGN_UP)

  const handleBlur = (setFieldTouched, name) => {
    setFieldTouched(name, true)
  }

  const handleChange = (setFieldValue, attr, val) => {
    setFieldValue(attr, val)
  }

  const handleSignUp = async (values) => {
    const mergeFormAndValues = mapValuesToDeal(values, authentication.signUpForm.fields)
    dispatch(authenticationActions.signUpWithDealInfo(mergeFormAndValues))
  }

  const config = AuthConfig[tenant]
  const email = user && user.email_address ? user.email_address : ''
  let fields = authentication?.signUpForm?.fields || []
  let financing_type = null

  const duplicate_email = 'Duplicate Email'
  const duplicate_phone = 'Duplicate Phone'
  const bgImage = `${urls.IMAGES}/${env}/${tenant}/sign_bg.jpg`

  return (
    <Auth
      bg={config.common.show_auth_bg ? bgImage : false}
      bg_size={config.common['bg_size']}
      customColors={tenantColors[tenant]}
    >
      <Helmet>
        <title>
          {startCase(SIGN_UP)} • {COMPANY_NAME}
        </title>
      </Helmet>
      {authentication.isIdleTimeout && (
        <AlertWrapper>
          <AlertInnerWrapper>
            <AlertMessage type="error">{idleTimeoutMessage}</AlertMessage>
          </AlertInnerWrapper>
        </AlertWrapper>
      )}
      <div className={`auth-panel`}>
        <div className={`section primary-section`}>
          <div className={SIGN_UP}>
            <h1 className={`title`}>{authentication?.signUpForm?.title}</h1>
            <Formik
              enableReinitialize
              initialValues={{ ...initialValues, email: email }}
              onSubmit={async (values) => {
                if (salesPerson) {
                  values.salesPerson = salesPerson
                }
                if (!financing_type) {
                  financing_type = isProductEnabled(FINANCING_TYPE_ABL, featureFlags)
                    ? FINANCING_TYPE_ABL
                    : FINANCING_TYPE_FACTORING
                }
                const { password } = values
                setPasswordInvalid(false)
                if (!cognitoPasswordValid(password)) {
                  setCustomError('Password Invalid')
                  setPasswordInvalid(true)
                  return false
                }
                handleSignUp(values, tenant)
              }}
            >
              {({ setFieldTouched, setFieldValue, values, errors, touched, key = location.key }) => (
                <Form autoComplete="on" className={`${SIGN_UP}-form`} key={key}>
                  {authentication.error && (
                    <span className={`error-msg`}>
                      {typeof authentication.error === 'string'
                        ? authentication.error
                        : JSON.stringify(authentication.error)}
                    </span>
                  )}
                  {customError && <span className={`error-msg`}>{customError}</span>}
                  {(authentication.error === duplicate_email || authentication.error === duplicate_phone) && (
                    <Link className={`duplicate`} to={ROUTES.sign_in}>
                      Sign in instead.
                    </Link>
                  )}
                  {fields.map((field, i) => (
                    <Fragment key={i + field.name}>
                      <Field
                        errors={errors}
                        field={field}
                        handleBlur={(name) => handleBlur(setFieldTouched, name)}
                        handleChange={(attr, val) => handleChange(setFieldValue, attr, val)}
                        touched={touched}
                        values={values}
                      />
                      {field.following_question && values[field.name] === field.following_question.condition && (
                        <Field
                          errors={errors}
                          field={field.following_question}
                          handleBlur={(name) => handleBlur(setFieldTouched, name)}
                          handleChange={(attr, val) => handleChange(setFieldValue, attr, val)}
                          touched={touched}
                          values={values}
                        />
                      )}
                    </Fragment>
                  ))}

                  {passwordInvalid && <InvalidPasswordCognito style={{ marginTop: '20px' }} />}

                  <div className={`form-group`}>
                    <Button
                      buttonType={`button`}
                      className={`submit-btn`}
                      disabled={authentication.loading}
                      text={authentication.loading ? <FaSpinner /> : 'Sign Up'}
                      type={`submit`}
                    />
                  </div>
                  <div className={`further-options ${SIGN_UP}-further-options`}>
                    <div>
                      <p>
                        Already have an account? <Link to={ROUTES.sign_in}>Sign in</Link>
                      </p>
                      <p className="small">
                        This site is protected by reCAPTCHA and the Google{' '}
                        <a href="https://policies.google.com/privacy">Privacy Policy</a> and{' '}
                        <a href="https://policies.google.com/terms">Terms of Service</a> apply.
                      </p>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
        <MarketingColumn />
      </div>
    </Auth>
  )
}
