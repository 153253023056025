import { formatCase } from 'xen/helpers';
export const transformUserResponse = (response) => {
    return {
        activeTill: response.active_till ? new Date(response.active_till) : null,
        archived: response.archived,
        businessAblId: response.business_abl_id ?? '',
        businessId: response.business_id ?? '',
        createdAt: new Date(response.created_at),
        deleted: response.deleted,
        disabled: !!response.disabled,
        emailAddress: response.email_address,
        firstName: response.first_name ?? '',
        flowStep: response.flow_step ?? '',
        id: response.id,
        intercomHash: response.intercom_hash ?? '',
        isAdmin: !!response.is_admin,
        isOperations: response.is_operations,
        isSalesPerson: !!response.is_sales_person,
        isUnderwriter: !!response.is_underwriter,
        landingPageReferrer: response.landing_page_referrer ?? '',
        lastName: response.last_name ?? '',
        middleName: response.middle_name ?? '',
        name: [response.first_name, response.last_name].filter(Boolean).join(' ').trim(),
        // Permissions are only sometimes returned on the user object
        permissions: response.permissions ? response.permissions.map(transformUserPermissionResponse) : [],
        phoneNumber: response.phone_number ?? '',
        resetPasswordRequired: !!response.reset_password_required,
        termsAcceptedAt: response.terms_accepted_at ? new Date(response.terms_accepted_at) : null,
        title: response.title ?? '',
        trafficIp: response.traffic_ip ?? '',
        trafficUserAgent: response.traffic_user_agent ?? '',
        underwriteDealId: response.underwrite_deal_id,
        updatedAt: new Date(response.updated_at),
        userType: response.user_type,
        uuid: response.uuid ?? '',
        hasPassword: response.has_password,
    };
};
export const transformUserPermissionResponse = (response) => {
    const transformed = formatCase.objectKeys('camel', response);
    const { createdAt, updatedAt, ...rest } = transformed;
    return {
        ...rest,
        createdAt: new Date(createdAt),
        updatedAt: new Date(updatedAt),
    };
};
export const transformUserCreatePayload = (payload) => {
    const requestPayload = {
        email_address: payload.emailAddress,
        first_name: payload.firstName,
        user_type: payload.userType,
    };
    if ('activeTill' in payload) {
        requestPayload.active_till = payload.activeTill?.toISOString();
    }
    if ('archived' in payload) {
        requestPayload.archived = payload.archived;
    }
    if ('businessAblId' in payload) {
        requestPayload.business_abl_id = payload.businessAblId;
    }
    if ('businessId' in payload) {
        requestPayload.business_id = payload.businessId;
    }
    if ('flowStep' in payload) {
        requestPayload.flow_step = payload.flowStep;
    }
    if ('isAdmin' in payload) {
        requestPayload.is_admin = payload.isAdmin;
    }
    if ('isOperations' in payload) {
        requestPayload.is_operations = payload.isOperations;
    }
    if ('isSalesPerson' in payload) {
        requestPayload.is_sales_person = payload.isSalesPerson;
    }
    if ('isUnderwriter' in payload) {
        requestPayload.is_underwriter = payload.isUnderwriter;
    }
    if ('landingPageReferrer' in payload) {
        requestPayload.landing_page_referrer = payload.landingPageReferrer;
    }
    if ('lastName' in payload) {
        requestPayload.last_name = payload.lastName;
    }
    if ('middleName' in payload) {
        requestPayload.middle_name = payload.middleName;
    }
    if ('phoneNumber' in payload) {
        requestPayload.phone_number = payload.phoneNumber;
    }
    if ('resetPasswordRequired' in payload) {
        requestPayload.reset_password_required = payload.resetPasswordRequired;
    }
    if ('title' in payload) {
        requestPayload.title = payload.title;
    }
    if ('trafficIp' in payload) {
        requestPayload.traffic_ip = payload.trafficIp;
    }
    if ('trafficUserAgent' in payload) {
        requestPayload.traffic_user_agent = payload.trafficUserAgent;
    }
    if ('disabled' in payload) {
        requestPayload.disabled = payload.disabled;
    }
    return requestPayload;
};
export const transformUserUpdatePayload = (payload) => {
    const requestPayload = {};
    if ('activeTill' in payload) {
        requestPayload.active_till = payload.activeTill?.toISOString();
    }
    if ('archived' in payload) {
        requestPayload.archived = payload.archived;
    }
    if ('businessAblId' in payload) {
        requestPayload.business_abl_id = payload.businessAblId;
    }
    if ('businessId' in payload) {
        requestPayload.business_id = payload.businessId;
    }
    if ('emailAddress' in payload) {
        requestPayload.email_address = payload.emailAddress;
    }
    if ('firstName' in payload) {
        requestPayload.first_name = payload.firstName;
    }
    if ('flowStep' in payload) {
        requestPayload.flow_step = payload.flowStep;
    }
    if ('isAdmin' in payload) {
        requestPayload.is_admin = payload.isAdmin;
    }
    if ('isOperations' in payload) {
        requestPayload.is_operations = payload.isOperations;
    }
    if ('isSalesPerson' in payload) {
        requestPayload.is_sales_person = payload.isSalesPerson;
    }
    if ('isUnderwriter' in payload) {
        requestPayload.is_underwriter = payload.isUnderwriter;
    }
    if ('landingPageReferrer' in payload) {
        requestPayload.landing_page_referrer = payload.landingPageReferrer;
    }
    if ('lastName' in payload) {
        requestPayload.last_name = payload.lastName;
    }
    if ('middleName' in payload) {
        requestPayload.middle_name = payload.middleName;
    }
    if ('phoneNumber' in payload) {
        requestPayload.phone_number = payload.phoneNumber;
    }
    if ('resetPasswordRequired' in payload) {
        requestPayload.reset_password_required = payload.resetPasswordRequired;
    }
    if ('title' in payload) {
        requestPayload.title = payload.title;
    }
    if ('trafficIp' in payload) {
        requestPayload.traffic_ip = payload.trafficIp;
    }
    if ('trafficUserAgent' in payload) {
        requestPayload.traffic_user_agent = payload.trafficUserAgent;
    }
    if ('userType' in payload) {
        requestPayload.user_type = payload.userType;
    }
    if ('disabled' in payload) {
        requestPayload.disabled = payload.disabled;
    }
    if ('password' in payload) {
        requestPayload.password = payload.password;
    }
    if ('termsAcceptedAt' in payload) {
        requestPayload.terms_accepted_at = payload.termsAcceptedAt?.toISOString();
    }
    return requestPayload;
};
