import React, { useEffect, useRef } from 'react'

// Constants
import { tenantColors } from '../../_constants/colors'
import { useSelector } from 'react-redux'

// Styled Components
import { StyledMenuToggle } from './MenuToggle.styles'
import { Menu } from './Menu'
import { useMenuOpen } from '../../_reduxHooks/menuOpen.hook'
import { menuConfig, headerConfig } from '../../_configs'

export const MenuToggle = () => {
  const env = useSelector((state) => state.env)
  const menuOpen = useSelector((state) => state.menuOpen)

  const { toggleMenu } = useMenuOpen()
  const tenant = env.tenant
  const customColors = tenantColors[tenant]
  const config = menuConfig[tenant]
  const headerTenantConfig = headerConfig[tenant]

  const toggleRef = useRef()
  const menuRef = useRef()

  useEffect(() => {
    document.addEventListener('mousedown', handleClick)
    return () => {
      document.removeEventListener('mousedown', handleClick)
    }
  }, [])

  const handleClick = (e) => {
    let isClickOutside = menuRef.current && !menuRef.current.contains(e.target)
    let isClickOnToggle = toggleRef.current && toggleRef.current.contains(e.target)

    if (isClickOutside && !isClickOnToggle) {
      toggleMenu()
    }
  }

  if (!config?.length && !headerTenantConfig?.length) return null

  return (
    <>
      <StyledMenuToggle className={`menu-toggle`} customColors={customColors} onClick={toggleMenu} ref={toggleRef}>
        ⋯
      </StyledMenuToggle>
      {menuOpen && (
        <div className={`menu-container`} ref={menuRef}>
          <Menu />
        </div>
      )}
    </>
  )
}
