import { jsx as _jsx } from "react/jsx-runtime";
import { createContext, useContext } from 'react';
const defaultDeal = {
    amount: 60000,
    archived: false,
    archivedAt: '',
    brokerCommissions: [],
    business: '',
    businessCity: '',
    businessCode: '',
    businessContacts: [],
    businessEntity: '',
    businessName: '',
    businessOfficers: [],
    businessPhone: '',
    businessState: '',
    businessStreet: '',
    businessUnit: '',
    createdAt: '',
    debtors: [],
    documentSigners: [],
    dueDiligenceDocuments: [],
    formattedAddress: '',
    id: 0,
    isClient: false,
    lastAction: '',
    metaForm: {
        customFields: null,
        id: 1,
        name: '',
    },
    productType: 'factoring',
    salesPerson: '',
    salesPersonId: '',
    stageStatus: 'application_ready_to_start',
    state: 'pending',
    submissionDate: '',
    tenantId: 0,
    updatedAt: '',
    viewedSinceLastAction: false,
};
const DealContext = createContext(defaultDeal);
export const DealProvider = ({ children, deal }) => {
    return _jsx(DealContext.Provider, { value: deal ?? defaultDeal, children: children });
};
export const useDealContext = () => {
    const context = useContext(DealContext);
    return context;
};
