import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import { ClipLoader } from 'react-spinners'

// Components
import { Button } from '../Button'
import colors from '../../_constants/colors'

export const S_LoaderButton = styled(Button)`
  ${(props) => props.customCss}
`

export const LoaderButton = ({
  children,
  className,
  customCss,
  disabled = false,
  isLoading,
  onClick,
  text,
  ...rest
}) => {
  return (
    <S_LoaderButton
      className={className}
      customCss={customCss}
      disabled={isLoading || disabled}
      onClick={onClick}
      text=""
      type={'button'}
      {...rest}
    >
      <div>
        {isLoading ? (
          <ClipLoader
            color={`${className.includes('secondary') ? colors.TEXT_DARK : '#fff'}`}
            cssOverride={{
              display: `block`,
              margin: `0`,
            }}
            size={15}
          />
        ) : (
          <>
            {text}
            {children}
          </>
        )}
      </div>
    </S_LoaderButton>
  )
}

LoaderButton.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]),
  className: PropTypes.string,
  isLoading: PropTypes.bool.isRequired,
  onClick: PropTypes.func,
  text: PropTypes.string,
  customCss: PropTypes.any,
}

LoaderButton.defaultProps = {
  text: '',
  className: '',
  customCss: css``,
}
