import { jsx as _jsx } from "react/jsx-runtime";
import { z } from 'zod';
import { MultiSelect, Select, Tooltip } from 'xen-ui';
import { useGetSalesPeople } from 'xen/api/use-sales-person';
import { FormSelect } from '../select';
export const FormSalesPersonSelect = ({ label = DEFAULT_LABEL, nothingFoundMessage = DEFAULT_NOTHING_FOUND_MESSAGE, allowDeselect, dataFilter = () => true, error, required, withUnassignedOption, ...props }) => {
    const { data: salesPeople = [], isError, isSuccess } = useGetSalesPeople();
    const { isDataEmpty, fieldError, selectData } = getDataAndErrorStates({
        dataFilter,
        error,
        isError,
        isSuccess,
        salesPeople,
        withUnassignedOption,
    });
    const input = (_jsx(FormSelect, { allowDeselect: allowDeselect ?? !required, data: selectData, disabled: isDataEmpty, error: fieldError, label: label, nothingFoundMessage: nothingFoundMessage, required: required, searchable: true, ...props }));
    if (isDataEmpty) {
        return _jsx(Tooltip, { label: DEFAULT_NO_DATA_MESSAGE, children: input });
    }
    return input;
};
const salesPersonSelectSchema = z.string().min(1, { message: 'Sales person is required' });
FormSalesPersonSelect.schema = salesPersonSelectSchema;
export const SalesPersonSelect = ({ label = DEFAULT_LABEL, nothingFoundMessage = DEFAULT_NOTHING_FOUND_MESSAGE, allowDeselect, dataFilter = () => true, error, placeholder, required, value, withUnassignedOption, ...props }) => {
    const { data: salesPeople = [], isError, isSuccess } = useGetSalesPeople();
    const { isDataEmpty, fieldError, selectData } = getDataAndErrorStates({
        dataFilter,
        error,
        isError,
        isSuccess,
        salesPeople,
        withUnassignedOption,
    });
    const input = (_jsx(Select, { allowDeselect: allowDeselect ?? !required, data: selectData, disabled: isDataEmpty, error: fieldError, label: label, nothingFoundMessage: nothingFoundMessage, placeholder: value ? undefined : placeholder, required: required, searchable: true, value: value, ...props }));
    if (isDataEmpty) {
        return _jsx(Tooltip, { label: DEFAULT_NO_DATA_MESSAGE, children: input });
    }
    return input;
};
export const SalesPersonMultiSelect = ({ label = DEFAULT_LABEL, nothingFoundMessage = DEFAULT_NOTHING_FOUND_MESSAGE, dataFilter = () => true, error, placeholder, required, value, withUnassignedOption, ...props }) => {
    const { data: salesPeople = [], isError, isSuccess } = useGetSalesPeople();
    const { isDataEmpty, fieldError, selectData } = getDataAndErrorStates({
        dataFilter,
        error,
        isError,
        isSuccess,
        salesPeople,
        withUnassignedOption,
    });
    const input = (_jsx(MultiSelect, { data: selectData, disabled: isDataEmpty, error: fieldError, label: label, nothingFoundMessage: nothingFoundMessage, placeholder: value?.length ? undefined : placeholder, required: required, value: value, ...props }));
    if (isDataEmpty) {
        return _jsx(Tooltip, { label: DEFAULT_NO_DATA_MESSAGE, children: input });
    }
    return input;
};
// Private -------------------------------------------------------------------
const DEFAULT_LABEL = 'Sales person';
const DEFAULT_NOTHING_FOUND_MESSAGE = 'No sales people found...';
const DEFAULT_ERROR_MESSAGE = 'Could not fetch sales people';
const DEFAULT_NO_DATA_MESSAGE = 'No sales people exist';
const createSelectData = (salesPerson) => {
    const { user: { firstName, lastName }, } = salesPerson;
    return {
        value: String(salesPerson.id),
        label: `${firstName} ${lastName}`.trim(),
    };
};
const getDataAndErrorStates = ({ dataFilter, error, isError, isSuccess, salesPeople, withUnassignedOption, }) => {
    let selectData = salesPeople
        .filter(dataFilter)
        .map(createSelectData)
        .sort((a, b) => a.label.localeCompare(b.label));
    const isDataEmpty = isSuccess && selectData.length === 0;
    if (withUnassignedOption && !isDataEmpty)
        selectData = addUnassignedOption(selectData);
    const fieldError = isError ? DEFAULT_ERROR_MESSAGE : error;
    return {
        isDataEmpty,
        fieldError,
        selectData,
    };
};
const addUnassignedOption = (data) => {
    if (data.length === 0)
        return [];
    return [{ label: '(Unassigned)', value: 'unassigned' }, ...data];
};
export const filterOnlyAssignable = (assignedId) => {
    return (salesPerson) => {
        // Context: There is a line of code in finvoice_platform that creates SalesPeople
        // for admin, underwriter, and salesperson. We are going to use the front-end to
        // filter out the correct sales people so we only include the sales people that
        // should be assignable, not all sales people.
        // If sales person is already assigned, include them
        if (assignedId === salesPerson?.id)
            return true;
        // If associated user is enabled sales person, include them
        if (salesPerson?.user && salesPerson.user.isSalesPerson && !salesPerson.user.disabled)
            return true;
        return false;
    };
};
