import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useController } from 'react-hook-form';
import { z } from 'zod';
import { Box, Progress, rem, Stack, Text, XenIcon, PasswordInput as XenPasswordInput } from 'xen-ui';
import { ErrorText } from '../../error-text';
const CHARACTER_IS = {
    NUMBER: /[0-9]/,
    LOWERCASE: /[a-z]/,
    UPPERCASE: /[A-Z]/,
    SPECIAL: /[\^$*.[\]{}()?!@#%&,><:;|_~]/,
};
const MIN_CHARACTERS = 8;
export function FormPasswordInput({ name, control, defaultValue, error, iconLeft, iconRight, rules, shouldUnregister, onChange, hideStrengthRequirements = false, ...props }) {
    const { field: { value, onChange: fieldOnChange, ...field }, fieldState, } = useController({
        name,
        control,
        defaultValue,
        rules,
        shouldUnregister,
    });
    const fieldError = error ?? fieldState.error?.message;
    const LeftIcon = iconLeft ? XenIcon[iconLeft] : undefined;
    const RightIcon = iconRight ? XenIcon[iconRight] : undefined;
    // Always set field error, but hide redundant error text when strength requirements are visible
    const hasFieldError = Boolean(fieldError);
    const errorText = hasFieldError ? _jsx(ErrorText, { component: "span", message: fieldError }) : undefined;
    const errorProps = hideStrengthRequirements ? undefined : { style: { display: 'none' } };
    return (_jsx(XenPasswordInput, { error: errorText, errorProps: errorProps, inputContainer: (input) => {
            if (hideStrengthRequirements)
                return input;
            const strength = getStrength(value);
            const progressColor = strength === 100 ? 'green.9' : strength > 50 ? 'yellow' : 'gray.7';
            return (_jsxs(Stack, { gap: "xs", children: [_jsxs(Stack, { gap: "xs", children: [_jsx(Progress, { color: progressColor, mt: 4, size: 6, value: strength }), _jsxs(Stack, { gap: "xxxs", children: [_jsx(PasswordRequirement, { hasFieldError: hasFieldError, label: `Includes at least ${MIN_CHARACTERS} characters`, meetsRequirement: value.length >= MIN_CHARACTERS }), requirements.map((requirement, index) => (_jsx(PasswordRequirement, { hasFieldError: hasFieldError, label: requirement.label, meetsRequirement: requirement.regex.test(value) }, index)))] })] }), input] }));
        }, inputWrapperOrder: ['label', 'description', 'error', 'input'], onChange: (e) => {
            fieldOnChange(e);
            onChange?.(e);
        }, styles: { root: { display: 'flex', flexDirection: 'column', gap: '.25rem' }, ...props.styles }, value: value, ...(LeftIcon && { leftSection: _jsx(LeftIcon, { fontSize: "inherit" }) }), ...(RightIcon && { rightSection: _jsx(RightIcon, { fontSize: "inherit" }) }), ...field, ...props }));
}
const inputSchema = () => z
    .string()
    .min(MIN_CHARACTERS, { message: `Must include at least ${MIN_CHARACTERS} characters` })
    .refine((value) => CHARACTER_IS.NUMBER.test(value), { message: 'Must include at least one number' })
    .refine((value) => CHARACTER_IS.LOWERCASE.test(value), {
    message: 'Must include at least one lowercase letter',
})
    .refine((value) => CHARACTER_IS.UPPERCASE.test(value), {
    message: 'Must include at least one uppercase letter',
})
    .refine((value) => CHARACTER_IS.SPECIAL.test(value), {
    message: 'Must include at least one special character: ^$*.[]{}()?!@#%&,><:;|_~',
});
FormPasswordInput.schema = inputSchema;
const PasswordRequirement = ({ hasFieldError, meetsRequirement, label }) => {
    // By default, show gray icon and text
    let Icon = XenIcon.Square;
    let textColor = 'gray.7';
    // If requirement is met, show green icon and text
    if (meetsRequirement) {
        Icon = XenIcon.CheckMark;
        textColor = 'green.9';
    }
    else if (hasFieldError) {
        // If requirement is not met AND there is a field error, show red icon and text
        Icon = XenIcon.Close;
        textColor = 'red.8';
    }
    return (_jsxs(Text, { c: textColor, size: "xs", style: { display: 'flex', alignItems: 'center' }, children: [_jsx(Icon, { style: { width: rem(12), height: rem(12) } }), ' ', _jsx(Box, { component: "span", ml: 8, children: label })] }));
};
const requirements = [
    { regex: CHARACTER_IS.NUMBER, label: 'Includes number' },
    { regex: CHARACTER_IS.LOWERCASE, label: 'Includes lowercase letter' },
    { regex: CHARACTER_IS.UPPERCASE, label: 'Includes uppercase letter' },
    { regex: CHARACTER_IS.SPECIAL, label: 'Includes special character: ^$*.[]{}()?!@#%&,><:;|_~' },
];
const getStrength = (password) => {
    let multiplier = password.length >= MIN_CHARACTERS ? 0 : 1;
    requirements.forEach((requirement) => {
        if (!requirement.regex.test(password)) {
            multiplier += 1;
        }
    });
    return Math.max(100 - (100 / (requirements.length + 1)) * multiplier, 10);
};
