import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Alert, Group, Stack, Text } from 'xen-ui';
import { ButtonLink, TextLink } from 'xen/components/link';
import { SUPPORT_EMAIL } from 'xen/constants';
import { useAuthentication } from 'xen/hooks';
import { Page } from '../page';
import { PageTitle } from '../page-title';
export const NotFound = () => {
    const { isLoggedIn } = useAuthentication();
    return (_jsx(Page, { "data-testid": "page-not-found", children: _jsx(Alert, { color: "red", title: 404, variant: "light", children: _jsxs(Stack, { gap: "md", children: [_jsx(PageTitle, { title: "Page not found" }), _jsxs(Text, { size: "lg", children: ["No page found at this address: ", _jsx("code", { children: window.location.pathname })] }), _jsxs(Text, { size: "lg", children: [_jsx(TextLink, { to: "/", children: "Return to home" }), " or contact", ' ', _jsx(TextLink, { to: `mailto:${SUPPORT_EMAIL}`, children: SUPPORT_EMAIL }), " for more information."] }), !isLoggedIn && (_jsxs(Group, { children: [_jsx(ButtonLink, { searchParams: { redirectTo: window.location.href }, to: "/sign-in", children: "Sign in" }), _jsx(ButtonLink, { to: "/sign-up", variant: "outline", children: "Sign up" })] }))] }) }) }));
};
