import { z } from 'zod';
import { DEAL_STAGE_STATUSES } from 'xen/_modules/prospects/constants';
export const isValidSearchParam = (key, value) => {
    const isNumberString = (val) => typeof val === 'string' && !isNaN(Number(val));
    const typeGuards = {
        acceptTerms: (value) => value === 'true',
        addDocuments: (value) => value === 'true',
        admin: (value) => value === 'true',
        analyze: (value) => value === 'true',
        archived: (value) => value === 'true',
        businessName: (value) => typeof value === 'string',
        disclaimer: (value) => value === 'true',
        edit: (value) => value === 'true',
        editStatus: (value) => value === 'true',
        emailAddress: (value) => z.string().email().safeParse(value).success,
        integrationPlaidAccounts: (value) => typeof value === 'string',
        metaFormId: (value) => isNumberString(value),
        page: (value) => isNumberString(value),
        perPage: (value) => isNumberString(value),
        print: (value) => value === 'true',
        quoteCreated: (value) => value === 'true',
        redirectTo: (value) => typeof value === 'string',
        reload: (value) => value === '1',
        'salesPersonId[]': (value) => Array.isArray(value) && value.every((item) => isNumberString(item)),
        sendAgreement: (value) => value === 'contract' || value === 'quote',
        sessionToken: (value) => typeof value === 'string',
        sortAsc: (value) => value === 'true',
        sortBy: (value) => typeof value === 'string' &&
            [
                'amount',
                'business',
                'created_at',
                'meta_form_name',
                'sales_person_name',
                'stage_status',
                'updated_at',
            ].includes(value),
        sp: (value) => isNumberString(value),
        'stageStatus[]': (value) => Array.isArray(value) && value.every((item) => DEAL_STAGE_STATUSES.includes(item)),
        tab: (value) => typeof value === 'string',
        validate: (value) => value === 'docs' || value === 'info',
        verificationToken: (value) => typeof value === 'string',
        view: (value) => typeof value === 'string' &&
            [
                'brokers',
                'business-information',
                'notes',
                'quotes',
                'risk-and-fraud',
                'sign-documents',
                'status',
                'underwriting',
                'uploaded-documents',
            ].includes(value),
        welcome: (value) => value === 'true',
        welcomePassword: (value) => value === 'true',
    };
    return typeGuards[key]?.(value) || false;
};
