import React from 'react'

export const PieChartShadow = ({ id }) => {
  return (
    <filter height="200%" id={id} width="200%" x="-50%" y="-50%">
      <feGaussianBlur in="SourceAlpha" result="blur" stdDeviation="1"></feGaussianBlur>
      <feOffset dx="1" dy="1"></feOffset>
      <feComposite in2="SourceAlpha" k2="-1" k3="1" operator="arithmetic" result="shadowDiff"></feComposite>

      <feFlood floodColor="#444444" floodOpacity="0.1"></feFlood>
      <feComposite in2="shadowDiff" operator="in"></feComposite>
      <feComposite in2="SourceGraphic" operator="over" result="firstfilter"></feComposite>

      <feGaussianBlur in="firstfilter" result="blur2" stdDeviation="1"></feGaussianBlur>
      <feOffset dx="0" dy="-2"></feOffset>
      <feComposite in2="firstfilter" k2="-1" k3="1" operator="arithmetic" result="shadowDiff"></feComposite>

      <feFlood floodColor="#444444" floodOpacity="0.1"></feFlood>
      <feComposite in2="shadowDiff" operator="in"></feComposite>
      <feComposite in2="firstfilter" operator="over"></feComposite>
    </filter>
  )
}
