import { useQuery } from '@tanstack/react-query';
import { request } from 'xen/api';
import { formatCase } from 'xen/helpers';
export const useGetTransactions = ({ queryParams, urlParams: { accountId } }) => {
    return useQuery({
        queryKey: ['integrations-plaid-accounts', accountId, 'integration-plaid-transactions', { queryParams }],
        queryFn: async () => {
            const response = await request({
                method: 'GET',
                url: `integration/plaid/accounts/${accountId}/transactions`,
                params: queryParams,
            });
            return formatCase.objectKeys('camel', response);
        },
    });
};
