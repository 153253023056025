import { jsx as _jsx } from "react/jsx-runtime";
import { ScrollArea as MantineScrollArea } from '@mantine/core';
import styles from './scroll-area.module.css';
export const ScrollArea = ({ hideXScroll = false, offsetScrollbars = 'x', type = 'auto', ...props }) => {
    return (_jsx(MantineScrollArea, { ...props, classNames: {
            viewport: hideXScroll ? styles.hideXScroll : undefined,
            ...props.classNames,
        }, offsetScrollbars: offsetScrollbars, role: "region", tabIndex: 0, type: type }));
};
ScrollArea.Autosize = MantineScrollArea.Autosize;
