import { ConfigProxy } from '../_helpers/util'
export const metadataConfig = ConfigProxy(
  {
    finvoice: {
      tenant_name: 'Xen',
      link_landing_page: 'https://xenplatforms.com/',
    },
    capitalnow: {
      tenant_name: 'Capital Now',
      link_landing_page: 'http://www.capitalnow.ca/',
    },
    sevenoaks: {
      tenant_name: 'SevenOaks',
      link_landing_page: 'http://www.sevenoakscapital.com/',
    },
    boldbusinesscapital: {
      tenant_name: 'Bold Business Capital',
      link_landing_page: 'https://boldbusinesscapital.com/',
    },
    twelvefive: {
      tenant_name: '12Five Capital',
      link_landing_page: 'https://12five.com',
    },
    sallyportcf: {
      tenant_name: 'Sallyport Commercial Finance',
      link_landing_page: 'http://www.sallyportcf.com/',
    },
    portercapital: {
      tenant_name: 'Porter Capital Corporation',
      link_landing_page: 'https://www.portercap.net/',
    },
    newcenturyfinancial: {
      tenant_name: 'New Century Financial',
      link_landing_page: 'https://www.newcenturyfinancial.com/',
    },
    scfactoringsolution: {
      tenant_name: 'Smart Capital Factoring Solution',
      link_landing_page: 'http://www.scfactoringsolution.com/',
    },
    chartercapitalusa: {
      tenant_name: 'Charter Capital Factoring',
      link_landing_page: 'https://www.chartercapitalusa.com/',
    },
    acsfactors: {
      tenant_name: 'ACS Factors',
      link_landing_page: 'https://www.acsfactors.com/',
    },
    ccbg: {
      tenant_name: 'Capital City Bank',
      link_landing_page: 'https://www.ccbg.com/',
    },
    hedayacapital: {
      tenant_name: 'Hedaya Capital',
      link_landing_page: 'https://www.hedayacapital.com/',
    },
    republicbc: {
      tenant_name: 'Republic Business Credit',
      link_landing_page: 'http://www.republicbc.com/',
    },
    newbridgeglobal: {
      tenant_name: 'Newbridge Global',
      link_landing_page: 'https://www.newbridgegs.com/',
    },
    axosbank: {
      tenant_name: 'Axos Bank',
      link_landing_page: 'https://www.axosbank.com/en/Business/Commercial-Lending/Factoring',
    },
    factoringdemo: {
      tenant_name: 'Xen Demo Factoring',
      link_landing_page: 'https://demo.finvoice.co/lender_home',
    },
    abldemo: {
      tenant_name: 'Xen Demo ABL',
      link_landing_page: 'https://sandbox.finvoice.co/lender_home',
    },
    demo: {
      tenant_name: 'Xen Demo Factoring',
      link_landing_page: 'https://demo.finvoice.co/lender_home',
    },
    sandbox: {
      tenant_name: 'Xen Demo ABL',
      link_landing_page: 'https://sandbox.finvoice.co/lender_home',
    },
    prodemo: {
      tenant_name: 'Xen Demo Pro',
      link_landing_page: 'https://xenplatforms.com/',
    },
    marcocapital: {
      tenant_name: 'Marco Capital',
      link_landing_page: 'https://www.marcocap.com/',
    },
    marcofi: {
      tenant_name: 'Marco Capital',
      link_landing_page: 'https://www.marcocap.com/',
    },
    ctrlpay: {
      tenant_name: 'Ctrlpay',
      link_landing_page: 'https://www.ctrlpayintl.com/',
    },
    breakoutfinance: {
      tenant_name: 'Breakout Capital',
      link_landing_page: 'https://breakoutfinance.com',
    },
    fastarfunding: {
      tenant_name: 'Fast AR Funding',
      link_landing_page: 'https://fastarfunding.finvoice.co/signup',
    },
    capitalplus: {
      tenant_name: 'MatPro Supply',
      link_landing_page: 'https://capitalplus.com/',
    },
    capitalplusfinancial: {
      tenant_name: 'MatPro Supply',
      link_landing_page: 'https://capitalplus.com/',
    },
    changecapital: {
      tenant_name: 'Change Capital',
      link_landing_page: 'https://change.capital/',
    },
    fundient: {
      tenant_name: 'Fundient',
      link_landing_page: 'https://fundient.com/',
    },
    interportcapital: {
      tenant_name: 'Interport Trade Capital',
      link_landing_page: 'https://interportcap.com/',
    },
    velocityfi: {
      tenant_name: 'VelocityFi',
      link_landing_page: 'https://velocityfi.com/',
    },
    canntella: {
      tenant_name: 'Canntella',
      link_landing_page: 'http://www.canntella.com/',
    },
  },
  {
    tenant_name: 'Xen',
    link_landing_page: 'https://xenplatforms.com/',
  }
)
