const ROUTES = {
  upload_invoices: `/upload_invoices`,
  sign_in: `/signin`,
  sign_up: `/signup`,
  forgot_password: `/forgot_password`,
  reset_password: `/reset_password`,
  page_not_found: `/404`,
  accounting_platforms: `/accounting_platforms`,
  confirm_form: `/confirm_form`,
  submitted: `/submitted`,
  client_home: `/client_home`,
  client_invoice_submission: `/client_invoice_submission`,
  abl_client_home: `/abl_client_home`,
  borrowing_base_submission: `/borrowing_bases/submission`,
  borrowing_base_summary: `/borrowing_bases/summary`,
  bbc_doc_upload: `/bbc_doc_upload`,
  prospect: `/prospect`,
  bank_loading: `/bank_loading`,
  your_invoices: `/your_invoices`,
  quickbooks_loading: `/quickbooks_loading`,
  lender_home: `/lender_home`,
  cash_receipt_details: `/cash_receipt_details`,
  funding_request_edit: `/funding_request_edit`,
  statements_daily: '/abl/daily-statements',
  e_signing: '/e-signing',
  email_confirmation: '/email_confirmation',
  settings: `/settings`,
  user_alerts: `/user_alerts`,
}

export const LENDER_ROUTES = {
  schedules: '/schedules',
  schedules_incomplete: '/schedules/incomplete',
  schedules_pending: '/schedules/pending',
  schedules_all: '/schedules/all',
  schedules_approved: '/schedules/approved',
  clients_overview: `/clients_overview`,
  admin: `/admin`,
  business_new_evaluation: `/business_new_evaluation`,
  business_evaluations: `/business_evaluations`,
  prospects_overview: `/prospects_overview`,
  new_prospect: `/new_prospect`,
  client_application: `/client_application`,
  edit_business: `/edit_business`,
  prospect_details: `/prospects_overview/details`,
  underwrite_borrower_details: `/borrower`,
  signing_documents: `/signing_documents`,
  cash_receipts_overview: `/cash_receipts`,
  borrowing_base_overview: `/borrowing_bases`,
  borrowing_base_submission: `/borrowing_bases/submission`,
  borrowing_base_summary: `/borrowing_bases/summary`,
  borrowing_base_templates: `/borrowing_bases/templates`,
  borrowing_base_templates_create: `/borrowing_bases/templates/create`,
  funding_requests_all: `/funding_requests/all`,
  funding_requests_pending: `/funding_requests/pending`,
  funding_requests_approved: `/funding_requests/approved`,
  funding_requests_rejected: `/funding_requests/rejected`,
  funding_requests_funded: `/funding_requests/funded`,
  funding_requests_create: `/funding_requests/create`,
  funding_requests: `/funding_requests`,
  invoices_overview: '/invoices/overview',
  invoice: '/invoice',
  invoices_purchased: '/invoices/purchased',
  invoices_closed: '/invoices/closed',
  invoices_ineligible: '/invoices/ineligible',
  invoices_pending: '/invoices/pending',
  invoices_not_funded: '/invoices/not_funded',
  invoices_all: '/invoices/all',
  invoices_short_paid: '/invoices/short_paid',
  invoices_create: '/schedule/create',
  cash_receipts: '/cash_receipts',
  debtors_overview: '/debtors_overview',
  debtors_list: '/debtors_overview/list',
  debtor_details: `/debtor_details`,
  debtor_payments_overview: `/debtor_payments`,
  debtor_payments_upload: `/debtor_payments/upload`,
  debtor_payments_create: '/debtor_payments/batch/create',
  debtor_payments_batch: '/debtor_payments/batch',

  //for v2 transaction clients only
  debtor_payments_batches: '/debtor_payments/batches',
  debtor_payments_batches_all: '/debtor_payments/batches/all',
  debtor_payments_batches_created: '/debtor_payments/batches/created',
  debtor_payments_batches_pending: '/debtor_payments/batches/pending',
  debtor_payments_batches_incomplete: '/debtor_payments/batches/incomplete',
  debtor_payments_batches_complete: '/debtor_payments/batches/completed',
  debtor_payments_batches_list: `/debtor_payments/list`,

  digitization: '/digitization',
  borrowing_base_workflow: `/borrowing_base_workflow`,
  generated_reports: `/generated-reports`,
  brokers: `/brokers_overview`,
  settings: `/settings`,
}

export const ABL_LENDER_ROUTES = {
  bbc_templates: '/abl/templates',
  bbc_template_details: '/abl/template',
  funding_requests: '/funding_requests/abl',
  business_edit: '/abl/business_edit',
  statements_daily: '/abl/daily-statements',
}

export default ROUTES
