import { combineReducers } from 'redux'

// Reducers
import sideNav from './sideNav.reducer'
import { accounting } from './accounting.reducer'
import { additionalBusinessData } from './additionalBusinessData.reducer'
import { admin } from './admin.reducer'
import { alert } from './alert.reducer'
import { authentication } from './authentication.reducer'
import { bank } from './bank.reducer'
import { bankingAccount } from './bankingAccount.reducer'
import { borrowingBaseCertificate } from './borrowingBaseCertificate.reducer'
import { business } from './business.reducer'
import { businessOfficers } from './businessOfficers.reducer'
import { deal } from './deal.reducer'
import { drag } from './drag.reducer'
import { env } from './env.reducer'
import { esigning } from './esigning.reducer'
import { featureFlags } from './featureFlags.reducer'
import { form } from './form.reducer'
import { menuOpen } from './menuOpen.reducer'
import { modal } from './modal.reducer'
import { notifications } from './notifications.reducer'
import { searchTerm } from './searchTerm.reducer'
import { tenant } from './tenant.reducer'
import { timezone } from './timezone.reducer'
import { topBusinessDebtors } from './topBusinessDebtors.reducer'
import { user } from './user.reducer'
import { cancelToken } from './cancelToken.reducer'
import { subscription } from './subscription.reducer'
import { locks } from './locks.reducer'
import { businessBankAccount } from './businessBankAccount.reducer'
import { reportsUpdate } from './reportsUpdate.reducer'
import { invoiceSchedules } from './scheduleInvoices.reducer'
import { underwriteDeal } from './underwriteDeal.reducer'

// Constants
import { authenticationConstants } from '../_constants'
import { transactions } from './transactions.reducer'

const appReducer = combineReducers({
  accounting,
  additionalBusinessData,
  admin,
  alert,
  authentication,
  bank,
  bankingAccount,
  borrowingBaseCertificate,
  business,
  businessOfficers,
  businessBankAccount,
  deal,
  drag,
  env,
  esigning,
  featureFlags,
  form,
  menuOpen,
  modal,
  notifications,
  searchTerm,
  sideNav,
  tenant,
  timezone,
  topBusinessDebtors,
  user,
  cancelToken,
  subscription,
  locks,
  reportsUpdate,
  transactions,
  invoiceSchedules,
  underwriteDeal,
})

const rootReducer = (state, action) => {
  if (action.type === authenticationConstants.SIGN_OUT_SUCCESS) {
    localStorage.removeItem('persist:finvoiceapp')
    state = {
      // env and featureFlags should persist on sign out
      env: state.env,
      featureFlags: state.featureFlags,
    }
  }

  return appReducer(state, action)
}

export default rootReducer
