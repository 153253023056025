import { jsx as _jsx } from "react/jsx-runtime";
import { lazy } from 'react';
import { Route } from 'react-router-dom';
import { RestrictedAccess } from 'xen/components';
import { useAuthentication } from 'xen/hooks';
const Brokers = lazy(() => import('../pages/brokers'));
const BrokerDetails = lazy(() => import('../pages/broker-details'));
export const BROKERS_PATHS = {
    root: '/brokers',
    details: '/brokers/:id',
};
export const BrokersRoutes = () => {
    const { details, root } = BROKERS_PATHS;
    const { isLoggedIn } = useAuthentication();
    if (!isLoggedIn)
        return [];
    return [
        _jsx(Route, { exact: true, path: root, children: _jsx(RestrictedAccess, { userTypes: ['lender'], children: _jsx(Brokers, {}) }) }, root),
        _jsx(Route, { exact: true, path: details, children: _jsx(RestrictedAccess, { userTypes: ['lender'], children: _jsx(BrokerDetails, {}) }) }, details),
    ];
};
