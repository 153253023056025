import React from 'react'
import styled from 'styled-components'
import { connect } from 'react-redux'

//Constants
import colors, { tenantColors } from '../_constants/colors'
import { COMPANY_NAME } from 'xen/constants'

const StyledCopyright = styled.div`
  color: ${(props) => props.customColors?.footer_header || colors.DEFAULT_FOOTER_HEADER};
  opacity: 0.5;
  line-height: 1.5em;
  justify-self: center;
  padding: 0.625rem;
  font-size: 0.75rem;
  border-top: 1px solid black;

  @media screen and (min-width: 500px) {
    grid-column-start: span 3;
  }
`

const Copyright = ({ tenant }) => {
  const customColors = tenantColors[tenant]
  return (
    <StyledCopyright className={`copyright`} customColors={customColors}>
      <p>
        Copyright &copy; {new Date().getFullYear()}, {COMPANY_NAME}
      </p>
    </StyledCopyright>
  )
}

const mapStateToProps = (state) => {
  const { env } = state
  return {
    env: env.env,
    tenant: env.tenant,
  }
}

const connectedCopyright = connect(mapStateToProps)(Copyright)
export { connectedCopyright as Copyright }
