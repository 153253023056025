import { jsx as _jsx } from "react/jsx-runtime";
import { lazy } from 'react';
import { Route } from 'react-router-dom';
import { useFeatureFlags } from 'xen/hooks';
const ForgotPassword = lazy(() => import('../pages/forgot-password'));
const ResetPassword = lazy(() => import('../pages/reset-password'));
const SignIn = lazy(() => import('../pages/sign-in'));
const SignOut = lazy(() => import('../pages/sign-out'));
const SignUp = lazy(() => import('../pages/sign-up'));
export const AUTH_PATHS = {
    forgotPassword: '/forgot-password',
    resetPassword: '/reset-password',
    signIn: '/sign-in',
    signOut: '/sign-out',
    signUp: '/sign-up',
};
export const AuthRoutes = () => {
    const { forgotPassword, resetPassword, signIn, signOut, signUp } = AUTH_PATHS;
    const { tenantEnrollment } = useFeatureFlags();
    const authenticationRoutes = [
        _jsx(Route, { exact: true, path: forgotPassword, children: _jsx(ForgotPassword, {}) }, forgotPassword),
        _jsx(Route, { exact: true, path: resetPassword, children: _jsx(ResetPassword, {}) }, resetPassword),
        _jsx(Route, { exact: true, path: signIn, children: _jsx(SignIn, {}) }, signIn),
        _jsx(Route, { exact: true, path: signOut, children: _jsx(SignOut, {}) }, signOut),
    ];
    const registrationRoute = (_jsx(Route, { exact: true, path: signUp, children: _jsx(SignUp, {}) }, signUp));
    // Hide user sign-up if tenant enrollment is enabled
    if (tenantEnrollment)
        return authenticationRoutes;
    return [...authenticationRoutes, registrationRoute];
};
