import { jsx as _jsx } from "react/jsx-runtime";
import { Skeleton } from 'xen-ui';
import { useGetSalesPeople } from 'xen/api/use-sales-person';
const DefaultLoader = () => _jsx(Skeleton, { my: 1, py: "md", width: "100%" });
export const SalesPersonName = ({ loader = _jsx(DefaultLoader, {}), salesPersonId }) => {
    const { data: salesPeople = [], isError, isPending } = useGetSalesPeople();
    if (!salesPersonId) {
        return '-';
    }
    if (isError) {
        return 'Error';
    }
    if (isPending) {
        return loader;
    }
    const salesPerson = salesPeople.find(({ id }) => id === salesPersonId)?.user;
    if (!salesPerson) {
        return '-';
    }
    return `${salesPerson.firstName} ${salesPerson.lastName}`.trim();
};
