import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import get from 'lodash/get'

// Helpers
import { getIcon } from '../../../_helpers/util'

// Styles
import { S_FormInput, S_TextAreaInput, S_FormSelect, S_RadioGroup, S_MultiSelect, S_Icon } from './FormInput.styles'

/*
# DO NOT USE THIS
# The dumbest input possible, no state, no logic, no rules
*/

export const FormInputRef = React.forwardRef((props, ref) => (
  <S_FormInput
    autoComplete="off"
    data-testid={`${props?.testId ? props?.testId : 'default-form-input'}`}
    ref={ref}
    {...props}
  />
))

export const FormInput = (props) => {
  const { icon, iconClassName = '', ...rest } = props

  const newProps = { ...rest }
  if (!props.type) {
    newProps['type'] = 'text'
  }
  const { inputRef } = props
  if (icon) {
    const isIconComponent = typeof icon === 'function'
    const Icon = isIconComponent ? icon : getIcon(icon)
    return (
      <S_Icon>
        <Icon className={`icon ${iconClassName}`} />
        <FormInputRef {...newProps} ref={inputRef} />
      </S_Icon>
    )
  }
  return <FormInputRef {...newProps} ref={inputRef} />
}

export const TextAreaInput = (props) => {
  const { inputRef } = props
  return <S_TextAreaInput {...props} ref={inputRef} />
}

export const SelectInput = (props) => {
  const {
    options = [],
    className = '',
    disabled = false,
    placeholder,
    defaultValue = '',
    inputRef,
    invalid = false,
    onChange,
    onBlur,
    'data-testid': dataTestId,
    name,
    id,
    ...rest
  } = props
  const showDefault = get(props, 'showDefault', false)
  const defaultDisabled = !showDefault
  return (
    <S_FormSelect invalid={invalid}>
      <select
        className={className}
        data-testid={dataTestId}
        defaultValue={defaultValue}
        disabled={disabled}
        id={id}
        key={`select-${name}`}
        name={name}
        onBlur={onBlur}
        onChange={onChange}
        ref={inputRef}
        {...rest}
      >
        {!!placeholder && (
          <option disabled={defaultDisabled} key={`${name}-placeholder`} selected={!defaultValue} value="">
            {placeholder}
          </option>
        )}
        {options.map((opt, i) => (
          <option
            disabled={opt?.disabled || false}
            key={`${name}-${i}`}
            selected={opt.value === defaultValue}
            value={opt.value}
          >
            {opt.display}
          </option>
        ))}
      </select>
    </S_FormSelect>
  )
}

function mapStateToProps(state) {
  const { env } = state
  return {
    tenant: env.tenant,
  }
}

const RadioGroupInput = (props) => {
  const {
    defaultValue = '',
    disabled = false,
    following_question,
    id,
    inputRef,
    label,
    name,
    onChange,
    options = [],
    value,
    className = '',
    staticButtons = false,
    'data-testid': dataTestId,
    ...rest
  } = props
  const initalChecked = value || defaultValue
  const [displayValue, setDisplayValue] = useState(initalChecked)

  // Allow us to reset the field when ID changes
  useEffect(() => {
    setDisplayValue(value || defaultValue)
  }, [id, value, defaultValue])

  const handleChangeLocal = (e) => {
    if (!disabled) {
      let value = e.target.value
      setDisplayValue(value)
      onChange(value, id, e)
    }
  }

  return (
    <S_RadioGroup
      {...rest}
      className={`radio-group ${className}`}
      disabled={disabled}
      id={id}
      role="radiogroup"
      staticButtons={staticButtons}
    >
      <div className="buttons">
        {options.map((opt) => {
          const OptionLabel = () => {
            if (!opt.label) {
              return ''
            }
            return typeof opt.label === 'function' ? <opt.label /> : opt.label
          }
          const checked = String(opt.value) === displayValue.toString()
          return (
            <div
              className={`radio-btn ${opt.className ? opt.className : ''} ${checked ? 'checked' : ''}`}
              key={opt.value}
            >
              <input
                checked={checked}
                data-testid={`${dataTestId}-${opt.value}`}
                disabled={disabled}
                id={`${id}-${opt.value}`}
                name={`${name}`}
                onChange={handleChangeLocal}
                ref={inputRef}
                type="radio"
                value={opt.value}
              />

              <label disabled={disabled} htmlFor={`${id}-${opt.value}`}>
                <OptionLabel />
              </label>
            </div>
          )
        })}
      </div>
    </S_RadioGroup>
  )
}

const connectedRadioGroupInput = connect(mapStateToProps)(RadioGroupInput)
export { connectedRadioGroupInput as RadioGroupInput }

const MultiSelectInput = (props) => {
  const {
    options = [],
    inputRef,
    name,
    defaultValue = {},
    value,
    onChange,
    id,
    label,
    following_question,
    ...rest
  } = props

  const [displayValue, setDisplayValue] = useState(value || defaultValue)

  // Allow us to reset the field when ID changes
  useEffect(() => {
    setDisplayValue(value || defaultValue)
  }, [id, value])

  const handleChangeLocal = (e, field_name) => {
    let newValue = { ...displayValue, [field_name]: e.target.checked }
    setDisplayValue(newValue)
    onChange(newValue, id)
  }

  return (
    <S_MultiSelect {...rest} id={id}>
      {options.map((opt) => (
        <div className={`checkbox-btn`} key={opt.field_name}>
          <input
            defaultChecked={String(displayValue[opt.field_name]) === 'true'}
            id={`${id}-${opt.field_name}`}
            name={`${name}-${opt.field_name}`}
            onChange={(e) => handleChangeLocal(e, opt.field_name)}
            ref={inputRef}
            type="checkbox"
          />
          <label htmlFor={`${id}-${opt.field_name}`}>{opt.label}</label>
        </div>
      ))}
    </S_MultiSelect>
  )
}

const connectedMultiSelectInput = connect(mapStateToProps)(MultiSelectInput)
export { connectedMultiSelectInput as MultiSelectInput }
