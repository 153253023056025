import React from 'react'
import styled from 'styled-components'
import { Field } from 'formik'
import { selectInputStyles } from '../../_constants/styles'

const StyledSelect = styled.div`
  ${selectInputStyles}
  select {
    ::-webkit-outer-spin-button,
    ::-webkit-inner-spin-button {
      /* display: none; <- Crashes Chrome on hover */
      -webkit-appearance: none;
      margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
    }
  }
`

const SelectField = ({ field, formikName, handleChange, handleBlur, invalid }) => (
  <Field
    className={invalid ? 'invalid-input' : ''}
    name={formikName}
    render={({ field: formikField }) => {
      return (
        <StyledSelect>
          <select
            className={`react-select`}
            name={formikField.name}
            onBlur={(e) => {
              handleBlur(formikName, field.name, e.currentTarget.value)
            }}
            onChange={(option) => {
              handleChange(formikName, option.currentTarget.value)
            }}
            value={formikField.value}
          >
            {[
              <option
                key={-1}
                label={`${
                  field.options.find((o) => o.value === '') ? field.options.find((o) => o.value === '').label : ''
                }`}
                value=""
              >
                {field.placeholder || field.label || 'Select'}
              </option>,
            ].concat(
              // Have to include label as attribute and inner text for iOS
              field.options
                .filter((o) => o.value !== '')
                .map((opt, i) => (
                  <option key={i} label={opt.label} value={opt.value}>
                    {opt.label}
                  </option>
                ))
            )}
          </select>
        </StyledSelect>
      )
    }}
    validate={(value) => {
      let errorMessage
      if (field.required && !value) {
        errorMessage = `Field is required.`
      }
      return errorMessage
    }}
  />
)

export default SelectField
