import { jsx as _jsx } from "react/jsx-runtime";
import { forwardRef } from 'react';
import { MultiSelect as MantineMultiSelect } from '@mantine/core';
import { createLabelText, getTextFromReactNode, selectScrollAreaProps } from '../_internal';
import { MultiSelectValueRenderer } from './multi-select-value-renderer';
const ForwardedMultiSelect = forwardRef(({ hideLabel = false, label, scrollAreaProps = {}, ...props }, ref) => {
    const labelText = createLabelText(label, hideLabel, props.type);
    return (_jsx(MantineMultiSelect, { ...props, clearButtonProps: {
            'aria-hidden': undefined,
            'aria-label': `Clear ${getTextFromReactNode(label)}`,
            ...props.clearButtonProps,
        }, ref: ref, ...labelText, scrollAreaProps: { ...selectScrollAreaProps, ...scrollAreaProps } }));
});
ForwardedMultiSelect.displayName = 'MultiSelect';
export const MultiSelect = Object.assign(ForwardedMultiSelect, {
    ValueRenderer: MultiSelectValueRenderer,
});
