import React, { useState } from 'react'
import { ClipLoader } from 'react-spinners'
import { FaChevronLeft, FaChevronRight, FaCaretDown } from 'react-icons/fa'
import { AiFillFileExcel, AiOutlineFilePdf } from 'react-icons/ai'
import { IconContext } from 'react-icons'

// Utils
import { colors } from '../../_constants/colors'

// Components
import { S_PaginationControls } from './PaginationControls.styles'
import ToolTip from '../Tooltip/Tooltip'

//Helpers
import { EXPORT_TABLE_FORMATS } from '../../_helpers/export-table/exportTable.helper'

const minLabel = (totalResults, pageSize, pageNum) => {
  return totalResults > 0 ? pageSize * (pageNum - 1) + 1 : 0
}
const maxLabel = (totalResults, pageSize, pageNum) => {
  return pageNum * pageSize > totalResults ? totalResults : pageNum * pageSize
}

export const PaginationControls = ({
  disabled = false,
  isLoadingDownload = false,
  onDownloadClick = () => {},
  onNextPage = () => {},
  onPageSelected = () => {},
  onPageSizeChange = () => {},
  onPrevPage = () => {},
  pageNum = 1, // First page is 1, just like API
  pageSize = 10,
  pageSizePostfix,
  pageSizes,
  showDownload = false,
  totalResults = 0,
}) => {
  const [alreadyNavigatedToLastPage, setAlreadyNavigatedToLastPage] = useState(false)
  const showPageLabels = totalResults > 0
  const pageMinLabel = minLabel(totalResults, pageSize, pageNum)
  const pageMaxLabel = maxLabel(totalResults, pageSize, pageNum)
  const prevEnabled = pageNum > 1
  const nextEnabled = totalResults > pageNum * pageSize

  let pageLabels = []
  let totalPages = totalResults / pageSize
  for (let i = 0; i < totalPages; i++) {
    pageLabels.push(i + 1)
  }

  const showSingleItem = pageSize === 1
  const labelClasses = `pagination-label ${showSingleItem ? 'for-single' : ''}`

  return (
    <>
      <S_PaginationControls className={disabled ? 'disabled' : ''}>
        <div className={'nav-controls'}>
          {/* Prev */}
          <IconContext.Provider value={{ color: colors.DEFAULT_SECONDARY, size: '25px' }}>
            <FaChevronLeft className="prev" onClick={prevEnabled ? onPrevPage : () => {}} />
          </IconContext.Provider>

          <div className={labelClasses}>
            {/* Make a distinction for page sizes of 1 */}
            {pageSize === 1 ? (
              showPageLabels ? (
                <>
                  {pageMinLabel}
                  <span className="spacer">/</span>
                  {totalResults}
                </>
              ) : (
                `0`
              )
            ) : showPageLabels ? (
              <div className={`select-container`}>
                <div className={`page-select`}>
                  <div className={`current-page-label`}>
                    {pageMinLabel} - {pageMaxLabel}
                    &nbsp;of&nbsp;
                    {totalResults}
                  </div>
                  <select
                    className={`page-select-option`}
                    disabled={disabled}
                    onChange={(e) => onPageSelected(Number(e.target.value))}
                    value={pageNum}
                  >
                    {pageLabels.map((label) => {
                      return (
                        <option
                          className={`page-number-lnk ${label == pageNum ? 'current-page' : ''}`}
                          key={`page-${label}`}
                          value={label}
                        >
                          {minLabel(totalResults, pageSize, label)} - {maxLabel(totalResults, pageSize, label)}
                          &nbsp;of&nbsp;
                          {totalResults}
                        </option>
                      )
                    })}
                  </select>
                </div>
                <div className={`page-size-select`}>
                  <div className={`size-select-label`}>
                    <div className={'caret-container'}>
                      <IconContext.Provider value={{ color: '#ccc', size: '15px' }}>
                        <FaCaretDown />
                      </IconContext.Provider>
                    </div>
                  </div>
                  <select
                    className={`page-size-select-option`}
                    disabled={disabled}
                    onChange={(e) => onPageSizeChange(e.target.value)}
                    value={pageSize}
                  >
                    {pageSizes.map((pageSize) => {
                      return (
                        <option key={pageSize} value={pageSize}>
                          {pageSize} {pageSizePostfix}
                        </option>
                      )
                    })}
                  </select>
                </div>
              </div>
            ) : (
              `0 results`
            )}
          </div>

          <IconContext.Provider value={{ color: colors.DEFAULT_SECONDARY, size: '25px' }}>
            {/* Next */}
            <FaChevronRight className="next" onClick={nextEnabled ? onNextPage : () => {}} />
          </IconContext.Provider>
        </div>
        <Downloader
          isLoadingDownload={isLoadingDownload}
          onDownloadClick={onDownloadClick}
          showDownload={showDownload}
        />
      </S_PaginationControls>
    </>
  )
}

export const Downloader = ({ onDownloadClick, showDownload, isLoadingDownload }) => {
  if (isLoadingDownload) {
    return (
      <ClipLoader
        color={'#000'}
        cssOverride={{
          display: `block`,
          margin: `5px`,
          animationDuration: `1.5s !important`,
        }}
        size={20}
      />
    )
  }

  return (
    showDownload && (
      <div className="download">
        <ToolTip content={'Download Spreadsheet'}>
          <div className="download-icon" onClick={() => onDownloadClick(EXPORT_TABLE_FORMATS.XLSX)}>
            <IconContext.Provider value={{ size: '25px', className: 'download-icon' }}>
              <AiFillFileExcel />
            </IconContext.Provider>
          </div>
        </ToolTip>

        <ToolTip content={'Download as PDF'}>
          <div className="download-icon" onClick={() => onDownloadClick(EXPORT_TABLE_FORMATS.PDF)}>
            <IconContext.Provider value={{ size: '25px', className: 'pdf-icon' }}>
              <AiOutlineFilePdf />
            </IconContext.Provider>
          </div>
        </ToolTip>
      </div>
    )
  )
}
