import { jsx as _jsx } from "react/jsx-runtime";
import { useState } from 'react';
import { Pagination as XenPagination } from 'xen-ui';
function chunk(array, size) {
    if (!array.length) {
        return [];
    }
    const head = array.slice(0, size);
    const tail = array.slice(size);
    return [head, ...chunk(tail, size)];
}
export const usePagination = ({ initialPage = 1, pageSize = 10, items = [], }) => {
    const [activePage, setActivePage] = useState(initialPage);
    const chunks = chunk(items, pageSize);
    const totalPages = chunks.length;
    const currentItems = chunks[activePage - 1] || [];
    const Pagination = () => {
        return _jsx(XenPagination, { onChange: setActivePage, total: totalPages, value: activePage });
    };
    return {
        activePage,
        currentItems,
        totalPages,
        Pagination,
    };
};
