import { CheckIcon, CloseButton, Combobox, useCombobox } from '@mantine/core';
const isComboboxItemGroup = (item) => {
    return Object.prototype.hasOwnProperty.call(item, 'group');
};
const isComboboxDataGroups = (data) => {
    return data.every(isComboboxItemGroup);
};
const isComboboxDataItems = (data) => {
    return !data.every(isComboboxItemGroup);
};
export { CheckIcon, CloseButton, Combobox, isComboboxItemGroup, isComboboxDataGroups, isComboboxDataItems, useCombobox };
