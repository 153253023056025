import request from './axios_service'
import config from 'config'

export const getNewUnderwriteStatus = (dealId) => {
  return request({
    baseURL: config.underwriteApiUrl,
    url: `underwrite/api/v1/deals/${dealId}/underwriting/`,
    method: 'GET',
  })
}

export const updateNewUnderwriteStatus = (dealId, status, note = '') => {
  return request({
    baseURL: config.underwriteApiUrl,
    url: `underwrite/api/v1/deals/${dealId}/underwriting/${status}`,
    method: 'PUT',
    data: {
      note: note,
    },
  })
}

export const updateUnderwritingChecklistItem = ({ dealId, questionId, value }) => {
  return request({
    baseURL: config.underwriteApiUrl,
    url: `underwrite/api/v1/deals/${dealId}/checklist_questions/${questionId}`,
    method: 'PUT',
    data: {
      value: value,
    },
  })
}

export const getUnderwritingChecklist = (dealId) => {
  return request({
    baseURL: config.underwriteApiUrl,
    url: `underwrite/api/v1/deals/${dealId}/checklist_questions`,
    method: 'GET',
  })
}

export const getUnderwritingChecklistAnswers = (dealId) => {
  return request({
    baseURL: config.underwriteApiUrl,
    url: `underwrite/api/v1/deals/${dealId}/checklist_question_answers`,
    method: 'GET',
  })
}

export const createDeal = (payload, headers) => {
  // This method is erroneously named—while it does create a deal, it's actually
  // used by the sign-up form and not the deal creation form.
  return request({
    baseURL: config.underwriteApiUrl,
    url: 'underwrite/api/v1/signup',
    method: 'POST',
    data: payload,
    headers: headers,
  })
}

export const resendRegistrationEmail = (deal_id, email = null) => {
  return request({
    baseURL: config.underwriteApiUrl,
    url: `underwrite/api/v1/deals/${deal_id}/notifications`,
    method: 'POST',
    data: {
      notification_type: 'resend_confirmation_email',
      email: email,
    },
  })
}

export const sendEmailNotification = (notification_type, deal_id, email) => {
  return request({
    baseURL: config.underwriteApiUrl,
    url: `underwrite/api/v1/deals/${deal_id}/notifications`,
    method: 'POST',
    data: {
      notification_type,
      email,
    },
  })
}

export const prospectUrl = (identifier) => {
  return request({
    baseURL: config.underwriteApiUrl,
    url: `/underwrite/api/v1/users/prospect_url?identifier=${identifier}`,
    data: null,
    method: 'GET',
  })
}
