import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Divider, Flex, Group, Heading, Loader, Skeleton, Stack, Text } from 'xen-ui';
import { useGetInvoice, useIsInvoiceUpdating } from 'xen/api/use-invoice';
import { Card, ErrorText } from 'xen/components';
import { DeleteInvoiceButton, InvoiceStateBadge, InvoiceUpdateForm, InvoiceVerificationSection } from '../../components';
import { InvoiceFileManager } from '../invoice-file-manager';
export const InvoiceCard = ({ invoice }) => {
    const isInvoiceUpdating = useIsInvoiceUpdating(invoice.id);
    // Should eventually move to invoice-verifications-related component when each invoice has a expanded/collapsed view
    const { data: serializedInvoice, isLoading: isSerializedInvoiceLoading, isError: isSerializedInvoiceError, } = useGetInvoice(invoice.id);
    if (isSerializedInvoiceLoading)
        return _jsx(InvoiceCardSkeleton, {});
    if (isSerializedInvoiceError || !serializedInvoice)
        return _jsx(ErrorText, { message: "Could not fetch invoice" });
    return (_jsxs(Card, { "data-testid": `invoice-card-${invoice.id}`, children: [_jsxs(Group, { bg: "gray.0", justify: "space-between", px: "md", py: "xs", children: [_jsxs(Group, { children: [_jsxs(Text, { component: "span", fw: "bold", size: "lg", children: ["Invoice ", invoice.invoiceNumber] }), _jsx(InvoiceStateBadge, { invoice: invoice }), isInvoiceUpdating && _jsx(Loader, { size: "sm" })] }), _jsx(DeleteInvoiceButton, { color: "red", invoice: invoice, size: "xs", variant: "outline" })] }), _jsx(Divider, {}), _jsxs(Flex, { direction: { base: 'column', sm: 'row' }, children: [_jsxs(Stack, { p: "md", w: { base: '100%', sm: '30%' }, children: [_jsx(Heading, { level: 3, size: "h5", children: "Details" }), _jsx(InvoiceUpdateForm, { invoice: invoice })] }), _jsx(Divider, { hiddenFrom: "sm", orientation: "horizontal" }), _jsx(Divider, { orientation: "vertical", visibleFrom: "sm" }), _jsxs(Stack, { gap: "xl", p: "md", w: "100%", children: [_jsx(InvoiceVerificationSection, { serializedInvoice: serializedInvoice }), _jsxs(Stack, { children: [_jsx(Heading, { level: 3, size: "h5", children: "Documents" }), _jsx(InvoiceFileManager, { invoice: serializedInvoice })] })] })] })] }));
};
const InvoiceCardSkeleton = () => {
    return _jsx(Skeleton, { height: "30rem" });
};
InvoiceCard.Skeleton = InvoiceCardSkeleton;
