import React from 'react'
import styled from 'styled-components'
import { ClipLoader } from 'react-spinners'

export const S_SectionLoader = styled.div`
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const SectionLoader = ({ className = '', size = 60 }) => {
  return (
    <S_SectionLoader className={className}>
      <ClipLoader
        color={'#000'}
        cssOverride={{
          display: `block`,
          margin: `0 auto`,
          animationDuration: `1.5s !important`,
        }}
        size={size}
      />
    </S_SectionLoader>
  )
}
