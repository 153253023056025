import { jsx as _jsx } from "react/jsx-runtime";
import { Table } from '@mantine/core';
// Use xen scroll area instead of mantine scroll area, since ours has useful defaults and enhancements.
import { ScrollArea as XenScrollArea } from '../scroll-area';
export const BasicTable = (props) => {
    return _jsx(Table, { ...props });
};
const Thead = (props) => {
    return _jsx(Table.Thead, { ...props });
};
BasicTable.Thead = Thead;
const Tbody = (props) => {
    return _jsx(Table.Tbody, { ...props });
};
BasicTable.Tbody = Tbody;
const Tfoot = (props) => {
    return _jsx(Table.Tfoot, { ...props });
};
BasicTable.Tfoot = Tfoot;
const Tr = (props) => {
    return _jsx(Table.Tr, { ...props });
};
BasicTable.Tr = Tr;
const Th = (props) => {
    return _jsx(Table.Th, { ...props });
};
BasicTable.Th = Th;
const Td = (props) => {
    return _jsx(Table.Td, { ...props });
};
BasicTable.Td = Td;
const Caption = (props) => {
    return _jsx(Table.Caption, { ...props });
};
BasicTable.Caption = Caption;
const ScrollArea = (props) => {
    return _jsx(XenScrollArea, { ...props });
};
BasicTable.ScrollArea = ScrollArea;
export {};
