import { useQuery } from '@tanstack/react-query';
import { request } from 'xen/api';
import { useAuthentication } from 'xen/hooks';
import { transformUserResponse } from './transform';
async function getCurrentUser({ queryParams }) {
    try {
        // We will eventually replace this with a new axios instance.
        const response = await request({
            url: '/login/logged_in_user_info',
            method: 'GET',
            params: queryParams,
        });
        const data = transformUserResponse(response);
        return data;
    }
    catch (_error) {
        throw Error('Could not fetch current user');
    }
}
export const useGetCurrentUser = () => {
    const { sessionToken } = useAuthentication();
    return useQuery({
        queryKey: ['current-user'],
        queryFn: () => getCurrentUser({ queryParams: { sessionToken } }),
        enabled: !!sessionToken,
    });
};
