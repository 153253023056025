import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { formatCase } from 'xen/helpers/format/case';
export const ApplicationFormattedStageStatus = ({ stageStatus, bolded = true }) => {
    const stage = stageStatus.split('_')[0];
    const status = stageStatus.substring(stageStatus.indexOf('_') + 1);
    if (!stage || !status) {
        return formatCase.sentence(stageStatus);
    }
    if (bolded) {
        return (_jsxs(_Fragment, { children: [_jsx("b", { children: formatCase.sentence(stage) }), ": ", formatCase.sentence(status)] }));
    }
    return (_jsxs(_Fragment, { children: [formatCase.sentence(stage), ": ", formatCase.sentence(status)] }));
};
