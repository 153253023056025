import { ConfigProxy } from '../_helpers/util'
export const menuConfig = ConfigProxy(
  {
    finvoice: [
      {
        label: 'Privacy Policy',
        link: 'https://www.xenplatforms.com/privacy1',
      },
      {
        label: 'Terms of Use',
        link: 'https://www.xenplatforms.com/terms-of-use-1',
      },
    ],
    demo: [
      {
        label: 'Privacy Policy',
        link: 'https://www.xenplatforms.com/privacy1',
      },
      {
        label: 'Terms of Use',
        link: 'https://www.xenplatforms.com/terms-of-use-1',
      },
    ],
    capitalnow: [
      {
        type: 'phone',
        label: '403-617-2075 🇨🇦',
        link: 'tel:4036172075',
      },
      {
        type: 'phone',
        label: '512-858-8806 🇺🇸',
        link: 'tel:5128588806',
      },
    ],
    sevenoaks: [
      {
        label: 'For all your Working Capital & Cashflow solutions, please visit our website at',
        link: 'http://www.sevenoakscapital.com/',
      },
      {
        label: 'www.sevenoaks.com',
        link: 'http://www.sevenoakscapital.com/',
      },
    ],
    boldbusinesscapital: [],
    twelvefive: [],
    sallyportcf: [
      {
        label: 'About Sallyport',
        link: 'http://www.sallyportcf.com/company/',
      },
      {
        label: 'FAQs',
        link: 'http://www.sallyportcf.com/faqs/',
      },
    ],
    portercapital: [
      {
        label: 'About',
        link: 'https://www.portercap.net/about',
      },
      {
        label: 'Website',
        link: 'https://www.portercap.net',
      },
      {
        label: 'News',
        link: 'https://www.portercap.net/blog',
      },
    ],
    marcocapital: [
      {
        label: 'Grow With Marco',
        link: 'https://www.marcocap.com/customer-log-in',
      },
      {
        label: 'About',
        link: 'https://www.marcocap.com/about-feed',
      },
      {
        label: 'Contact',
        link: 'https://www.marcocap.com/contact-feed',
      },
      {
        label: 'Jobs',
        link: 'https://angel.co/company/marco-capital/jobs',
      },
    ],
    marcofi: [
      {
        label: 'Grow With Marco',
        link: 'https://www.marcocap.com/customer-log-in',
      },
      {
        label: 'About',
        link: 'https://www.marcocap.com/about-feed',
      },
      {
        label: 'Contact',
        link: 'https://www.marcocap.com/contact-feed',
      },
      {
        label: 'Jobs',
        link: 'https://angel.co/company/marco-capital/jobs',
      },
    ],
    newcenturyfinancial: [
      {
        label: 'About Factoring',
        link: 'http://www.newcenturyfinancial.com/about-factoring/what-is-factoring/',
      },
      {
        label: 'Industries We Serve',
        link: 'http://www.newcenturyfinancial.com/industries-we-serve/',
      },
    ],
    scfactoringsolution: [],
    acsfactors: [],
    chartercapitalusa: [
      {
        label: 'Privacy Policy',
        link: 'https://www.xenplatforms.com/privacy1',
      },
      {
        label: 'EULA',
        link: 'https://www.xenplatforms.com/terms-of-use-1',
      },
    ],
    ccbg: [],
    hedayacapital: [
      {
        label: 'About',
        link: 'https://www.hedayacapital.com/',
      },
      {
        label: 'Privacy Policy',
        link: 'https://hedayacapital.finvoice.co/privacy',
      },
      {
        label: 'End User License Agreement',
        link: 'https://hedayacapital.finvoice.co/eula',
      },
      {
        label: 'Contact Us',
        link: 'tel:5128588806',
      },
    ],
    republicbc: [
      {
        label: 'About Republic Business Credit',
        link: 'http://www.republicbc.com/about/',
      },
      {
        label: 'Services',
        link: 'http://republicbc.com/products/',
      },
    ],
    newbridgeglobal: [],
    axosbank: [
      {
        label: 'About',
        link: 'https://www.axosbank.com/en/Business/Commercial-Lending/Factoring',
      },
      {
        label: 'Privacy',
        link: 'https://www.axosbank.com/Legal/Privacy-and-Security',
      },
    ],
    factoringdemo: [
      {
        label: 'Privacy Policy',
        link: 'https://www.xenplatforms.com/privacy1',
      },
      {
        label: 'Terms of Use',
        link: 'https://www.xenplatforms.com/terms-of-use-1',
      },
    ],
    abldemo: [
      {
        label: 'Privacy Policy',
        link: 'https://www.xenplatforms.com/privacy1',
      },
      {
        label: 'Terms of Use',
        link: 'https://www.xenplatforms.com/terms-of-use-1',
      },
    ],
    prodemo: [
      {
        label: 'Privacy Policy',
        link: 'https://www.xenplatforms.com/privacy1',
      },
      {
        label: 'Terms of Use',
        link: 'https://www.xenplatforms.com/terms-of-use-1',
      },
    ],
    ctrlpay: [
      {
        type: 'phone',
        label: '1-800-375-5056',
        link: 'tel:+18003755056',
      },
    ],
    breakoutfinance: [
      {
        type: 'phone',
        label: '888-318-3534',
        link: 'tel:+8883183534',
      },
    ],
    fastarfunding: [],
    velocityfi: [
      {
        label: 'About',
        link: 'https://velocityfi.com/about/',
      },
      {
        label: 'FAQ',
        link: 'https://velocityfi.com/factoring/#faqs',
      },
      {
        type: 'phone',
        label: '432-247-1888',
        link: 'tel: 4322471888',
      },
    ],
    capitalplus: [
      {
        label: 'About',
        link: 'https://capitalplus.com/who-we-are/',
      },
      {
        label: 'Contact us',
        link: 'https://capitalplus.com/who-we-are/contact-us/',
      },
    ],
    capitalplusfinancial: [
      {
        label: 'About',
        link: 'https://capitalplus.com/who-we-are/',
      },
      {
        label: 'Contact us',
        link: 'https://capitalplus.com/who-we-are/contact-us/',
      },
    ],
    changecapital: [
      {
        label: 'Contact us',
        link: 'https://change.capital/contact-us/',
      },
    ],
    interportcapital: [
      [
        {
          label: 'About',
          link: 'https://interportcap.com/who-we-are/',
        },
        {
          label: 'Contact',
          link: 'https://interportcap.com/contact-us/',
        },
        {
          label: 'How it Works',
          link: 'https://interportcap.com/how-it-works/',
        },
        {
          label: 'FAQ',
          link: 'https://interportcap.com/faq/',
        },
        {
          label: 'Privacy Policy',
          link: 'https://interportcap.com/privacy-policy/',
        },
      ],
    ],
  },
  []
)
