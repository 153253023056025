import React, { Suspense, lazy } from 'react'
import { SectionLoader } from '../_components/SectionLoader'
import { Route, Switch } from 'react-router-dom'
import { SignUpPageOldUnderwrite, SignIn, ForgotPassword, ResetPassword, SignUp } from '../AuthPage'
import ROUTES from '../_constants/routes'
import { BankLoadingTrackback } from '../_components/BankLoadingTrackback'
import { ProspectPage } from '../ProspectPage'
import { PrivateProspectRoute } from '../_components/routes/PrivateProspectRoute'
import { PrivateFactoringClientRoute } from '../_components/routes/PrivateFactoringClientRoute'
import { ABLClientRoutes } from '../_routes/ABLClientRoutes'
import { ABLLenderRoutes } from '../_routes/ABLLenderRoutes'
import { FactoringLenderRoutes } from '../_routes/FactoringLenderRoutes'
import { EsigningLenderRoutes } from '../_routes/EsigningLenderRoutes'
import { OperateLenderRoutes } from '../_routes/OperateLenderRoutes'
import { UnderwriteLenderRoutes } from '../_routes/UnderwriteLenderRoutes'
import { LenderRoutes } from '../_routes/LenderRoutes'
import { AdminRoutes } from '../_routes/FVAdminRoutes'
import { UserSettingsRoutes } from '../_routes/UserSettingsRoutes'
import { PageNotFound } from '../PageNotFound'
import { useSelector } from 'react-redux'
import { isFeatureEnabled } from '../_helpers/flags'
import { UserAlerts } from '../_views/LenderViews/UserAlerts'
import { XenRoutes } from 'xen/routes'
import { Redirect } from 'xen/components'
import { useFeatureFlags } from 'xen/hooks'

//eagerly lazy loaded pages, loads components onload but without blocking interactivity
const pageComponents = {
  UploadInvoicesPage: import('../UploadInvoicesPage/index.js'),
  AccountingPlatformsPage: import('../AccountingPlatformsPage/index.js'),
  YourInvoicesPage: import('../YourInvoicesPage/index.js'),
  ConfirmFormPage: import('../ConfirmFormPage/index.js'),
  SubmittedPage: import('../SubmittedPage/index.js'),
  ClientHomePage: import('../ClientHomePage/index.js'),
  ClientInvoicePage: import('../_views/factoring/borrower/ClientInvoiceSchedule/index.js'),
  BorrowingBaseCertificatePage: import('../BorrowingBaseCertificatePage/index.js'),
  QuickbooksLoadingPage: import('../QuickbooksLoadingPage/index.js'),
  BbcDocUploadPage: import('../BbcDocUploadPage/index.js'),
  ESignPage: import('../_views/common/ESignPage/index'),
  EmailConfirmation: import('../_views/common/EmailConfirmation/index'),
  InvoiceUpload: import('../_views/factoring/common/CreateInvoicesRedesign/InvoiceUpload'),
}

// Pages
const UploadInvoicesPage = lazy(() => pageComponents['UploadInvoicesPage'])
const InvoiceUploadPage = lazy(() => pageComponents['InvoiceUpload'])
const AccountingPlatformsPage = lazy(() => pageComponents['AccountingPlatformsPage'])
const YourInvoicesPage = lazy(() => pageComponents['YourInvoicesPage'])
const ConfirmFormPage = lazy(() => pageComponents['ConfirmFormPage'])
const SubmittedPage = lazy(() => pageComponents['SubmittedPage'])
const ClientHomePage = lazy(() => pageComponents['ClientHomePage'])
const ClientInvoicePage = lazy(() => pageComponents['ClientInvoicePage'])
const QuickbooksLoadingPage = lazy(() => pageComponents['QuickbooksLoadingPage'])
const ESignPage = lazy(() => pageComponents['ESignPage'])
const EmailConfirmationPage = lazy(() => pageComponents['EmailConfirmation'])

export const AppRoutes = () => {
  const isAdminSite = window.location.host.includes('.admin.') || window.location.host.includes('.adminstaging.')
  const authentication = useSelector((state) => state.authentication)
  const user = useSelector((state) => state.user)
  const featureFlags = useSelector((state) => state.featureFlags)
  const loggedIn = authentication?.loggedIn
  const accounting_integration = isFeatureEnabled('accounting_integration', featureFlags)
  const newSignUpFlow = isFeatureEnabled('new_underwriting_sign_up_flow', featureFlags)
  const { xenAuthPages } = useFeatureFlags()

  const getRouteAdminOrSignUpFeature = () => {
    if (isAdminSite) {
      return <SignIn />
    } else if (newSignUpFlow) {
      return <SignUp />
    } else {
      return <SignUpPageOldUnderwrite />
    }
  }

  return (
    <Suspense fallback={<SectionLoader />}>
      <div className={`page-content`}>
        <Switch>
          {/* Xen Routes */}
          {XenRoutes()}

          {/**
           * Base Routes
           * Fallback to XenRoutes > HomeRoutes > root when `xenAuthPages` is enabled
           */}
          {!xenAuthPages && <Route exact path={`/`} render={() => getRouteAdminOrSignUpFeature()} />}

          {/* Auth Routes */}
          <Route
            path={ROUTES.sign_in}
            render={() => (xenAuthPages ? <Redirect keepSearchParams to="/sign-in" /> : <SignIn />)}
          />
          <Route
            path={ROUTES.sign_up}
            render={() =>
              xenAuthPages ? (
                <Redirect keepSearchParams to="/sign-up" />
              ) : newSignUpFlow ? (
                <SignUp />
              ) : (
                <SignUpPageOldUnderwrite />
              )
            }
          />
          <Route
            path={ROUTES.forgot_password}
            render={() => (xenAuthPages ? <Redirect to="/forgot-password" /> : <ForgotPassword />)}
          />
          <Route
            path={ROUTES.reset_password}
            render={() => (xenAuthPages ? <Redirect to="/reset-password" /> : <ResetPassword />)}
          />
          <Route path={ROUTES.email_confirmation} render={() => <EmailConfirmationPage />} />

          {/* Redirect Routes */}
          <Route component={BankLoadingTrackback} path={ROUTES.bank_loading} />

          {/* E-Signing Page */}
          <Route component={ESignPage} path={ROUTES.e_signing + '/:global_id'} />

          <Route component={UserAlerts} path={ROUTES.user_alerts} />
          {/* Prospect Routes */}
          <Route component={ProspectPage} path={ROUTES.prospect} />
          <PrivateProspectRoute component={UploadInvoicesPage} path={ROUTES.upload_invoices} />
          {accounting_integration ? (
            <PrivateProspectRoute component={AccountingPlatformsPage} path={ROUTES.accounting_platforms} />
          ) : null}
          <PrivateProspectRoute component={YourInvoicesPage} path={ROUTES.your_invoices} />
          <PrivateProspectRoute component={QuickbooksLoadingPage} path={ROUTES.quickbooks_loading} />
          <PrivateProspectRoute component={ConfirmFormPage} path={ROUTES.confirm_form} />
          <PrivateProspectRoute component={SubmittedPage} path={ROUTES.submitted} />

          {/* Client Routes */}
          <PrivateFactoringClientRoute component={ClientHomePage} path={ROUTES.client_home} />
          <PrivateFactoringClientRoute
            component={InvoiceUploadPage}
            path={`${ROUTES.client_invoice_submission}/:id/upload`}
          />
          <PrivateFactoringClientRoute
            component={ClientInvoicePage}
            path={ROUTES.client_invoice_submission + '/:schedule_id'}
          />

          {/* ABL Client Routes */}
          {ABLClientRoutes({ loggedIn, user, featureFlags })}

          {/* ABL Lender Routes */}
          {ABLLenderRoutes({ loggedIn, user, featureFlags })}

          {/* Factoring Lender Routes */}
          {FactoringLenderRoutes({ loggedIn, user, featureFlags })}

          {/* Esigning Lender Routes */}
          {EsigningLenderRoutes({ loggedIn, user, featureFlags })}

          {/* Operate Lender Routes */}
          {OperateLenderRoutes({ loggedIn, user, featureFlags })}

          {/* Underwrite Lender Routes */}
          {UnderwriteLenderRoutes({ loggedIn, user, featureFlags })}

          {/* Lender Routes */}
          {LenderRoutes({ loggedIn, user, featureFlags })}

          {/* FV Admin Panel */}
          {AdminRoutes({ loggedIn, user })}

          {UserSettingsRoutes({ loggedIn, user })}

          {/* Fallback Route */}
          <Route component={PageNotFound} />
        </Switch>
      </div>
    </Suspense>
  )
}
