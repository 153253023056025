import { jsx as _jsx } from "react/jsx-runtime";
import { z } from 'zod';
import { Select } from 'xen-ui';
import { useGetDebtorsSearch } from 'xen/api/use-debtor';
import { FormSelect } from '../select';
export const FormDebtorSelect = ({ label = DEFAULT_LABEL, nothingFoundMessage = DEFAULT_NOTHING_FOUND_MESSAGE, error, allowDeselect, required, ...props }) => {
    const { data: debtors = [], isError, isSuccess } = useGetDebtorsSearch({ debtorName: '' });
    const dataError = getDataError(isError, isSuccess, debtors.length);
    const selectDebtorList = debtors.map(createSelectData);
    return (_jsx(FormSelect, { allowDeselect: allowDeselect ?? !required, data: selectDebtorList, error: dataError || error, label: label, nothingFoundMessage: nothingFoundMessage, required: required, searchable: true, ...props }));
};
const debtorSelectSchema = z.string().min(1, { message: 'Debtor is required' });
FormDebtorSelect.schema = debtorSelectSchema;
export const DebtorSelect = ({ label = DEFAULT_LABEL, nothingFoundMessage = DEFAULT_NOTHING_FOUND_MESSAGE, error, allowDeselect, required, ...props }) => {
    const { data: debtors = [], isError, isSuccess } = useGetDebtorsSearch({ debtorName: '' });
    const dataError = getDataError(isError, isSuccess, debtors.length);
    const selectDebtorList = debtors.map(createSelectData);
    return (_jsx(Select, { allowDeselect: allowDeselect ?? !required, data: selectDebtorList, error: dataError || error, label: label, nothingFoundMessage: nothingFoundMessage, required: required, searchable: true, ...props }));
};
// Private -------------------------------------------------------------------
const DEFAULT_LABEL = 'Debtor';
const DEFAULT_NOTHING_FOUND_MESSAGE = 'No debtors found...';
const DEFAULT_ERROR_MESSAGE = 'Could not fetch debtors';
const DEFAULT_NO_DATA_MESSAGE = 'No debtors exist';
const createSelectData = (debtor) => {
    return {
        value: String(debtor.id),
        label: debtor.debtorName,
    };
};
const getDataError = (isError, isSuccess, dataLength) => {
    if (isError)
        return DEFAULT_ERROR_MESSAGE;
    if (isSuccess && dataLength === 0)
        return DEFAULT_NO_DATA_MESSAGE;
    return '';
};
