/**
 * Always require a label. Set it via aria-label if hideLabel is true.
 * Use in components that render an `input`, which needs a label for proper a11y.
 * Extend component props type with the `LabelProps` type.
 */
export const createLabelText = (label, hideLabel, type) => {
    const useAriaLabel = (hideLabel || type === 'hidden') && typeof label === 'string';
    const labelText = useAriaLabel ? { 'aria-label': label, label: undefined } : { label };
    return labelText;
};
/** Default scroll area props for select components */
export const selectScrollAreaProps = {
    mah: 220,
    scrollbarSize: '0.75rem',
    type: 'auto',
};
export const getTextFromReactNode = (node) => {
    if (typeof node === 'string') {
        return node;
    }
    if (Array.isArray(node)) {
        return node.map(getTextFromReactNode).join('');
    }
    if (typeof node === 'object' && node !== null && 'props' in node) {
        return getTextFromReactNode(node.props.children);
    }
    return '';
};
