import React from 'react'
import { Helmet } from 'react-helmet'

// Components
import { PageContents } from '../PageContents'
import { ContentColumn } from '../../../_components/layout/ContentColumn'
import { SectionLoader } from '../../../_components/SectionLoader'
import { COMPANY_NAME } from 'xen/constants'

export const FVAdminStandardPage = ({
  title,
  showSideNav = true,
  isLoading = false,
  children,
  setTenantSetter = () => {},
}) => (
  <PageContents setTenantSetter={setTenantSetter} showSideNav={showSideNav}>
    <Helmet>
      <title>
        {title} • {COMPANY_NAME}
      </title>
    </Helmet>
    <ContentColumn>{isLoading ? <SectionLoader /> : children}</ContentColumn>
  </PageContents>
)
