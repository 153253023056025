import { jsx as _jsx } from "react/jsx-runtime";
import { getFontSize, rem } from '@mantine/core';
// Find all available icons at https://tabler.io/icons
import { TbAddressBook, TbAlertTriangle, TbArchive, TbArchiveFilled, TbArchiveOff, TbArrowsSort, TbBell, TbBuilding, TbBuildingBank, TbCalendar, TbCheck, TbCheckbox, TbChecklist, TbChevronDown, TbChevronLeft, TbChevronRight, TbChevronUp, TbCircleMinus, TbCirclePlus, TbClipboardList, TbClock, TbCloudUpload, TbCopy, TbCreditCard, TbCurrencyDollar, TbDownload, TbDownloadOff, TbEdit, TbExclamationMark, TbExternalLink, TbEye, TbFile, TbFileCheck, TbFilePencil, TbFiles, TbFileText, TbFileX, TbHelpCircleFilled, TbHome, TbHourglass, TbInbox, TbInfoSmall, TbKey, TbLink, TbListCheck, TbLoader, TbLock, TbLogin, TbLogout, TbLogs, TbMail, TbMenu2, TbPencil, TbPlus, TbPrinter, TbProgressCheck, TbQuestionMark, TbRefresh, TbReportAnalytics, TbRotateClockwise2, TbSettings, TbShieldCheck, TbShieldSearch, TbSmartHome, TbSortAscending, TbSortDescending, TbSquare, TbTrash, TbUser, TbUserCircle, TbUserDollar, TbUserEdit, TbUserFilled, TbUserPlus, TbUsers, TbUsersPlus, TbWritingSign, TbX, } from 'react-icons/tb';
const MANTINE_SIZES = ['xs', 'sm', 'md', 'lg', 'xl'];
const SIZE_MAP = {
    xs: '0.5rem',
    sm: '1rem',
    md: '2rem',
    lg: '3rem',
    xl: '4rem',
};
const withFontSize = (IconComponent) => {
    const IconComponentWithFontSize = ({ fontSize = 'inherit', size, ...props }) => {
        let iconSize;
        if (size && MANTINE_SIZES.includes(size)) {
            iconSize = SIZE_MAP[size];
        }
        else if (size) {
            iconSize = size;
        }
        else if (fontSize) {
            iconSize = getFontSize(fontSize);
        }
        return _jsx(IconComponent, { "data-testid": "icon", ...props, style: { width: iconSize, height: iconSize, ...props.style } });
    };
    IconComponentWithFontSize.displayName = 'IconComponentWithFontSize';
    return IconComponentWithFontSize;
};
// Add new icons here
const XenIconMap = {
    AddressBook: TbAddressBook,
    AlertTriangle: TbAlertTriangle,
    Archive: TbArchive,
    ArchiveFilled: TbArchiveFilled,
    ArchiveOff: TbArchiveOff,
    Bell: TbBell,
    Building: TbBuilding,
    BuildingBank: TbBuildingBank,
    Business: TbBuildingBank,
    Burger: TbMenu2,
    Calendar: TbCalendar,
    Checkbox: TbCheckbox,
    Checklist: TbChecklist,
    CheckMark: TbCheck,
    ChevronDown: TbChevronDown,
    ChevronLeft: TbChevronLeft,
    ChevronRight: TbChevronRight,
    ChevronUp: TbChevronUp,
    ClipboardList: TbClipboardList,
    Clock: TbClock,
    Close: TbX,
    CloudUpload: TbCloudUpload,
    Copy: TbCopy,
    CreditCard: TbCreditCard,
    CurrencyDollar: TbCurrencyDollar,
    DocumentCheck: TbFileCheck,
    Download: TbDownload,
    DownloadOff: TbDownloadOff,
    Edit: TbEdit,
    ExclamationMark: TbExclamationMark,
    ExternalLink: TbExternalLink,
    Eye: TbEye,
    File: TbFile,
    FilePencil: TbFilePencil,
    Files: TbFiles,
    FileX: TbFileX,
    Gear: TbSettings,
    Home: TbHome,
    Lock: TbLock,
    Hourglass: TbHourglass,
    Inbox: TbInbox,
    Info: TbInfoSmall,
    Key: TbKey,
    Link: TbLink,
    ListCheck: TbListCheck,
    Loader: TbLoader,
    Login: TbLogin,
    Logout: TbLogout,
    Logs: TbLogs,
    Mail: TbMail,
    MinusCircle: TbCircleMinus,
    Note: TbFileText,
    Pencil: TbPencil,
    Printer: TbPrinter,
    Plus: TbPlus,
    PlusCircle: TbCirclePlus,
    ProgressCheck: TbProgressCheck,
    QuestionMark: TbQuestionMark,
    QuestionMarkCircle: TbHelpCircleFilled,
    Refresh: TbRefresh,
    ReportAnalytics: TbReportAnalytics,
    RotateClockwise2: TbRotateClockwise2,
    ShieldCheck: TbShieldCheck,
    ShieldSearch: TbShieldSearch,
    SmartHome: TbSmartHome,
    SortArrows: TbArrowsSort,
    SortAsc: TbSortAscending,
    SortDesc: TbSortDescending,
    Square: TbSquare,
    Trash: TbTrash,
    User: TbUser,
    UserCircle: TbUserCircle,
    UserDollar: TbUserDollar,
    UserEdit: TbUserEdit,
    UserFilled: TbUserFilled,
    UserPlus: TbUserPlus,
    Users: TbUsers,
    UsersPlus: TbUsersPlus,
    WritingSign: TbWritingSign,
};
const XenIcon = Object.entries(XenIconMap).reduce((acc, [name, Component]) => {
    acc[name] = withFontSize(Component);
    return acc;
}, {});
export { rem, XenIcon };
