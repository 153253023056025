import { jsx as _jsx } from "react/jsx-runtime";
import { forwardRef } from 'react';
import { Select as MantineSelect } from '@mantine/core';
import { createLabelText, getTextFromReactNode, selectScrollAreaProps } from '../_internal';
import { SelectValueRenderer } from './select-value-renderer';
const ForwardedSelect = forwardRef(({ hideLabel = false, label, scrollAreaProps = {}, ...props }, ref) => {
    const labelText = createLabelText(label, hideLabel, props.type);
    return (_jsx(MantineSelect, { ...props, clearButtonProps: {
            'aria-hidden': undefined,
            'aria-label': `Clear ${getTextFromReactNode(label)}`,
            ...props.clearButtonProps,
        }, ref: ref, ...labelText, scrollAreaProps: { ...selectScrollAreaProps, ...scrollAreaProps } }));
});
ForwardedSelect.displayName = 'Select';
export const Select = Object.assign(ForwardedSelect, {
    ValueRenderer: SelectValueRenderer,
});
