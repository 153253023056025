import { jsx as _jsx } from "react/jsx-runtime";
import { lazy } from 'react';
import { Route } from 'react-router-dom';
import { FeatureNotEnabled, NotFound, RestrictedAccess } from 'xen/components';
import { useAuthentication, useFeatureFlags } from 'xen/hooks';
const Prospects = lazy(() => import('../pages/prospects'));
const ProspectsLenderDashboard = lazy(() => import('../pages/prospects-lender-dashboard'));
const ProspectDashboard = lazy(() => import('../pages/prospect-dashboard'));
const ProspectPreQual = lazy(() => import('../pages/prospect-pre-qualification'));
const ProspectCreate = lazy(() => import('../pages/prospect-create'));
const ProspectDetails = lazy(() => import('../pages/prospect-details'));
export const PROSPECTS_PATHS = {
    root: '/prospects',
    lenderDashboard: '/prospects/lender-dashboard',
    dashboard: '/prospects/dashboard',
    create: '/prospects/new',
    preQualification: '/prospects/pre-qualification',
    details: '/prospects/:id',
};
export const ProspectsRoutes = () => {
    const { create, dashboard, details, lenderDashboard, preQualification, root } = PROSPECTS_PATHS;
    const { stagedApplication } = useFeatureFlags();
    const { isLoggedIn } = useAuthentication();
    if (!isLoggedIn)
        return [];
    return [
        _jsx(Route, { exact: true, path: root, children: _jsx(RestrictedAccess, { userTypes: ['lender'], children: _jsx(Prospects, {}) }) }, root),
        _jsx(Route, { exact: true, path: lenderDashboard, children: _jsx(RestrictedAccess, { userTypes: ['lender'], children: _jsx(ProspectsLenderDashboard, {}) }) }, lenderDashboard),
        _jsx(Route, { exact: true, path: dashboard, children: _jsx(RestrictedAccess, { userTypes: ['client', 'prospect'], children: _jsx(ProspectDashboard, {}) }) }, dashboard),
        _jsx(Route, { exact: true, path: create, children: _jsx(RestrictedAccess, { userTypes: ['lender'], children: _jsx(ProspectCreate, {}) }) }, create),
        _jsx(Route, { exact: true, path: preQualification, children: () => {
                if (!stagedApplication)
                    return _jsx(FeatureNotEnabled, { feature: "Staged application" });
                return (_jsx(RestrictedAccess, { userTypes: ['prospect'], children: _jsx(ProspectPreQual, {}) }));
            } }, preQualification),
        // Needs to come after other /prospects/* routes so it's only matched if the other routes are not
        _jsx(Route, { exact: true, path: details, children: ({ match }) => {
                // If the nested path is not a number, render 404 page
                if (isNaN(Number(match?.params.id)))
                    return _jsx(NotFound, {});
                return (_jsx(RestrictedAccess, { userTypes: ['lender'], children: _jsx(ProspectDetails, {}) }));
            } }, details),
    ];
};
