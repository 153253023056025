export const STORAGE_KEYS = {
    appVersion: 'app-version',
};
export const storage = {
    clear: () => {
        localStorage.clear();
    },
    get: (key) => {
        return localStorage.getItem(key);
    },
    remove: (key) => {
        localStorage.removeItem(key);
    },
    set: (key, value) => {
        localStorage.setItem(key, value);
    },
};
