import { datadogRum } from '@datadog/browser-rum'

export function startMonitoring({ applicationId, env, version }) {
  // eslint-disable-next-line no-console
  console.log('[Datadog] Datadog RUM client initialized')

  const sampleRate = 100

  datadogRum.init({
    applicationId,
    clientToken: 'pub34bc849dc31b94d5844f6145d9af7623',
    site: 'us5.datadoghq.com',
    service: 'platform_react',
    env,
    version,
    sessionSampleRate: sampleRate,
    sessionReplaySampleRate: sampleRate,
    trackFrustrations: true,
    trackResources: true,
    trackLongTasks: true,
    trackUserInteractions: true,
    defaultPrivacyLevel: 'mask-user-input',
    beforeSend: (event) => {
      // Check if session is a bot based on User-Agent
      if (isBot()) {
        event.context = event.context || {}
        event.context.is_bot = true // Custom RUM tag
        event.context.bot_user_agent = navigator.userAgent // Capture bot user agent
      }
    },
  })
}

export function addTenantToMonitoringContext(tenant) {
  datadogRum.setGlobalContext({
    tenant: {
      id: tenant.id,
      name: tenant.organization_name,
      token: tenant.token,
    },
  })
}

export function addUserToMonitoringContext(user) {
  datadogRum.setUser({
    email: user.email_address,
    id: user.id,
    is_admin: user.is_admin,
    is_operations: user.is_operations,
    is_sales_person: user.is_sales_person,
    is_underwriter: user.is_underwriter,
    name: [user.first_name?.trim(), user.last_name?.trim()].join(' ').trim(),
    phone_number: user.phone_number,
    title: user.title,
    user_type: user.user_type,
  })
}

export function removeUserFromMonitoringContext() {
  datadogRum.clearUser()
}

// https://docs.datadoghq.com/real_user_monitoring/guide/identify-bots-in-the-ui/
function isBot() {
  // Regex patterns to identify known bot instances:
  const botPattern =
    '(googlebot/|bot|Googlebot-Mobile|Googlebot-Image|Google favicon|Mediapartners-Google|bingbot|slurp|java|wget|curl|Commons-HttpClient|Python-urllib|libwww|httpunit|nutch|phpcrawl|msnbot|jyxobot|FAST-WebCrawler|FAST Enterprise Crawler|biglotron|teoma|convera|seekbot|gigablast|exabot|ngbot|ia_archiver|GingerCrawler|webmon |httrack|webcrawler|grub.org|UsineNouvelleCrawler|antibot|netresearchserver|speedy|fluffy|bibnum.bnf|findlink|msrbot|panscient|yacybot|AISearchBot|IOI|ips-agent|tagoobot|MJ12bot|dotbot|woriobot|yanga|buzzbot|mlbot|yandexbot|purebot|Linguee Bot|Voyager|CyberPatrol|voilabot|baiduspider|citeseerxbot|spbot|twengabot|postrank|turnitinbot|scribdbot|page2rss|sitebot|linkdex|Adidxbot|blekkobot|ezooms|dotbot|Mail.RU_Bot|discobot|heritrix|findthatfile|europarchive.org|NerdByNature.Bot|sistrix crawler|ahrefsbot|Aboundex|domaincrawler|wbsearchbot|summify|ccbot|edisterbot|seznambot|ec2linkfinder|gslfbot|aihitbot|intelium_bot|facebookexternalhit|yeti|RetrevoPageAnalyzer|lb-spider|sogou|lssbot|careerbot|wotbox|wocbot|ichiro|DuckDuckBot|lssrocketcrawler|drupact|webcompanycrawler|acoonbot|openindexspider|gnam gnam spider|web-archive-net.com.bot|backlinkcrawler|coccoc|integromedb|content crawler spider|toplistbot|seokicks-robot|it2media-domain-crawler|ip-web-crawler.com|siteexplorer.info|elisabot|proximic|changedetection|blexbot|arabot|WeSEE:Search|niki-bot|CrystalSemanticsBot|rogerbot|360Spider|psbot|InterfaxScanBot|Lipperhey SEO Service|CC Metadata Scaper|g00g1e.net|GrapeshotCrawler|urlappendbot|brainobot|fr-crawler|binlar|SimpleCrawler|Livelapbot|Twitterbot|cXensebot|smtbot|bnf.fr_bot|A6-Indexer|ADmantX|Facebot|Twitterbot|OrangeBot|memorybot|AdvBot|MegaIndex|SemanticScholarBot|ltx71|nerdybot|xovibot|BUbiNG|Qwantify|archive.org_bot|Applebot|TweetmemeBot|crawler4j|findxbot|SemrushBot|yoozBot|lipperhey|y!j-asr|Domain Re-Animator Bot|AddThis)'

  const regex = new RegExp(botPattern, 'i')

  return regex.test(navigator.userAgent)
}
