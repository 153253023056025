import React from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { Button } from '../../Button'
import { history } from '../../../_helpers'

// Actions
import { modalActions } from '../../../_actions'

import { AuthConfig } from '../../../_configs/auth.config'
import ROUTES from 'legacy/_constants/routes'

const StyledDiv = styled.div`
  max-width: 500px;
  .applyBtn {
    margin-top: 16px;
    padding-left: auto;
  }
  .title {
    margin-bottom: 8px;
  }
  .body {
    margin-top: 8px;
  }
`

const BaseAuthSubmit = ({ tenant, closeModal }) => {
  return (
    <StyledDiv>
      <div className={`title`}>{AuthConfig[tenant].modal_title}</div>
      <div className={`body`}>{AuthConfig[tenant].modal_body}</div>
      <div className={'applyBtn'}>
        <Button
          onClick={() => {
            closeModal()
            const redirectPath = AuthConfig[tenant].sign_up.redirect || ROUTES.accounting_platforms
            history.push(redirectPath)
          }}
          text={`Apply Now`}
          type={`button`}
        />
      </div>
    </StyledDiv>
  )
}

function mapStateToProps(state) {
  const { business, env } = state
  return {
    business,
    tenant: env.tenant,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    closeModal: () => {
      dispatch(modalActions.closeModal())
    },
  }
}

const connectedSubmit = connect(mapStateToProps, mapDispatchToProps)(BaseAuthSubmit)
export { connectedSubmit as AuthSubmit }
